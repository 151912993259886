import { gql } from "@apollo/client"
import { imageData, userDataCompressed } from "../../../common/fragments"

export const GET_ART_NOTES = gql`
	query getArtNotes($art_id: ID, $thumbnailResolution: String = "128x128") {
		getArtNotes(input: { art_id: $art_id }) {
			id
			type_id
			applied_at
			applied_user_id
			note_text
			is_critical
			is_private
			is_deleted
			created_by
			created_at
			modified_by
			modified_at
			user {
				...userDataCompressed
				is_deleted
			}
			images {
				...imageData
			}
		}
	}
	${imageData}
	${userDataCompressed}
`

export const CREATE_NOTE = gql`
	mutation createArtNote(
		$CreateNoteInput: CreateNoteInput!
		$thumbnailResolution: String = "128x128"
	) {
		createArtNote(input: $CreateNoteInput) {
			code
			success
			message
			note {
				id
				type_id
				applied_at
				applied_user_id
				note_text
				created_by
				created_at
				modified_by
				modified_at
				is_critical
				is_private
				is_deleted
				user {
					...userDataCompressed
					is_deleted
				}
				images {
					...imageData
				}
			}
		}
	}
	${imageData}
	${userDataCompressed}
`

export const ART_UPDATE_NOTE = gql`
	mutation updateArtNote(
		$UpdateNoteInput: UpdateNoteInput!
		$thumbnailResolution: String = "128x128"
	) {
		updateArtNote(input: $UpdateNoteInput) {
			code
			success
			message
			note {
				id
				type_id
				applied_at
				applied_user_id
				note_text
				is_critical
				is_private
				is_deleted
				created_by
				created_at
				modified_by
				modified_at
				user {
					...userDataCompressed
					is_deleted
				}
				images {
					...imageData
				}
			}
		}
	}
	${imageData}
	${userDataCompressed}
`
