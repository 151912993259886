import React, { useEffect, useMemo, useState } from "react"
import { useRecoilValue } from "recoil"
import { rightQuickViewAtom } from "../navigation/Tabs/TabbedPage"
import RightQuickView from "../QuickView/RightQuickView"
import { format } from "date-fns"
import { QUICK_SEARCH_LIMIT } from "../constants/values"
import { useQuery } from "@apollo/client"
import { DYNAMIC_LISTING_SEARCH } from "../Search/Queries"
import { Avatar, InputAdornment, MenuItem, Select } from "@mui/material"
import Dropdown from "../input/Dropdown/Dropdown"
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded"
import TextBox from "../input/Text/TextBox"
import Search from "@mui/icons-material/Search"
import ImageIcon from "@mui/icons-material/Image"
import { useDebounce } from "../util"

const typeOptions = ["Art Fair OR Exhibition", "Art Fair", "Exhibition"]

const getPlaceholder = () => (
	<Avatar
		alt={"Image"}
		variant="square"
		style={{
			height: "5rem",
			width: "5rem",
			objectFit: "scale-down",
		}}>
		<ImageIcon style={{ fontSize: "50" }} />
	</Avatar>
)

const sortOptions = [
	{
		label: "Date",
		value: "start_at",
	},
	{
		label: "Name",
		value: "title",
	},
]

const ArtExhibitionQuickView = (props) => {
	const QVOverride = useRecoilValue(rightQuickViewAtom)

	const [query, setQuery] = useState("")
	const [sortModel, setSortModel] = useState(sortOptions[0].label)
	const [typeOption, setTypeOption] = useState(typeOptions[0])

	const filters = useMemo(
		() => [
			{
				field: "listing_type",
				type: "contains",
				value: "Art Fair OR Exhibition",
				isOptional: false,
			},
			{
				field: "is_shown_in_side_panel",
				type: "eq",
				value: "true",
				isOptional: false,
			},
		],
		[],
	)

	// Listing Query
	const variables = {
		limit: QUICK_SEARCH_LIMIT,
		field: sortOptions[0].value,
		direction: "ASC",
		filters: filters,
	}

	const { data, refetch } = useQuery(DYNAMIC_LISTING_SEARCH, { variables })

	const debouncedValue = useDebounce(query, 1500)

	useEffect(() => {
		refetch({
			field: sortOptions.find((option) => option.label === sortModel)?.value,
			filters: [
				{
					field: "is_shown_in_side_panel",
					type: "eq",
					value: "true",
					isOptional: false,
				},
				{
					...filters[0],
					value: typeOption,
				},
				{
					field: "query",
					type: "eq",
					value: debouncedValue,
				},
			],
		})
	}, [debouncedValue, filters, refetch, sortModel, typeOption])

	return (
		<>
			{QVOverride}
			<RightQuickView
				style={{
					overflowY: "auto",
					display: QVOverride ? "none" : "block",
				}}>
				<div className="flex w-full flex-col px-3">
					<div className="flex pt-6 items-center text-md text-grey">
						Artfairs & Exhibitions
					</div>
					<div className="flex py-2">
						<TextBox
							endAdornment={
								<InputAdornment position="end">
									<Search />
								</InputAdornment>
							}
							className="w-full"
							step="any"
							name="inventory_number_prefix"
							value={query}
							onChange={(event) => setQuery(event.target.value)}
							data-testid="art-inventory"
						/>
					</div>
					<div className="flex pb-2">
						<div className="flex align-middle justify-center text-md text-grey">
							Sort:
						</div>
						<div
							style={{
								display: "flex",
								alignItems: "center",
								justifyContent: "space-between",
							}}>
							<Select
								inputProps={{
									style: {
										paddingLeft: "0",
									},
								}}
								input={<Dropdown />}
								IconComponent={ExpandMoreRoundedIcon}
								className="hideborder small-selection"
								value={sortModel}
								renderValue={(data) => <div>{data}</div>}
								onChange={(event) => {
									setSortModel(event.target.value)
								}}
								data-testid="sort">
								{sortOptions.map((options) => (
									<MenuItem
										key={options.label}
										value={options.label}
										style={{ cursor: "pointer" }}
										data-testid="sort-item">
										{options.label}
									</MenuItem>
								))}
							</Select>
						</div>
					</div>
					<div className="flex pb-2">
						<div className="flex align-middle justify-center text-md text-grey">
							Type:
						</div>
						<div
							style={{
								display: "flex",
								alignItems: "center",
								justifyContent: "space-between",
							}}>
							<Select
								inputProps={{
									style: {
										paddingLeft: "0",
									},
								}}
								input={<Dropdown />}
								IconComponent={ExpandMoreRoundedIcon}
								className="hideborder small-selection"
								value={typeOption}
								renderValue={(data) => <div>{data}</div>}
								onChange={(event) => {
									console.log("event", event.target)
									setTypeOption(event.target.value)
								}}
								data-testid="type">
								{typeOptions.map((type) => (
									<MenuItem
										key={type}
										value={type}
										style={{ cursor: "pointer" }}
										data-testid="type-item">
										{type}
									</MenuItem>
								))}
							</Select>
						</div>
					</div>
					<div className="flex flex-col overflow-y-auto">
						{data?.searchDynamicListings?.items?.map((item, index) => (
							<div
								className="flex-row my-2"
								key={index}
								style={{ cursor: "pointer" }}
								onClick={() => {
									window.open(`/listings/${item.id}`, "_blank")
								}}>
								{item.imgUrl ? (
									<img src={item?.imgUrl} alt="" className="w-20 h-20" />
								) : (
									getPlaceholder()
								)}
								<div className="flex-col ml-2">
									<div className="font-normal opacity-100">{item.title}</div>
									<div className="font-normal opacity-100">
										{format(new Date(item.start_at), "MMM do, yyyy")}
									</div>
									<div className="font-normal text-[#6A6A72]">
										{`${item?.listing_type?.value} | ${item.location ?? "--"}`}
									</div>
								</div>
							</div>
						))}
					</div>
				</div>
			</RightQuickView>
		</>
	)
}

export default ArtExhibitionQuickView
