import routes from '../../navigation/routes'
import ArtistQuickView from './ArtistQuickView'
import { GET_ARTIST_DETAILS } from '../Queries'
import { useParams } from 'react-router-dom'
import { DYNAMIC_ARTISTS_SEARCH } from '../../Search/Queries'
import TabbedPage from '../../navigation/Tabs/TabbedPage'

export default function Artist(props) {
	const params = useParams()

	return TabbedPage({
		...props,
		routes: routes.artistPages.pages,
		Quickview: ArtistQuickView,
		query: GET_ARTIST_DETAILS,
		searchGlobalQuery: DYNAMIC_ARTISTS_SEARCH,
		options: routes.artistPages.options,
		params,
	})
}
