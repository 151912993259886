/* eslint-disable eqeqeq */
import { useApolloClient, useMutation, useQuery } from "@apollo/client"
import { GridSortModel } from "@mui/x-data-grid-pro"
import React, { useCallback, useContext, useEffect } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import CollapsibleSearchCard from "../common/components/CollapsibleSearchCard"
import { SavedSearch, SearchTerm } from "../common/SavedSearch"
import { Order, Variables } from "../common/typescriptVars"
import { ART_SEARCH_OBJECT, DEFAULT_SEARCH_LIMIT } from "../constants/values"
import { DYNAMIC_ART_NAV } from "../navigation/Queries"
import useNavigation from "../navigation/useNavigation"
import UserAccessQuickView from "../QuickView/UserAccessQuickView"
import { SELECT_ALL_ART } from "../Search/ActionButtons/Queries"
import GlobalSearchFilters, {
	getOperatorValue,
	initialFilterState,
} from "../Search/GlobalSearchFilters"
import {
	ART_FLAG_QUERY,
	DYNAMIC_ART_SEARCH,
	GET_ART_FILTER_FIELDS,
	GET_SEARCHES,
	UPDATE_SEARCH,
} from "../Search/Queries"
import SearchQuickView, {
	SAVED_SEARCHES,
	SELECTED,
} from "../Search/SearchQuickView"
import {
	filterMap,
	filterSearchTerms,
	handleDeleteSearch,
	updateLabel,
} from "../Search/unifiedSearchHelpers"
import { severity } from "../Snackbar/CustomizedSnackbar"
import { AuthStateContext, DispatchContext } from "../store"
import { ArtDataGrid } from "./ArtDataGrid"
import ArtQuickView from "./ArtPiece/ArtQuickView"
import CreateArtQuickView from "./CreateArtQuickView"
import SearchArt from "./SearchArt"
import ArtExhibitionQuickView from "../ArtExhibitionQuickView/ArtExhibitionQuickView"

type RowId = string

type ArtT = any

type PreviousSearch = {
	order: Order
	orderBy: string | null
	limit: number
	fields?: any
	art: ArtT[]
	steps: number
	activeStep: number
	totalItems: number | null
	advancedSearch: any
	currentSearch: SavedSearch
} | null

const Art = () => {
	const { push: pushNav } = useNavigation()
	const navigate = useNavigate()
	const location = useLocation()
	const userAuthentication = useContext(AuthStateContext)
	const client = useApolloClient()

	const prevSearch: PreviousSearch = location.state as PreviousSearch

	// Snackbar
	const dispatch = useContext(DispatchContext)
	const openSnackbar = useCallback(
		(severity: string, text: string) => {
			dispatch({ type: "openSnackBar", payload: { severity, text } })
		},
		[dispatch],
	)

	const closeSnackbar = () => {
		dispatch({ type: "closeSnackBar" })
	}

	// Mutations
	const [updateSearch] = useMutation(UPDATE_SEARCH)

	// State that comes from prevSearch
	const [order, setOrder] = React.useState<Order>(prevSearch?.order || "asc")
	const [orderBy, setOrderBy] = React.useState(prevSearch?.orderBy || null)
	const [art, setArt] = React.useState(prevSearch?.art ?? [])
	const [activeStep, setActiveStep] = React.useState(
		prevSearch?.activeStep ?? 0,
	)
	const [totalItems, setTotalItems] = React.useState(
		prevSearch?.totalItems ?? null,
	)
	const [steps, setSteps] = React.useState(prevSearch?.steps ?? 1)
	const [selectedArt, setSelectedArt] = React.useState<any>(null)
	const [advancedSearch, setAdvancedSearch] = React.useState(
		prevSearch?.advancedSearch ?? false,
	)
	const [currentSearch, setCurrentSearch] = React.useState(
		prevSearch?.currentSearch ??
			new SavedSearch({
				object_id: ART_SEARCH_OBJECT,
				search_terms: [],
				is_global: true,
				user_id: userAuthentication.user?.id,
			}),
	)
	const [limit, setLimit] = React.useState(
		prevSearch?.limit ?? DEFAULT_SEARCH_LIMIT,
	)

	// Normal State
	const [qvTabValue, setQvTabValue] = React.useState(SAVED_SEARCHES)

	const [flaggedCount, setFlaggedCount] = React.useState(null)
	const [firstRender, setFirstRender] = React.useState(true)
	const [selection, setSelection] = React.useState([])
	const [savedSearches, setSavedSearches] = React.useState([])
	const [searchLoading, setSearchLoading] = React.useState(false)
	const [createArt, setCreateArt] = React.useState(false)

	const [editMode, setEditMode] = React.useState(false)

	const setFilters = (filters: SearchTerm[]) => {
		setCurrentSearch({
			...currentSearch,
			search_terms: filters,
		})
	}

	const currentFilters = [
		"isActive",
		"art_status_value",
		"art_categories",
		"modified_at",
		"isFavorite",
		"isVerified",
		"ready",
		"gallery_contacts",
		"query",
	]

	const advancedFilters = filterSearchTerms(currentSearch.search_terms)?.filter(
		(term: SearchTerm) => {
			if (currentFilters.includes(term.field)) return false
			return true
		},
	)

	useEffect(() => {
		if (currentSearch.search_terms?.length == 0 && advancedSearch) {
			setCurrentSearch(
				new SavedSearch({
					object_id: ART_SEARCH_OBJECT,
					search_terms: [initialFilterState()],
					is_global: true,
					user_id: userAuthentication.user?.id,
				}),
			)
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentSearch.search_terms, userAuthentication.user?.id, advancedSearch])

	// Search on change of order
	React.useEffect(() => {
		setFirstRender(false)
		if (art)
			if (art.length !== 0 && art[0].disabled !== true && !firstRender) {
				handleSubmit()
			}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [order, orderBy, activeStep, limit])

	/**
	 * Save current page state for if one returns to it through
	 * back arrow.
	 *
	 * @returns current page state
	 */
	function updateHistory(index?: RowId) {
		const currentPageState = {
			currentSearch,
			orderBy,
			order,
			art,
			limit,
			totalItems,
			steps,
			activeStep,
			advancedSearch,
			searchPage: location.pathname,
			variables: {} as Variables,
		}
		const offset = index
			? art.findIndex((a) => a.id == index)
			: art.indexOf(selectedArt)
		const cursor = activeStep * limit + offset
		const variables = makeVariables(
			cursor,
			currentSearch,
			orderBy,
			order,
			currentSearch?.search_terms ?? [],
		)
		variables.limit = 3
		currentPageState.variables = variables
		navigate(location.pathname, { state: currentPageState })
		return currentPageState
	}

	function saveAndGo(path: string, row: RowId) {
		// persist current page state in history
		const state = updateHistory(row)
		pushNav(
			{
				url: location.pathname,
				state,
				query: DYNAMIC_ART_NAV,
			},
			state.variables?.cursor,
		)
		// navigate to new page (with this page's query and `variables`)
		navigate(path, { state })
	}

	// Save search to state on selecting a Art
	React.useEffect(() => {
		if (selectedArt) updateHistory()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedArt])

	// Get saved searches
	const { loading, error } = useQuery(GET_SEARCHES, {
		skip: !userAuthentication.user?.id,
		variables: {
			userId: userAuthentication.user?.id,
			objectId: ART_SEARCH_OBJECT,
			global: true,
		},
		onCompleted: (data) => {
			if (data && data.getSearches && data.getSearches?.length !== 0) {
				setSavedSearches(
					data.getSearches.map(
						(search: SavedSearch) => new SavedSearch(search),
					),
				)
			} else {
				// console.log("No saved searches were found.")
			}
		},
	})

	if (error) {
		console.log(error)
		openSnackbar(severity.ERROR, "Error retrieving your saved searches.")
	}

	const makeVariables = (
		cursor: number,
		currentSearch: SavedSearch,
		orderBy: string | null,
		order: Order,
		filters?: SearchTerm[] | null,
		limitOverride?: number,
	): Variables => {
		return {
			cursor,
			limit: limitOverride ?? limit,
			field: orderBy,
			direction: order == "asc" ? "ASC" : "DESC",
			filters: filters
				? filterMap(filters)
				: filterMap(currentSearch.search_terms),
			thumbnailResolution: "128x128",
		}
	}

	const handleSubmit = (
		cursorObj?: { cursor?: number },
		filters?: SearchTerm[],
		isSavedSearch?: boolean,
	) => {
		const cursor = cursorObj?.cursor

		setSearchLoading(true)

		let submitCursor
		if (cursor == undefined) {
			submitCursor = activeStep * limit
		} else {
			submitCursor = cursor
		}
		openSnackbar(severity.INFO, "Loading search results...")
		const variables = makeVariables(
			submitCursor,
			currentSearch,
			orderBy,
			order,
			filters ?? null,
		)
		setQvTabValue(SELECTED)

		// Set filters when handle submit is not coming from saved search list
		if (filters?.length && !isSavedSearch) setFilters(filters)

		type DynamicArtSearchResults = {
			data: {
				searchDynamicArt: {
					items: ArtT[]
					totalItems: number
					cursor: number
				}
			}
		}

		client
			.query({ query: DYNAMIC_ART_SEARCH, variables })
			.then((result: DynamicArtSearchResults) => {
				const { data } = result

				if (data.searchDynamicArt?.items) {
					setArt(data.searchDynamicArt.items)

					if (data.searchDynamicArt.items?.length < 1) setSteps(1)
					else setSteps(Math.ceil(data.searchDynamicArt.totalItems / limit))

					setTotalItems(data.searchDynamicArt.totalItems || 0)
					setSearchLoading(false)
					if (!data.searchDynamicArt.totalItems) {
						openSnackbar(severity.WARNING, "There were no results.")
					} else {
						closeSnackbar()
					}
				} else {
					console.error(data)
					setSearchLoading(false)
					openSnackbar(severity.ERROR, "There was an error searching art.")
				}
			})
			.catch((error: Error) => {
				console.error(error)
				setSearchLoading(false)
				openSnackbar(severity.ERROR, "Could not search art.")
			})
	}

	const handleReset = () => {
		setCurrentSearch(
			new SavedSearch({
				object_id: ART_SEARCH_OBJECT,
				search_terms: [],
				is_global: true,
				user_id: userAuthentication.user?.id,
			}),
		)
		setArt([])
		setCreateArt(false)
		setSteps(1)
		setActiveStep(0)
		setTotalItems(null)
		setOrder("asc")
		setOrderBy(null)
		setSelectedArt(null)
		setSearchLoading(false)
		setQvTabValue(SAVED_SEARCHES)
	}

	const updateArtSavedSearchLabel = (search: SavedSearch, label: string) =>
		updateLabel(
			search,
			label,
			updateSearch,
			savedSearches,
			setSavedSearches,
			openSnackbar,
		)

	const handleDeleteArtSavedSearch = (search: SavedSearch) =>
		handleDeleteSearch(
			search,
			updateSearch,
			savedSearches,
			setSavedSearches,
			openSnackbar,
		)

	/* Get the dynamic art filter fields 
	Also populates the search with filters when navigated to with updates to the history (aka history.push())*/
	const { data: artFilterFields, error: artFilterError } = useQuery(
		GET_ART_FILTER_FIELDS,
		{
			fetchPolicy: "cache-first",
			onCompleted: ({ searchDynamicArtFilterFields }) => {
				// @ts-ignore
				const storedFields = location?.state?.fields
				if (storedFields) {
					setAdvancedSearch(true)
					const fieldFilters = Object.entries<string>(storedFields).map(
						([field, value], i) => {
							const filterField = searchDynamicArtFilterFields.find(
								(f: any) => f.name == field.split(".")[0],
							)

							const { nestedQueryType } = filterField

							let type = getOperatorValue(filterField?.type) || "contains"
							return {
								field,
								value,
								type,
								isOptional: false,
								id: new Date().getTime() + i,
								nestedQueryType,
							}
						},
					)

					setFilters(fieldFilters)
					handleSubmit({}, fieldFilters)
				}
			},
		},
	)

	if (artFilterError) {
		openSnackbar(
			severity.ERROR,
			"There was an error retrieving filterable fields for this search.",
		)
		console.error(artFilterError)
	}

	const renderQuickView = () => {
		if (createArt) {
			return <CreateArtQuickView {...{ setCreateArt }}></CreateArtQuickView>
		} else if (selectedArt) {
			if (
				(selectedArt.is_private || selectedArt.is_gallery_private) &&
				!selectedArt.created_at
			)
				return (
					<UserAccessQuickView
						entity={selectedArt}
						onClose={() => setSelectedArt(null)}></UserAccessQuickView>
				)

			return (
				<ArtQuickView
					{...{
						id: selectedArt.id,
						onClose: () => setSelectedArt(null),
					}}></ArtQuickView>
			)
		} else {
			return (
				<SearchQuickView
					savedSearches={savedSearches}
					setSavedSearches={setSavedSearches}
					setCurrentSearch={setCurrentSearch}
					setFilters={setFilters}
					handleSubmit={handleSubmit}
					currentSearch={currentSearch}
					setAdvancedSearch={setAdvancedSearch}
					savedSearchesLoading={loading}
					updateLabel={updateArtSavedSearchLabel}
					handleDeleteSearch={handleDeleteArtSavedSearch}
					setSelection={setSelection}
					selection={selection}
					totalItems={totalItems}
					flaggedCount={flaggedCount}
					setFlaggedCount={setFlaggedCount}
					makeVariables={makeVariables}
					orderBy={orderBy}
					order={order}
					searchQuery={DYNAMIC_ART_SEARCH}
					flagQuery={ART_FLAG_QUERY}
					entity="art"
					bulkActionQuery={SELECT_ALL_ART}
					setValue={setQvTabValue}
					value={qvTabValue}
					filterFields={artFilterFields?.searchDynamicArtFilterFields}
					requery={handleSubmit}
				/>
			)
		}
	}

	const variables = makeVariables(0, currentSearch, orderBy, order)

	return (
		<>
			<section className="main-page">
				{renderQuickView()}

				<div
					style={{
						display: "flex",
						flex: "1 1 auto",
						overflow: "auto",
						flexDirection: "column",
					}}>
					{!editMode && (
						<CollapsibleSearchCard>
							{advancedSearch ? (
								<GlobalSearchFilters
									loading={searchLoading}
									currentSearch={currentSearch}
									setCurrentSearch={setCurrentSearch}
									onReset={handleReset}
									onSearch={handleSubmit}
									object_id={ART_SEARCH_OBJECT}
									advancedSearch={advancedSearch}
									setAdvancedSearch={setAdvancedSearch}
									setCreateEntity={setCreateArt}
									filterFields={artFilterFields?.searchDynamicArtFilterFields}
									typeName="Art"
									onFindMode={() => navigate("/art/findmode/details")}
									savedSearches={savedSearches}
									setSavedSearches={setSavedSearches}
									prevSearch={prevSearch?.currentSearch}
									toggleEditMode={() => setEditMode(true)}
								/>
							) : (
								<SearchArt
									loading={searchLoading}
									currentFilters={currentSearch.search_terms}
									resetSearch={handleReset}
									currentSearch={currentSearch}
									setCurrentSearch={setCurrentSearch}
									handleSubmit={handleSubmit}
									setActiveStep={setActiveStep}
									setTotalItems={setTotalItems}
									advancedSearch={advancedSearch}
									setAdvancedSearch={setAdvancedSearch}
									setCreateArt={setCreateArt}
									advancedFilters={advancedFilters}
									selection={selection}
									setSelection={setSelection}
									onFindMode={() => navigate("/art/findmode/details")}
									prevSearch={prevSearch?.currentSearch}
									savedSearches={savedSearches}
									setSavedSearches={setSavedSearches}
									toggleEditMode={() => {
										setQvTabValue(SELECTED)
										setEditMode(true)
									}}></SearchArt>
							)}
						</CollapsibleSearchCard>
					)}

					<ArtDataGrid
						rows={art}
						loading={searchLoading}
						saveAndGo={saveAndGo}
						setRows={setArt}
						setFlaggedCount={setFlaggedCount}
						userId={userAuthentication.user?.id}
						totalItems={totalItems}
						onSelect={setSelectedArt}
						selectedRow={selectedArt}
						limit={limit}
						setLimit={setLimit}
						activeStep={activeStep}
						setActiveStep={setActiveStep}
						sortModel={orderBy ? [{ field: orderBy, sort: order }] : []}
						onSortModelChange={(newSort: GridSortModel) => {
							if (newSort.length) {
								setOrderBy(newSort[0].field)
								setOrder(newSort[0].sort)
							} else {
								setOrderBy(null)
								setOrder("asc")
							}
						}}
						// flagmenu
						filterTo={(newFilters: SearchTerm[]) => {
							setSelection([])
							setFilters(newFilters)
							handleSubmit({ cursor: 0 }, newFilters)
							setQvTabValue(SELECTED)
							setSelectedArt(null)
						}}
						type="Art"
						variables={variables}
					/>
				</div>
				<ArtExhibitionQuickView />
			</section>
		</>
	)
}

export default Art
