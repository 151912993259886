import React from "react"
import Preferences from "./Preferences"
import ListingSearch from "../../../Search/ListingSearch"
import Listings from "./Listings"

export default function ContactListings(props) {
	return (
		<>
			<Preferences id={props.id} findMode={props.findMode} />
			{props.findMode ? (
				<ListingSearch />
			) : (
				<Listings
					id={props.id}
					state={props.state}
					QVOverride={props.QVOverride}
					setQVOverride={props.setQVOverride}
				/>
			)}
		</>
	)
}
