/* eslint-disable eqeqeq */
import React from "react"

import AdminConsole from "../AdminConsole/AdminConsole"
import AdminDataLog from "../AdminConsole/AdminDataLog"
import AdminPermissions from "../AdminConsole/AdminPermissions"
import AdminUser from "../AdminConsole/AdminUser"
import AdminUserList from "../AdminConsole/Search/AdminUsers"
import AdminImports from "../AdminConsole/Search/AdminImports"
import AdminImportsHelp from "../AdminConsole/Search/AdminImportsHelp"
import Art from "../Art/Art"
import ArtAccess from "../Art/ArtAccess"
import FinancialsAndTransactions from "../Art/ArtPiece/OffersAndDeals/FinancialsAndTransactions"
import ArtDataLog from "../Art/ArtPiece/ArtDataLog"
import ArtDetailPage from "../Art/ArtPiece/ArtDetailPage"
import ArtFilesPage from "../Art/ArtPiece/Files/FilesPage"
import ArtistArtPage from "../Artists/Artist/Art/ArtistArtPage"
import ArtistDataLog from "../Artists/Artist/ArtistDataLog"
import ArtistDetailPage from "../Artists/Artist/Detail/ArtistDetailPage"
import ArtistFilesPage from "../Artists/Artist/Files/FilesPage"
import ArtistListings from "../Artists/Artist/ArtistListings"
import ArtistNotes from "../Artists/Artist/Notes/ArtistNotes"
import ArtistResearch from "../Artists/Artist/ArtistResearch"
import Artists from "../Artists/Artist/Artist"
import ArtistTasks from "../Artists/Artist/Tasks/ArtistTasks"
import ArtListings from "../Art/ArtPiece/ArtListings"
import ArtNotes from "../Art/ArtPiece/Notes/ArtNotes"
import ArtOffersAndReserves from "../Art/ArtPiece/OffersAndDeals/ArtOffersAndReserves"
import ArtOperations from "../Art/ArtPiece/Operations/ArtOperations"
import ArtPiece from "../Art/ArtPiece/ArtPiece"
import ArtTasks from "../Art/ArtPiece/Tasks/ArtTasks"
import Contact from "../Contacts/Contact/Contact"
import ContactAccess from "../Contacts/Contact/Access/ContactAccess"
import ContactArtCollection from "../Contacts/ArtCollectionAndKnownWorks/ArtCollectionAndKnownWorks"
import ContactDataLog from "../Contacts/Contact/ContactDataLog"
import ContactDetail from "../Contacts/Contact/ContactDetail"
import ContactFilesPage from "../Contacts/Contact/Files/FilesPage"
import ContactListings from "../Contacts/Contact/Listings/ContactListings"
import ContactNotes from "../Contacts/Contact/Notes/ContactNotes"
import ContactOffersAndReserves from "../Contacts/OffersAndReserves/OffersAndReserves"
import Contacts from "../Contacts/Contacts"
import ContactTasks from "../Contacts/Contact/Tasks/ContactTasks"
import Deal from "../Deals/Deal/Deal"
import DealAccess from "../Deals/Deal/DealAccess"
import DealDatalog from "../Deals/Deal/DealDataLog"
import DealDetails from "../Deals/Deal/DealDetails"
import DealFilesPage from "../Deals/Deal/Files/FilesPage"
import DealNotes from "../Deals/Deal/Notes/DealNotes"
import Deals from "../Deals/Deals"
import DealTasks from "../Deals/Deal/Tasks/DealTasks"
import Details from "../User/Details/Details"
import Faq from "../Support/Faq"
import Home from "../Home/Home"
import Listing from "../Listings/Listing/Listing"
import ListingArt from "../Listings/Listing/ListingArt"
import ListingContacts from "../Listings/Listing/Contacts/ListingContacts"
import ListingDataLog from "../Listings/Listing/ListingDataLog"
import ListingDetailPage from "../Listings/Listing/ListingDetailPage"
import ListingFilesPage from "../Listings/Listing/Files/FilesPage"
import ListingNotes from "../Listings/Listing/Notes/ListingNotes"
import Listings from "../Listings/Listings"
import ListingTasks from "../Listings/Listing/Tasks/ListingTasks"
import PdfManual from "../Support/PdfManual"
// eslint-disable-next-line no-unused-vars
import ReleaseNotes from "../Support/ReleaseNotes"
import SearchArtists from "../Artists/Artists"
import SearchTasks from "../Tasks/Tasks"
import Support from "../Support/Support"
import Tag from "../Tags/Tag/Tag"
import TagDetail from "../Tags/Tag/TagDetail"
import Tags from "../Tags/Tags"
import User from "../User/User"
import UserDataLog from "../User/DataLog/UserDataLog"
import UserPermissionsPage from "../User/UserPermissionsPage"

// Queries
import { UPDATE_ART_FILES } from "../Art/Queries"
import { UPDATE_ARTIST_FILES } from "../Artists/Queries"
import { UPDATE_CONTACT_FILES } from "../Contacts/Queries"
import { UPDATE_DEAL_FILES } from "../Deals/Queries"
import { UPDATE_LISTING_FILES } from "../Listings/Queries"

import {
	UPDATE_ART_FILE,
	UPDATE_ARTIST_FILE,
	UPDATE_DEAL_FILE,
	UPDATE_LISTING_FILE,
	UPDATE_CONTACT_FILE,
} from "../Files/Queries"

// Icons
import ContactSupportIcon from "@mui/icons-material/ContactSupport"
import PublishIcon from "@mui/icons-material/Publish"
import { AssignmentOutlined, CorporateFare } from "@mui/icons-material"
import ViewCompactIcon from "@mui/icons-material/ViewCompact"
import Groups3Icon from "@mui/icons-material/Groups3"
import PersonIcon from "@mui/icons-material/Person"

// Constants
import { permissions, permissionValues } from "../constants/permissions"
import {
	ARTIST_FILE_OBJECT,
	ART_FILE_OBJECT,
	CONTACT_FILE_OBJECT,
	DEAL_FILE_OBJECT,
	LISTING_FILE_OBJECT,
} from "../constants/values"

import AdminRouter from "../AdminConsole/AdminRouter"
import ShowcaseArtists from "../Showcase/ShowcaseArtists"
import ShowcaseContacts from "../Showcase/Contacts/ShowcaseContacts"
import ShowcaseArtist from "../Showcase/ShowcaseArtist"
import Available from "../Showcase/Artist/Available"
import ShowcaseArtSearch from "../Showcase/Art/ShowcaseArtSearch"
import PastSales from "../Showcase/Artist/PastSales"
import LoanedReturned from "../Showcase/Artist/LoanedReturned"
import PrivatelyOwned from "../Showcase/Artist/PrivatelyOwned"
import MuseumOwned from "../Showcase/Artist/MuseumOwned"
import ShowcaseContactTabbedPage from "../Showcase/Contacts/ShowcaseContactTabbedPage"

import ShowcaseContactArt from "../Showcase/Contacts/ShowcaseContactArt"
import ShowcaseContact from "../Showcase/Contacts/ShowcaseContact"
import ShowcaseFilesPage from "../Showcase/ShowcaseFilesPage"
import ShowcaseCuratedListTabbedPage from "../Showcase/CuratedLists/ShowcaseCuratedListTabbedPage"
import ShowcaseCuratedListSearch from "../Showcase/CuratedLists/ShowcaseCuratedListSearch"
import ShowcaseCuratedListArt from "../Showcase/CuratedLists/ShowcaseCuratedListArt"
import ShowcaseExhibitionTabbedPage from "../Showcase/Exhibitions/ShowcaseExhibitionTabbedPage"
import ShowcaseExhibitionArt from "../Showcase/Exhibitions/ShowcaseExhibitionArt"
import ShowcaseExhibitionSearch from "../Showcase/Exhibitions/ShowcaseExhibitionSearch"
import ShowcaseArtFairSearch from "../Showcase/ArtFair/ShowcaseArtFairSearch"
import ShowcaseArtFairTabbedPage from "../Showcase/ArtFair/ShowcaseArtFairTabbedPage"
import ShowcaseArtFairArt from "../Showcase/ArtFair/ShowcaseArtFairArt"
import DealContract from "../Deals/Deal/DealContract"
import UserNotificationsPage from "../User/Notifications/UserNotificationsPage"
import AdminUserGroups from "../AdminConsole/AdminUserGroups"
import AdminGallery from "../AdminConsole/AdminGallery"

/**
 * A single page of the Levy Gorvy application
 * @param {string} route - the routes (array) that direct to the page
 * @param {any} component - the reference to the component
 * @param {string} name - the name of the page, used in navigation
 * @param {boolean} visible - if true, hides the tab not the route
 * @param {boolean} admin - navigation only displayed if in admin console
 * @param {any} icon - a mui icon to show upon display of navigation button
 * @param {int[]} permissions - the permissions or array of key value pairs representing the required permissions for this route
 * @param {string[]} permissionedBooleans - if all booleans of the the object are null, filter out the route
 * @param {boolean} everyPermRequired - if this boolean is true, all permission must pass for the route to be displayed
 * @param {boolean} navigateToFirst - if this string is truthy, the sidedrawer page will navigate to the first tabbed page entry
 */

function Page(
	route,
	component,
	name,
	visible = true,
	admin = false,
	icon = null,
	permissions = null,
	permissionedBooleans = null,
	everyPermRequired = false,
	navigateToFirst = false,
) {
	this.route = route
	this.component = component
	this.name = name
	this.visible = visible
	this.admin = admin
	this.icon = icon
	this.permissions = permissions
	this.permissionedBooleans = permissionedBooleans
	this.everyPermRequired = everyPermRequired
	this.navigateToFirst = navigateToFirst
}

const userPage = new Page("/user/*", User, "User", false)
const supportPage = new Page(
	"/support/*",
	Support,
	"Support",
	false,
	false,
	<ContactSupportIcon id="support-icon" />,
)

const pages = {
	mainPages: {
		pages: [
			new Page("/home", Home, "Home"),
			new Page(
				"/art/:id/*",
				ArtPiece,
				"Art",
				false,
				false,
				null,
				permissions.ART,
			),
			new Page("/art", Art, "Art", true, false, null, permissions.ART),
			new Page(
				"/contacts/:id/*",
				Contact,
				"Contact",
				false,
				false,
				null,
				permissions.CONTACTS,
			),
			new Page(
				"/contacts",
				Contacts,
				"Contacts",
				true,
				false,
				null,
				permissions.CONTACTS,
			),
			new Page(
				"/artists/:id/*",
				Artists,
				"Artists",
				false,
				false,
				null,
				permissions.ARTISTS,
			),
			new Page(
				"/artists",
				SearchArtists,
				"Artists",
				true,
				false,
				null,
				permissions.ARTISTS,
			),
			new Page(
				"/deals/:id/*",
				Deal,
				"Deal",
				false,
				false,
				null,
				permissions.DEALS,
			),
			new Page("/deals", Deals, "Deals", true, false, null, permissions.DEALS),
			new Page(
				"/listings/:id/*",
				Listing,
				"Listing",
				false,
				false,
				null,
				permissions.LISTINGS,
			),
			new Page(
				"/listings",
				Listings,
				"Listings",
				true,
				false,
				null,
				permissions.LISTINGS,
			),
			new Page("/tags/:id/*", Tag, "Tag", false),
			new Page("/tags", Tags, "Tags", true, false, null, [
				permissions.ART_TAGS,
				permissions.ARTISTS_TAGS,
				permissions.CONTACTS_TAGS,
				permissions.DEALS_TAGS,
				permissions.LISTINGS_TAGS,
			]),
			new Page("/tasks", SearchTasks, "Tasks", true, false, null),
			userPage,
			supportPage,
		],
	},

	adminPages: {
		pages: [
			/* When adding new pages to the admin console, ensure to link them in AdminRouter.js as well */
			new Page(
				"admin/users/:id/*",
				AdminConsole,
				"Profile",
				false,
				true,
				null,
				permissions.ADMIN_USERS,
			),
			new Page(
				"admin/users",
				AdminUserList,
				"Users",
				true,
				true,
				<PersonIcon />,
				permissions.ADMIN_USERS,
			),
			new Page(
				"admin/imports/help",
				AdminImportsHelp,
				"Import Help",
				false,
				true,
				null,
				permissions.ADMIN_IMPORT_TOOL,
			),
			new Page(
				"admin/imports",
				AdminImports,
				"Imports",
				true,
				true,
				<PublishIcon />,
				permissions.ADMIN_IMPORT_TOOL,
			),
			new Page(
				"admin/groups/*",
				AdminUserGroups,
				"Groups",
				true,
				true,
				<Groups3Icon />,
				permissions.ADMIN_USERS,
				null,
				null,
				"newyork",
			),
			new Page("admin", AdminRouter, "Admin", false), // Must be at the bottom of the list
		],
	},

	showcasePages: {
		pages: [
			new Page(
				"showcase/artists/:id/*",
				ShowcaseArtist,
				"Artists",
				false,
				false,
				null,
				[permissions.ART, permissions.ARTISTS],
				false,
				true,
			),
			new Page(
				"showcase/artists",
				ShowcaseArtists,
				"Artists",
				true,
				false,
				null,
				[permissions.ART, permissions.ARTISTS],
				false,
				true,
			),
			new Page(
				"showcase/art",
				ShowcaseArtSearch,
				"Art",
				true,
				false,
				null,
				permissions.ART,
			),

			new Page(
				"showcase/artfairs/:id/*",
				ShowcaseArtFairTabbedPage,
				"Art Fair",
				false,
				false,
				null,
				permissions.ART,
			),
			new Page(
				"showcase/artfairs",
				ShowcaseArtFairSearch,
				"Art Fairs",
				true,
				false,
				<AssignmentOutlined />,
				permissions.ART,
			),
			new Page(
				"showcase/exhibitions/:id/*",
				ShowcaseExhibitionTabbedPage,
				"Exhibition",
				false,
				false,
				null,
				permissions.ART,
			),
			new Page(
				"showcase/exhibitions",
				ShowcaseExhibitionSearch,
				"Exhibitions",
				true,
				false,
				<CorporateFare />,
				permissions.ART,
			),

			new Page(
				"showcase/curated_lists/:id/*",
				ShowcaseCuratedListTabbedPage,
				"Curated List",
				false,
				false,
				null,
				permissions.ART,
			),
			new Page(
				"showcase/curated_lists",
				ShowcaseCuratedListSearch,
				"Curated Lists",
				true,
				false,
				<ViewCompactIcon />,
				permissions.ART,
			),
			new Page(
				"showcase/contacts/:id/*",
				ShowcaseContactTabbedPage,
				"Contacts",
				false,
				false,
				null,
				permissions.ART,
			),
			new Page(
				"showcase/contacts",
				ShowcaseContacts,
				"Contacts",
				true,
				false,
				null,
				[permissions.ART, permissions.CONTACTS],
				false,
				true,
			),
		],
	},

	artPages: {
		pages: [
			new Page("details", ArtDetailPage, "Details"),
			new Page(
				"financials-and-transactions",
				FinancialsAndTransactions,
				"Financials & Transactions",
			),
			new Page("operations", ArtOperations, "Operations"),
			new Page(
				"listings",
				ArtListings,
				"Listings",
				true,
				false,
				null,
				permissions.LISTINGS,
			),
			new Page(
				"offers-and-reserves",
				ArtOffersAndReserves,
				"Offers & Reserves",
				true,
				false,
				null,
				permissions.DEALS,
			),
			new Page(
				"notes",
				ArtNotes,
				"Notes",
				true,
				false,
				null,
				permissions.ART_NOTES,
			),
			new Page(
				"tasks",
				ArtTasks,
				"Tasks",
				true,
				false,
				null,
				permissions.ART_TASKS,
			),
			new Page("datalog", ArtDataLog, "Data Log"),
			new Page("files", ArtFilesPage, "Files"),
			new Page("access", ArtAccess, "Access", true, false, null),
		],
		options: {
			object: "art",
			fileObjectId: ART_FILE_OBJECT,
			permissionVal: permissions.ART,
			updateQuery: UPDATE_ART_FILES,
			updateFileQuery: UPDATE_ART_FILE,
			uploadFile: true,
			toggleFavorite: true,
			toggleFlag: true,
			createFactSheet: true,
			createLabelSheet: true,
			createShippingForm: true,
			tag: true,
			createDeal: true,
			showcasePreview: true,
			delete: true,
			info: true,
			findMode: true,
		},
	},

	artistPages: {
		pages: [
			new Page("details", ArtistDetailPage, "Details"),
			new Page("art", ArtistArtPage, "Art", true, false, null, permissions.ART),
			new Page("research", ArtistResearch, "Research"),
			new Page(
				"listings",
				ArtistListings,
				"Listings",
				true,
				false,
				null,
				permissions.LISTINGS,
			),
			new Page(
				"notes",
				ArtistNotes,
				"Notes",
				true,
				false,
				null,
				permissions.ARTISTS_NOTES,
			),
			new Page(
				"tasks",
				ArtistTasks,
				"Tasks",
				true,
				false,
				null,
				permissions.ARTISTS_TASKS,
			),
			new Page("datalog", ArtistDataLog, "Data Log"),
			new Page("files", ArtistFilesPage, "Files"),
		],
		options: {
			object: "artist",
			fileObjectId: ARTIST_FILE_OBJECT,
			permissionVal: permissions.ARTISTS,
			updateQuery: UPDATE_ARTIST_FILES,
			updateFileQuery: UPDATE_ARTIST_FILE,
			uploadFile: true,
			toggleFavorite: true,
			toggleFlag: true,
			tag: true,
			delete: true,
			info: true,
			findMode: true,
		},
	},

	dealPages: {
		pages: [
			new Page("details", DealDetails, "Details"),
			new Page(
				"contract",
				DealContract,
				"Contract",
				true,
				false,
				null,
				(state) => state.getDeal?.deal_type?.value !== "Known Source",
			),
			new Page(
				"notes",
				DealNotes,
				"Notes",
				true,
				false,
				null,
				permissions.DEALS_NOTES,
			),
			new Page(
				"tasks",
				DealTasks,
				"Tasks",
				true,
				false,
				null,
				permissions.DEALS_TASKS,
			),
			new Page("datalog", DealDatalog, "Data Log"),
			new Page("files", DealFilesPage, "Files"),
			new Page("access", DealAccess, "Access", true, false, null),
		],
		options: {
			object: "deal",
			fileObjectId: DEAL_FILE_OBJECT,
			permissionVal: permissions.DEALS,
			createConsignmentForm: true,
			updateQuery: UPDATE_DEAL_FILES,
			updateFileQuery: UPDATE_DEAL_FILE,
			uploadFile: true,
			toggleFavorite: true,
			toggleFlag: true,
			delete: true,
			info: true,
			findMode: true,
		},
	},

	listingPages: {
		pages: [
			new Page("details", ListingDetailPage, "Details"),
			new Page("art", ListingArt, "Art", true, false, null, permissions.ART),
			new Page(
				"contacts",
				ListingContacts,
				"Contacts",
				true,
				false,
				null,
				permissions.CONTACTS,
			),
			new Page(
				"notes",
				ListingNotes,
				"Notes",
				true,
				false,
				null,
				permissions.LISTINGS_NOTES,
			),
			new Page(
				"tasks",
				ListingTasks,
				"Tasks",
				true,
				false,
				null,
				permissions.LISTINGS_TASKS,
			),
			new Page("datalog", ListingDataLog, "Data Log"),
			new Page("files", ListingFilesPage, "Files"),
		],
		options: {
			object: "listing",
			fileObjectId: LISTING_FILE_OBJECT,
			permissionVal: permissions.LISTINGS,
			updateQuery: UPDATE_LISTING_FILES,
			updateFileQuery: UPDATE_LISTING_FILE,
			uploadFile: true,
			toggleFavorite: true,
			toggleFlag: true,
			tag: true,
			showcasePreview: true,
			showcase: true,
			changeListingType: true,
			delete: true,
			info: true,
			findMode: true,
		},
	},

	userPages: {
		pages: [
			new Page("profile", Details, "Profile"),
			new Page("permissions", UserPermissionsPage, "Permissions"),
			new Page("notifications", UserNotificationsPage, "Notifications"),
			new Page("datalog", UserDataLog, "Data Log"),
		],
	},

	tagPages: { pages: [new Page("details", TagDetail, "Details")] },

	contactPages: {
		pages: [
			new Page("details", ContactDetail, "Details"),
			new Page(
				"art-collection-and-known-works",
				ContactArtCollection,
				"Art Collection & Known Works",
				true,
				false,
				null,
				permissions.ART,
			),
			new Page(
				"offers-and-reserves",
				ContactOffersAndReserves,
				"Offers & Reserves",
				true,
				false,
				null,
				permissions.DEALS,
				["publicAccess"],
			),
			new Page(
				"listings",
				ContactListings,
				"Listings",
				true,
				false,
				null,
				permissions.LISTINGS,
			),
			new Page(
				"notes",
				ContactNotes,
				"Notes",
				true,
				false,
				null,
				permissions.CONTACTS_NOTES,
			),
			new Page(
				"tasks",
				ContactTasks,
				"Tasks",
				true,
				false,
				null,
				permissions.CONTACTS_TASKS,
			),
			new Page("datalog", ContactDataLog, "Data Log", true, false, null, null, [
				"publicAccess",
			]),
			new Page("files", ContactFilesPage, "Files", true, false, null, null, [
				"publicAccess",
			]),
			new Page("access", ContactAccess, "Access", true, false, null),
		],
		options: {
			object: "contact",
			fileObjectId: CONTACT_FILE_OBJECT,
			permissionVal: permissions.CONTACTS,
			updateQuery: UPDATE_CONTACT_FILES,
			updateFileQuery: UPDATE_CONTACT_FILE,
			uploadFile: true,
			toggleFavorite: true,
			toggleFlag: true,
			tag: true,
			createDeal: true,
			showcasePreview: true,
			delete: true,
			info: true,
			findMode: true,
		},
	},

	supportPages: {
		pages: [
			new Page("releasenotes", ReleaseNotes, "Release Notes"),
			new Page("faq", Faq, "FAQ"),
			new Page("manual", PdfManual, "Manual"),
		],
	},

	adminUserPages: {
		pages: [
			new Page("profile", AdminUser, "Profile"),
			new Page("permissions", AdminPermissions, "Permissions"),
			new Page("notifications", UserNotificationsPage, "Notifications"),
			new Page("datalog", AdminDataLog, "Data Log"),
		],
		options: {
			object: "user",
			findMode: true,
		},
	},

	adminGalleryPages: {
		pages: [
			new Page("newyork", AdminGallery, "New York, US"),
			new Page("london", AdminGallery, "London, UK"),
			new Page("paris", AdminGallery, "Paris, Europe"),
			new Page("hongkong", AdminGallery, "Hong Kong, Asia"),
			new Page("zurich", AdminGallery, "Zürich, Europe"),
		],
		options: {
			object: "user",
			findMode: false,
		},
	},

	showcaseArtistPages: {
		pages: [
			new Page("available", Available, "Available"),
			new Page("past-sales", PastSales, "Past Sales"),
			new Page("loaned-returned", LoanedReturned, "Loaned/Returned"),
			new Page("privately-owned", PrivatelyOwned, "Privately Owned"),
			new Page("museum-owned", MuseumOwned, "Museum Owned"),
		],
		options: {
			findMode: false,
			toggleFlag: false,
			showcaseInformationToggle: true,
			curatedLists: true,
		},
	},
	artFairPages: {
		pages: [
			new Page("art", ShowcaseArtFairArt, "Art"),
			new Page("files", ShowcaseFilesPage, "Files"),
		],
		options: {
			findMode: false,
			toggleFlag: false,
			showcaseInformationToggle: true,
			object: "listing",
			fileObjectId: LISTING_FILE_OBJECT,
			updateQuery: UPDATE_LISTING_FILES,
			updateFileQuery: UPDATE_LISTING_FILE,
			curatedLists: true,
		},
	},
	exhibitionPages: {
		pages: [
			new Page("art", ShowcaseExhibitionArt, "Art"),
			new Page("files", ShowcaseFilesPage, "Files"),
		],
		options: {
			findMode: false,
			toggleFlag: false,
			menu: false,
			showcaseInformationToggle: true,
			object: "listing",
			fileObjectId: LISTING_FILE_OBJECT,
			updateQuery: UPDATE_LISTING_FILES,
			updateFileQuery: UPDATE_LISTING_FILE,
			curatedLists: true,
			showcasePdf: true,
		},
	},
	curatedListPages: {
		pages: [
			new Page("art", ShowcaseCuratedListArt, "Art"),
			new Page("files", ShowcaseFilesPage, "Files"),
		],
		options: {
			findMode: false,
			toggleFlag: false,
			showcaseInformationToggle: true,
			object: "listing",
			fileObjectId: LISTING_FILE_OBJECT,
			updateQuery: UPDATE_LISTING_FILES,
			updateFileQuery: UPDATE_LISTING_FILE,
			curatedLists: true,
			showcasePdf: true,
		},
	},
	showcaseContact: {
		pages: [
			new Page("contact", ShowcaseContact, "Contact Information"),
			new Page("art", ShowcaseContactArt, "Art Collection"),
		],
		options: {
			findMode: false,
			toggleFlag: false,
			showcaseInformationToggle: true,
			curatedLists: true,
		},
	},
}

function filteredPages(pagegroup, permissions) {
	return pages[pagegroup].pages.filter((page) => {
		if (page.permissions) {
			if (Array.isArray(page.permissions)) {
				if (page.everyPermRequired) {
					return page.permissions.every((perm) => {
						const permission =
							permissions &&
							permissions.find((element) => element.permission_id == perm)

						if (permission?.permission_value_id == permissionValues.NO)
							return false
						if (permission?.permission_value_id == permissionValues.CANNOT_SEE)
							return false
						return true
					})
				}

				page.permissions.forEach((perm) => {
					const permission =
						permissions &&
						permissions.find((element) => element.permission_id == perm)

					if (permission?.permission_value_id == permissionValues.NO)
						return false
					if (permission?.permission_value_id == permissionValues.CANNOT_SEE)
						return false
				})

				return true
			} else {
				const id = page.permissions
				const permission =
					permissions &&
					permissions.find((element) => element.permission_id == id)

				if (permission?.permission_value_id == permissionValues.NO) return false
				if (permission?.permission_value_id == permissionValues.CANNOT_SEE)
					return false

				return true
			}
		}

		return true
	})
}
export default pages
export { filteredPages, supportPage, userPage }
