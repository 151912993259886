/* eslint-disable eqeqeq */
import { useMutation, useQuery } from "@apollo/client"
import { withApollo } from "@apollo/client/react/hoc"
import React, { useCallback, useContext, useEffect } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import CollapsibleSearchCard from "../common/components/CollapsibleSearchCard"
import { SavedSearch } from "../common/SavedSearch"
import { ARTIST_SEARCH_OBJECT, DEFAULT_SEARCH_LIMIT } from "../constants/values"
import { DYNAMIC_ARTISTS_NAV } from "../navigation/Queries"
import useNavigation from "../navigation/useNavigation"
import { SELECT_ALL_ARTISTS } from "../Search/ActionButtons/Queries"
import GlobalSearchFilters, { getOperatorValue, initialFilterState } from "../Search/GlobalSearchFilters"
import { ARTIST_FLAG_QUERY, DYNAMIC_ARTISTS_SEARCH, GET_ARTIST_FILTER_FIELDS, GET_SEARCHES, UPDATE_SEARCH } from "../Search/Queries"
import SearchQuickView from "../Search/SearchQuickView"
import { filterMap, filterSearchTerms, handleDeleteSearch, updateLabel } from "../Search/unifiedSearchHelpers"
import { severity } from "../Snackbar/CustomizedSnackbar"
import { AuthStateContext, DispatchContext } from "../store"
import ArtistQuickView from "./Artist/ArtistQuickView"
import CreateArtistQuickView from "./Artist/CreateArtistQuickView"
import { ArtistsDataGrid } from "./ArtistsDataGrid"
import SearchArtists from "./SearchArtists"
import ArtExhibitionQuickView from "../ArtExhibitionQuickView/ArtExhibitionQuickView"

const Artists = (props) => {


	const { push: pushNav } = useNavigation()
	const navigate = useNavigate()
	const location = useLocation()
	const userAuthentication = useContext(AuthStateContext)

	const prevSearch = location.state

	// Snackbar
	const dispatch = useContext(DispatchContext)
	const openSnackbar = useCallback((severity, text) => {
		dispatch({ type: 'openSnackBar', payload: { severity, text }})
	}, [dispatch])

	const closeSnackbar = () => {
		dispatch({ type: 'closeSnackBar'})
	}

	// Mutations

	const [updateSearch] = useMutation(UPDATE_SEARCH)


	// State that comes from prevSearch
	const [order, setOrder] = React.useState(prevSearch?.order || "asc")
	const [orderBy, setOrderBy] = React.useState(prevSearch?.orderBy || null)
	const [artists, setArtists] = React.useState(prevSearch?.artists ?? [])
	const [activeStep, setActiveStep] = React.useState(prevSearch?.activeStep ?? 0)
	const [totalItems, setTotalItems] = React.useState(prevSearch?.totalItems ?? null)
	const [steps, setSteps] = React.useState(prevSearch?.steps ?? 1)
	const [selectedArtist, setSelectedArtist] = React.useState(null)
	const [advancedSearch, setAdvancedSearch] = React.useState(prevSearch?.advancedSearch ?? false)
	const [currentSearch, setCurrentSearch] = React.useState(prevSearch?.currentSearch ?? new SavedSearch({
		object_id: ARTIST_SEARCH_OBJECT,
		search_terms: [],
		is_global: true,
		user_id: userAuthentication.user?.id
	}))
	const [limit, setLimit] = React.useState(prevSearch?.limit ?? DEFAULT_SEARCH_LIMIT)

	// Normal State
	const [value, setValue] = React.useState(0)
	const [flaggedCount, setFlaggedCount] = React.useState(null)
	const [firstRender, setFirstRender] = React.useState(true)
	const [selection, setSelection] = React.useState([])
	const [savedSearches, setSavedSearches] = React.useState([])
	const [searchLoading, setSearchLoading] = React.useState(null)
	const [createArtist, setCreateArtist] = React.useState(null)
	
	const setFilters = (filters) => {
		setCurrentSearch({
			...currentSearch,
			search_terms: filters
		})
	}

	const currentFilters = [
		'gallery_contacts',
		'query',
		'artist_type',
		'nationality',
		'isFavorite'
	]

	const advancedFilters = filterSearchTerms(currentSearch.search_terms)?.filter(term => {
		if (currentFilters.includes(term.field)) return false
		return true
	})

	useEffect(() => {
		if (currentSearch.search_terms.length == 0 && advancedSearch) {
			setCurrentSearch(new SavedSearch({
				object_id: ARTIST_SEARCH_OBJECT,
				search_terms: [initialFilterState()],
				is_global: true,
				user_id: userAuthentication.user?.id
			}))
		}

	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentSearch.search_terms, userAuthentication.user?.id, advancedSearch])

	// Search on change of order
	React.useEffect(() => {
		setFirstRender(false)
		if(artists)
			if (artists.length !== 0 && artists[0].disabled !== true && !firstRender) {
				handleSubmit()
			}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [order, orderBy, limit, activeStep])

	/**
	 * Save current page state for if one returns to it through
	 * back arrow.
	 *
	 * @returns current page state
	 */
	function updateHistory (index) {
		const currentPageState = {
			currentSearch,
			orderBy,
			order,
			artists,
			totalItems,
			limit,
			steps,
			activeStep,
			advancedSearch,
			searchPage: location.pathname
		}
		const offset = index ? artists.findIndex(a => a.id == index) : artists.indexOf(selectedArtist)
		const cursor = activeStep * limit + offset
		const variables = makeVariables(cursor, currentSearch, orderBy, order, currentSearch?.search_terms ?? [])
		variables.limit = 3
		currentPageState.variables = variables
		navigate(location.pathname, { state: currentPageState })
		return currentPageState
	}

	function saveAndGo(path, row) {
		// persist current page state in history
		const state = updateHistory(row)
		pushNav({
			url: location.pathname,
			state,
			query: DYNAMIC_ARTISTS_NAV
		}, state.variables?.cursor)
		// navigate to new page (with this page's query and `variables`)
		navigate(path, { state })
	}

	// Save search to state on selecting a Artist
	React.useEffect(() => {
		if (selectedArtist) updateHistory()
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedArtist])


	// Get saved searches
	const {loading, error } = useQuery(GET_SEARCHES, {
		skip:  !userAuthentication.user?.id,
		variables: {
			userId: userAuthentication.user?.id,
			objectId: ARTIST_SEARCH_OBJECT,
			global: true
		},
		onCompleted: (data) => {
			if (data && data.getSearches && data.getSearches?.length !== 0) {
				setSavedSearches(data.getSearches
					.map(search => new SavedSearch(search)))
			} else {
				// console.log("No saved searches were found.")
			}
		}
	})

	if (error) {
		console.log(error)
		openSnackbar(severity.ERROR, "Error retrieving your saved searches.")
	}


	const makeVariables = (cursor, currentSearch, orderBy, order, filters, limitOverride) => {
		return ({
			cursor,
			limit: limitOverride ?? limit,
			field: orderBy,
			direction: order.toUpperCase(),
			filters: filters ? filterMap(filters) : filterMap(currentSearch.search_terms),
			thumbnailResolution: "128x128"
		})
	}

	const handleSubmit = ({cursor} = {}, filters = null, searchId) => {

		setSearchLoading(true)
		let submitCursor
		if (cursor == undefined) {
			submitCursor = activeStep * limit
		} else {
			submitCursor = cursor
		}
		openSnackbar(severity.INFO, "Loading search results...")

		const variables = makeVariables(
			submitCursor, currentSearch, orderBy, order, filters
		)
		setValue(1)

		// Set filters when handle submit is not coming from saved search list
		if (filters?.length && !searchId) setFilters(filters)

		props.client
			.query({ query: DYNAMIC_ARTISTS_SEARCH, variables })
			.then((result) => {

				const {data} = result

				if (data.searchDynamicArtists?.items) {
					setArtists(data.searchDynamicArtists.items)

					if (data.searchDynamicArtists.items < 1) setSteps(1)
					else setSteps((Math.ceil(data.searchDynamicArtists.totalItems / limit)))

					setTotalItems(data.searchDynamicArtists.totalItems || 0)
					setSearchLoading(false)
					if (!data.searchDynamicArtists.totalItems) {
						openSnackbar(severity.WARNING, "There were no results.")
					} else {
						closeSnackbar()
					}

				} else {
					console.error(data)
					setSearchLoading(false)
					openSnackbar(severity.ERROR, "There was an error searching art.")
				}

			})
			.catch((error) => {
				console.error(error)
				setSearchLoading(false)
				openSnackbar(severity.ERROR, "Could not search art.")
			})

	}

	const handleReset = (page) => {

		setCurrentSearch(new SavedSearch({
			object_id: ARTIST_SEARCH_OBJECT,
			search_terms: [],
			is_global: true,
			user_id: userAuthentication.user?.id
		}))
		setArtists([])
		setCreateArtist(false)
		setSteps(1)
		setActiveStep(0)
		setTotalItems(null)
		setOrder("asc")
		setOrderBy(null)
		setSelectedArtist(null)
		setSearchLoading(false)
		setValue(0)
	}

	const updateArtistSavedSearchLabel = (search, label) =>
		updateLabel(
			search,
			label,
			updateSearch,
			savedSearches,
			setSavedSearches,
			openSnackbar
		)

	const handleDeleteArtistSavedSearch = (search) =>
		handleDeleteSearch(
			search,
			updateSearch,
			savedSearches,
			setSavedSearches,
			openSnackbar
		)

	// Get the dynamic artist filter fields
	const { data: artistFilterFields, error: artistFilterError } = useQuery(GET_ARTIST_FILTER_FIELDS, {
		fetchPolicy: 'cache-first',
		onCompleted: ({ searchDynamicArtistsFilterFields }) => {
			const storedFields = location?.state?.fields
			if (storedFields) {
				setAdvancedSearch(true)
				const fieldFilters = Object.entries(storedFields)
					.map(([field, value], i) => {
						const filterField = searchDynamicArtistsFilterFields
							.find(f => f.name == field)
						let type = getOperatorValue(filterField?.type)
						return {
							field,
							value,
							type,
							id: new Date().getTime() + i
						}
					})

				setFilters(fieldFilters)
				handleSubmit({}, fieldFilters)
			}
		}
	})

	if (artistFilterError) {
		openSnackbar(severity.ERROR, 'There was an error retrieving filterable fields for this search.')
		console.error(artistFilterError)
	}

	const renderQuickView = () => {
		if (createArtist) {
			return (
				<CreateArtistQuickView
					setCreateArtist={setCreateArtist}
				></CreateArtistQuickView>
			)
		}

		else if (selectedArtist) {
			return (
				<ArtistQuickView
					id={selectedArtist.id}
					onClose={() => setSelectedArtist(null)}
				></ArtistQuickView>
			)
		}

		else {
			return (
				<SearchQuickView
					savedSearches={savedSearches}
					setSavedSearches={setSavedSearches}
					setCurrentSearch={setCurrentSearch}
					setFilters={setFilters}
					handleSubmit={handleSubmit}
					currentSearch={currentSearch}
					setAdvancedSearch={setAdvancedSearch}
					savedSearchesLoading={loading}
					updateLabel={updateArtistSavedSearchLabel}
					handleDeleteSearch={handleDeleteArtistSavedSearch}
					setSelection={setSelection}
					selection={selection}
					totalItems={totalItems}
					flaggedCount={flaggedCount}
					setFlaggedCount={setFlaggedCount}
					makeVariables={makeVariables}
					orderBy={orderBy}
					order={order}
					searchQuery={DYNAMIC_ARTISTS_SEARCH}
					entity="artist"
					bulkActionQuery={SELECT_ALL_ARTISTS}
					flagQuery={ARTIST_FLAG_QUERY}
					setValue={setValue}
					value={value}
					filterFields={artistFilterFields?.searchDynamicArtistsFilterFields}
					requery={handleSubmit}
				/>
			)
		}
	}

	const variables = makeVariables(0, currentSearch, orderBy, order)

	return (
		<>
			<section className="main-page">

				{ renderQuickView() }

				<div style={{
					display: 'flex',
					flex: '1 1 auto',
					overflow: 'auto',
					flexDirection: 'column',
				}}>
					<CollapsibleSearchCard>
						{advancedSearch ?
							<GlobalSearchFilters
								loading={searchLoading}
								currentSearch={currentSearch}
								setCurrentSearch={setCurrentSearch}
								onReset={handleReset}
								onSearch={handleSubmit}
								object_id={ARTIST_SEARCH_OBJECT}
								advancedSearch={advancedSearch}
								setAdvancedSearch={setAdvancedSearch}
								setCreateEntity={setCreateArtist}
								filterFields={artistFilterFields?.searchDynamicArtistsFilterFields}
								typeName="Artist"
								onFindMode={() => navigate('/artists/findmode/details')}
								savedSearches={savedSearches}
								setSavedSearches={setSavedSearches}
								prevSearch={prevSearch?.currentSearch}
							/> :
							<SearchArtists
								loading={searchLoading}
								currentFilters={currentSearch.search_terms}
								resetSearch={handleReset}
								currentSearch={currentSearch}
								setCurrentSearch={setCurrentSearch}
								handleSubmit={handleSubmit}
								setActiveStep={setActiveStep}
								setTotalItems={setTotalItems}
								advancedSearch={advancedSearch}
								setAdvancedSearch={setAdvancedSearch}
								setCreateArtist={setCreateArtist}
								advancedFilters={advancedFilters}
								selection={selection}
								setSelection={setSelection}
								onFindMode={() => navigate('/artists/findmode/details')}
								prevSearch={prevSearch?.currentSearch}
								savedSearches={savedSearches}
								setSavedSearches={setSavedSearches}
							></SearchArtists>}
					</CollapsibleSearchCard>

					<ArtistsDataGrid
				
						rows={artists}
						loading={searchLoading}
						saveAndGo={saveAndGo}
						setRows={setArtists}

						setFlaggedCount={setFlaggedCount}
						userId={userAuthentication.user?.id}
						mainSearchPage={true}
						totalItems={totalItems}
						onSelect={setSelectedArtist}
						selectedRow={selectedArtist}
						limit={limit}
						setLimit={setLimit}
						activeStep={activeStep}
						setActiveStep={setActiveStep}

						sortModel={ orderBy ? [{ field: orderBy, sort: order }] : []}
						onSortModelChange={newSort => {
							if (newSort.length) {
								setOrderBy(newSort[0].field)
								setOrder(newSort[0].sort)
							} else {
								setOrderBy(null)
								setOrder('asc')
							}
						}}
						// flagmenu
						filterTo={newFilter => {
							setSelection([])
							setFilters(newFilter)
							handleSubmit({cursor: 0}, newFilter)
							setValue(1)
							setSelectedArtist(null)
						}}
						type="Artist"
						variables={variables}
					
					/>
				</div>
				<ArtExhibitionQuickView />
			</section>
		</>
	)
}

export default withApollo(Artists)
