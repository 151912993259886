const ArtDetail = (props) => {
	const { art } = props
	const { artist, dimension, imgUrl, inventory_number, inventory_number_prefix, inventory_number_suffix, title: artTitle, year } = art || {}

	const title = artist?.first_name + ' ' + artist?.last_name
	const subTitle = artTitle + ', ' + year
	const inventoryNo = `${inventory_number_prefix}-${inventory_number}-${inventory_number_suffix}`

	return (
		<div className="flex flex-col w-full h-full">
			<div className={`flex bg-[#bdbdbd] ${!imgUrl ? 'p-4' : ''} shadow rounded h-3/6`}>
				<img src={imgUrl || `/images/icons/White/ArtImage.svg`} alt={subTitle} className="w-full h-full object-cover" />
			</div>
			<div className="flex flex-col text-sm h-3/6 pt-3">
				<div className="text-black">{title}</div>
				<div className="text-grey italics">{subTitle}</div>
				<div className="text-black">{inventoryNo}</div>
                <div className="text-black">{dimension?.[0]?.description}</div>
			</div>
		</div>
	)
}

export { ArtDetail }
