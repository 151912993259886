import routes from "../../navigation/routes"
import ArtQuickView from "./ArtQuickView"
import { GET_ART_DETAILS } from "../Queries"
import { useParams } from "react-router-dom"
import { DYNAMIC_ART_SEARCH } from "../../Search/Queries"
import DetailPage from "../../components/DetailPage/DetailPage"

export default function ArtPiece(props) {
	const params = useParams()

	return DetailPage({
		...props,
		routes: routes.artPages.pages,
		Quickview: ArtQuickView,
		query: GET_ART_DETAILS,
		searchGlobalQuery: DYNAMIC_ART_SEARCH,
		options: routes.artPages.options,
		params,
	})
}
