/* eslint-disable eqeqeq */
import "./listing.css"
import { Avatar, IconButton, Tooltip, Select, MenuItem } from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import { DispatchContext } from "../../store"
import { format } from "date-fns"
import { GET_LISTING_QV_DETAILS, UPDATE_LISTING } from "../Queries.js"
import { severity } from "../../Snackbar/CustomizedSnackbar"
import { Skeleton } from "@mui/material"
import { useNavigate, useParams } from "react-router-dom"
import { withApollo } from "@apollo/client/react/hoc"
import AssignmentIcon from "@mui/icons-material/Assignment"
import QuickView from "../../QuickView/QuickView"
import React, { useEffect, useContext, useCallback } from "react"
import { LookupContext } from "../../store"
import QuickViewNotes from "../../QuickView/QuickViewNotes"
import { formatDate, getShowcaseIcon } from "../../common/helpers"
import QuickViewContacts from "../../QuickView/QuickViewContacts"
import ShowcaseModal from "../../Showcase/ShowcaseModal"
import {
	FindModeInput,
	findModeFieldSelector,
	findModeFieldsAtom,
} from "../../navigation/Tabs/TabbedPage"
import { atom, useRecoilCallback, useRecoilState } from "recoil"
import Zoom from "react-medium-image-zoom"
import { Close } from "@mui/icons-material"
import Dropdown from "../../input/Dropdown/DropdownNoBorder"
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded"
import { useMutation } from "@apollo/client"

const EXHIBITION = 1
const ART_FAIR = 2
const SHOWCASE_ELIGABLE = [EXHIBITION, ART_FAIR]

export const listingQuickViewAtom = atom({
	key: "listingQuickViewAtom",
	default: null,
})

const showInSidePanelOptions = [
	{ label: "Yes", value: true, color: "#76EE00" },
	{ label: "No", value: false, color: "#FF4554" },
]

/**
 * This function returns the formatted date range of a listing as a string.
 * @param {=Object} - A listing object.
 */
const dateCell = (row) => {
	if (!row?.start_at) {
		return null
	}
	return (
		(row.start_at ? formatDate(row.start_at, "MMM d, yyyy") : "") +
		(row.end_at ? " - " + format(new Date(row.end_at), "MMM d, yyyy") : "")
	)
}

/**
 * @typedef ListingQuickViewProps
 * @property {import('./Listing').Listing} listing
 */

/**
 * @param {ListingQuickViewProps} props
 */
function ListingQuickView(props) {
	const navigate = useNavigate()
	const lookup = useContext(LookupContext)
	const listingTypes = lookup.data?.getListingTypes
	const [isShownInSidePanel, setIsShownInSidePanel] = React.useState(
		showInSidePanelOptions[0].value,
	)
	const [findModeReady, setFindModeReady] = useRecoilState(
		findModeFieldSelector("showcase_ready"),
	)
	const [listing, setListing] = useRecoilState(listingQuickViewAtom)

	const [notes, setNotes] = React.useState([])
	const [showcaseModal, setShowcaseModal] = React.useState(false)
	const params = useParams()

	const salespersons = listing?.salesperson

	const useStyles = makeStyles(() => ({
		large: {
			width: 285,
			height: 285,
		},
	}))

	const classes = useStyles()

	const [updateListing, { loading: updateListingLoading }] = useMutation(
		UPDATE_LISTING,
		{
			onError: (error) => {
				console.log(error)
				openSnackbar(severity.ERROR, "Could not update showcase.")
			},

			onCompleted: (response) => {
				if (response.updateListing.success === true) {
					openSnackbar(severity.SUCCESS, response.updateListing.message)
					setIsShownInSidePanel(
						response?.updateListing?.listing?.is_shown_in_side_panel,
					)
				} else {
					openSnackbar(severity.ERROR, response.updateListing.message)
				}
			},
		},
	)

	//Snackbar
	const dispatch = useContext(DispatchContext)
	const openSnackbar = useCallback(
		(severity, text) => {
			dispatch({ type: "openSnackBar", payload: { severity, text } })
		},
		[dispatch],
	)

	useEffect(() => {
		if (params.id !== undefined && !props.id && props.state) {
			if (props.state?.getListing?.is_shown_in_side_panel !== undefined) {
				setIsShownInSidePanel(props.state?.getListing?.is_shown_in_side_panel)
			}
			setListing(props.state?.getListing || {})
			setNotes(props.state?.getCriticalListingNotes)
		}
	}, [params.id, props.id, props.state, setListing, setIsShownInSidePanel])

	// Loading Logic
	const [loading, setLoading] = React.useState(true)
	useEffect(() => {
		if (Object.keys(props?.state?.getListing || {}).length) {
			setLoading(false)
		} else setLoading(true)
	}, [props])

	useEffect(() => {
		let active = true

		if (props.id && !props.findMode) {
			setListing(null)
			setNotes([])

			props.client
				.query({
					query: GET_LISTING_QV_DETAILS,
					variables: { id: props.id },
				})
				.then((result) => {
					if (!active) return
					// Snackbar responses
					if (!result.data.getListing && result.errors) {
						openSnackbar(severity.ERROR, result.errors[0].message)
						return
					} else {
						setListing(result.data.getListing)
						setIsShownInSidePanel(result.data.getListing.is_shown_in_side_panel)
						setNotes(result.data.getCriticalListingNotes)
					}

					setLoading(false)
				})
				.catch((e) => {
					if (!active) return
					console.error(e)
					openSnackbar(severity.ERROR, "Could not retrieve listing Quick View.")
					setLoading(false)
				})
		}
		return () => (active = false)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.id])

	let showcaseTooltip = "Showcase Disabled"
	if (listing?.showcase_ready || findModeReady) {
		showcaseTooltip = "Showcase Enabled"
	}
	if (props.findMode && findModeReady == null) {
		showcaseTooltip = ""
	}

	// handle FindMode search
	const handleKeyDown = useRecoilCallback(({ snapshot }) => async (e) => {
		if (!props.findMode || e.key !== "Enter") return
		e.stopPropagation()
		let fields = await snapshot.getPromise(findModeFieldsAtom)
		fields = Object.fromEntries(
			Object.entries(fields).filter(([_, v]) => v != null && v !== ""),
		)
		navigate("/listings", { state: { fields } })
	})

	const avatarListing = !props.findMode ? listing : { created_at: true }

	const isShowcaseEligable = SHOWCASE_ELIGABLE.includes(
		Number(listing?.type_id),
	)

	const getAvatar = () => (
		<Avatar
			alt={avatarListing?.title + " Image"}
			src={avatarListing?.imgUrl}
			variant="rounded"
			style={{
				height: !avatarListing?.imgUrl ? "285px" : "auto",
				width: "285px",
				objectFit: "scale-down",
			}}>
			<AssignmentIcon style={{ fontSize: "150" }} />
		</Avatar>
	)

	return (
		<>
			<QuickView className="quick-view-main">
				<div className="quick-view-inner" onKeyDown={handleKeyDown}>
					<header>
						<h2
							className="quick-view-title"
							data-testid="search-qv-header"
							style={{ display: "flex" }}>
							<div>
								{props.findMode ? (
									<FindModeInput field="listing_type" placeholder="Type" />
								) : (
									<span style={{ fontSize: "20px", fontWeight: "500" }}>
										{
											listingTypes?.find((type) => type.id == listing?.type_id)
												?.value
										}
									</span>
								)}
							</div>
							{isShowcaseEligable ? (
								<Tooltip title={showcaseTooltip}>
									<IconButton
										style={{
											height: "35px",
											width: "35px",
											marginRight: "auto",
										}}
										onClick={() => {
											if (props.findMode) {
												let next = null
												if (findModeReady) next = false
												if (findModeReady == null) next = true
												setFindModeReady(next)
												return
											}
											if (params.id !== undefined) setShowcaseModal(true)
										}}
										size="large">
										{props.findMode
											? getShowcaseIcon({ ready: findModeReady })
											: getShowcaseIcon({ ready: listing?.showcase_ready })}
									</IconButton>
								</Tooltip>
							) : (
								<div style={{ marginRight: "auto" }}></div>
							)}
							{props.onClose && listing?.id && (
								<span>
									<IconButton
										size="large"
										sx={{ height: "35px", width: "35px" }}
										onClick={props.onClose}>
										<Close />
									</IconButton>
								</span>
							)}
						</h2>

						<h2
							className="quick-view-title"
							data-testid="search-qv-header"
							style={{ display: "flex" }}>
							{props.findMode ? (
								<FindModeInput field="title" placeholder="Title" />
							) : (
								<>
									{loading ? (
										<div
											style={{
												display: "flex",
												justifyContent: "space-between",
												width: "100%",
											}}>
											<Skeleton variant="text" animation="wave" width="7em" />
											<Skeleton variant="text" animation="wave" width="4em" />
										</div>
									) : (
										listing?.title || <span>-</span>
									)}
								</>
							)}
						</h2>

						<h3 className="paragraph-1" style={{ fontWeight: 400 }}>
							{props.findMode ? (
								<FindModeInput
									field="location"
									placeholder="Location"
									style={{ marginTop: ".5em", width: "100%" }}
								/>
							) : (
								listing?.location || (
									<>
										{loading ? (
											<Skeleton animation="wave" variant="text" width="8em" />
										) : (
											<span>-</span>
										)}
									</>
								)
							)}
						</h3>

						<h3
							className="paragraph-1"
							style={{ fontWeight: 400, fontSize: "14px" }}>
							{props.findMode ? (
								<div style={{ display: "flex", marginTop: ".5em" }}>
									<FindModeInput
										field="start_at"
										placeholder="Start Date"
										style={{ marginRight: ".5em" }}
									/>
									<FindModeInput field="end_at" placeholder="End Date" />
								</div>
							) : dateCell(listing) ? (
								dateCell(listing)
							) : (
								<>
									{loading ? (
										<Skeleton animation="wave" variant="text" width="14em" />
									) : (
										<span>-</span>
									)}
								</>
							)}
						</h3>
					</header>

					{!props.findMode && !listing?.created_at ? (
						<Skeleton
							animation="wave"
							className={classes.large}
							variant="rectangular"
						/>
					) : (
						<>
							{!props.findMode && avatarListing.imgUrl ? (
								<Zoom
									overlayBgColorEnd="rgba(0, 0, 0, 0.5)"
									overlayBgColorStart="rgba(255, 255, 255, 0)">
									{" "}
									{getAvatar()}{" "}
								</Zoom>
							) : (
								getAvatar()
							)}
						</>
					)}

					{!props.findMode && (
						<div className="flex flex-row items-center w-full pt-4">
							<div className="text-sm text-grey">Show on Side Panel</div>
							<div
								style={{
									display: "flex",
									justifyContent: "space-between",
								}}>
								<Select
									disabled={updateListingLoading}
									inputProps={{
										style: {
											paddingLeft: "0",
										},
									}}
									input={<Dropdown />}
									IconComponent={ExpandMoreRoundedIcon}
									className="hideborder small-selection"
									value={isShownInSidePanel}
									renderValue={(data) => {
										const selectedOption = showInSidePanelOptions.find(
											(option) => option.value === data,
										)

										return (
											<div
												className={"text-sm"}
												style={{
													color: selectedOption?.color,
												}}>
												{selectedOption?.label}
											</div>
										)
									}}
									onChange={(event) => {
										updateListing({
											variables: {
												UpdateListingInput: {
													id: listing.id,
													is_shown_in_side_panel: event.target.value,
												},
											},
										})
									}}
									data-testid="type">
									{showInSidePanelOptions.map((option) => (
										<MenuItem
											key={option.value}
											value={option.value}
											style={{ cursor: "pointer" }}
											data-testid="type-item">
											<span className="text-sm">{option.label}</span>
										</MenuItem>
									))}
								</Select>
							</div>
						</div>
					)}
					<div style={{ marginTop: "1em", width: "100%" }}>
						<QuickViewContacts
							loading={loading}
							salespersons={salespersons}
							findMode={props.findMode}
						/>
					</div>

					{!props.findMode && (
						<QuickViewNotes
							notes={notes}
							entity={listing}
							privateObject={false}
							loading={loading}
						/>
					)}
				</div>
			</QuickView>

			<ShowcaseModal
				open={showcaseModal}
				close={() => setShowcaseModal(false)}
				entity={listing}
				state={props.state}
				setState={props.setState}
				private={listing?.is_private}
				entityName="listing"
				listingMode={true}
			/>
		</>
	)
}

export default withApollo(ListingQuickView)
