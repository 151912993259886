import React from "react"
import ArtListingsTable from "../../common/components/ArtListingsTable"
import ListingSearch from "../../Search/ListingSearch"

const ArtListings = (props) => {
	let art = props.state.getArtPiece || {}

	return props.findMode ? (
		<ListingSearch />
	) : (
		<ArtListingsTable
			art_id={art.id}
			art={art}
			setQVOverride={props.setQVOverride}
		/>
	)
}

export default ArtListings
