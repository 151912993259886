import React, { useContext } from 'react'
import { Paper, Menu, MenuItem, FormControl, Select } from '@mui/material'
import { CancelButton, SaveCardButton } from '../../../input/Buttons'
import Label from '../../../input/Label'
import TextBox from '../../../input/Text/TextBoxThin'
import { withApollo } from '@apollo/client/react/hoc'
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded'
import Dropdown from '../../../input/Dropdown/ThinDropdown'
import { LookupContext } from '../../../store'
import sortBy from 'lodash/sortBy'
import { FindModeInput } from '../../../navigation/Tabs/TabbedPage'
import { Skelly } from '../../../common/components/Skelly'
import CrateDimension from '../CrateDimension'
import TransitionsModal from '../../../navigation/TransitionsModal/TransitionsModal'

const initialState = {
	mouseX: null,
	mouseY: null,
	editable: false,
}

const OperationDetails = (props) => {

	const [state, setState] = React.useState(initialState)
	const [input, setInput] = React.useState({})
	const [crateDimensionModal, setCrateDimensionModal] = React.useState(false)

	const lookup = useContext(LookupContext)
	const insurances = lookup.data?.getInsurance
	const sortedInsurances = sortBy(insurances, [function(o) { return o.value }])

	const handleClick = (event) => {
		if (state.mouseX || state.editable || props.findMode || !props.art?.id) return
		event.preventDefault()
		setState({
			mouseX: event.clientX - 2,
			mouseY: event.clientY - 4,
		})
	}

	const resetInput = (art) => {
		const {
			id,
			frame_description,
			mount_description,
			crate_depth_metric,
			crate_description,
			crate_height_metric,
			crate_width_metric,
			installation_description,
			condition_description,
			insurance_id,
			has_coa,
			customs_status
		} = art

		setInput({
			id,
			frame_description,
			mount_description,
			crate_description,
			crate_depth_metric,
			crate_height_metric,
			crate_width_metric,
			installation_description,
			condition_description,
			insurance_id,
			has_coa,
			customs_status
		})
	}

	React.useEffect(() => {
		resetInput(props.art)
	}, [props.art])

	const handleClose = (option) => {
		if (option === 'edit') {
			setState(Object.assign({}, initialState, { editable: true }))
		} else {
			setState(initialState)
		}
	}

	const handleChange = (e) => updateField(e.target.name, e.target.value)

	const updateField = (field, value) => {
		setInput({
			...input,
			[field]: value
		})
	}
	const editable = state.editable || props.findMode
	
	const currentObject = input
		

	return (
		<Paper
			className="qv-margin"
			onContextMenu={handleClick}
			id="operation-details"
			data-testid="card-details"
		>
			<h1 className="card-title">
				<span>Details</span>
				{(state.editable && !props.findMode) && (
					<>
						<div className="spacer"></div>
						<CancelButton
							variant="contained"
							size="small"
							onClick={() => {
								resetInput(props.art)
								handleClose()
							}}
						>
							Cancel
						</CancelButton>
						<SaveCardButton
							variant="contained"
							color="secondary"
							size="small"
							disabled={props.disabled}
							onClick={() => {
								props.update(input)
								handleClose()
							}}
						>
							Save
						</SaveCardButton>
					</>
				)}
			</h1>

			<div className="column-body">
				<div className="column">
					<FormControl>
						<Label
							disableAnimation
							shrink
							htmlFor="operation-frame"
						>
							Frame
						</Label>
						{props.loading || props.error ?
							<Skelly error={props.error} /> : 
							(props.findMode ? <FindModeInput field="frameDescription" /> :
								<TextBox
									id="operation-frame"
									name="frame_description"
									multiline
									value={ currentObject.frame_description || ''}
									readOnly={!editable}
									placeholder={'-'}
									onChange={handleChange}
								/>
							)}
					</FormControl>
					<FormControl>
						<Label
							disableAnimation
							shrink
							htmlFor="operation-Mount"
						>
							Mount
						</Label>
						{props.loading || props.error ? <Skelly error={props.error} /> : (
							props.findMode ? <FindModeInput field="mountDescription" /> : 
								<TextBox
									id="operation-Mount"
									name="mount_description"
									multiline
									value={ currentObject.mount_description || '' }
									readOnly={!editable}
									placeholder={'-'}
									onChange={handleChange}
								/>
						)}
					</FormControl>
					<FormControl>
						<Label
							disableAnimation
							shrink
							htmlFor="operation-Crate"
						>
							Crate
						</Label>
						{props.loading || props.error ? <Skelly error={props.error} /> : (
							props.findMode ? <FindModeInput field="crateDescription" /> :
								<TextBox
									id="operation-Crate"
									name="crate_description"
									multiline
									value={ currentObject.crate_description || '' }
									readOnly={!editable}
									placeholder={'-'}
									onClick={() => {
										if (state.editable || props.findMode) {
											setCrateDimensionModal(true)
										}
									}}
								/>
						)}
					</FormControl>

					<FormControl>
						<Label
							disableAnimation
							shrink
							htmlFor="operation-customs-status"
						>
							Customs Status
						</Label>
						{props.loading || props.error ? <Skelly error={props.error} /> : (
							props.findMode ? <FindModeInput field="customs_status" /> :
								<TextBox
									id="operation-customs-statuse"
									name="customs_status"
									multiline
									value={ currentObject.customs_status || '' }
									readOnly={!editable}
									placeholder={'-'}
									onChange={handleChange}
								/>
						)}
					</FormControl>
				</div>
				<div className="column">
					<FormControl>
						<Label
							disableAnimation
							shrink
							htmlFor="operation-Installation"
						>
							Installation
						</Label>
						{props.loading || props.error ? <Skelly error={props.error} /> : (
							props.findMode ? <FindModeInput field="installationDescription" /> : <TextBox
								id="operation-Installation"
								name="installation_description"
								multiline
								value={ currentObject.installation_description || '' }
								readOnly={!editable}
								placeholder={'-'}
								onChange={handleChange}
							/>
						)}
					</FormControl>
					<FormControl>
						<Label
							disableAnimation
							shrink
							htmlFor="operation-Condition"
						>
							Condition
						</Label>
						{props.loading || props.error ? <Skelly error={props.error} /> : (
							props.findMode ? <FindModeInput field="conditionDescription" /> : <TextBox
								id="operation-Condition"
								name="condition_description"
								multiline
								value={ currentObject.condition_description || '' }
								readOnly={!editable}
								placeholder={'-'}
								onChange={handleChange}
							/>
						)}
					</FormControl>

					<FormControl>
						<Label
							disableAnimation
							shrink
							htmlFor="operation-Insurance"
							id="insurance-label"
						>
							Insurance
						</Label>
						
						{(props.loading || props.error) ? <Skelly error={props.error} /> : 
							props.findMode ? <FindModeInput field="insurance" /> : 
								<Select
									IconComponent={ExpandMoreRoundedIcon}
									name="insurance"
									
									labelId="insurance-label"
									input={<Dropdown />}
									value={(sortedInsurances?.length && input.insurance_id) || 'All'}
									className={(!state.editable) ? "readonly" : null}
									readOnly={!state.editable}
									onChange={(e) => {
										setInput({
											...input,
											insurance_id:  (e.target.value === 'All') ? null : e.target.value
										})
									}}
								>
									<MenuItem key="All" value="All">-</MenuItem>
									{sortedInsurances && sortedInsurances.map(insurance => (
										<MenuItem key={insurance.id} value={insurance.id}>{insurance.value}</MenuItem>
									))}
								</Select>
						}
						
					</FormControl>

					<FormControl>
						<Label
							disableAnimation
							shrink
							htmlFor="operation-HasCoA"
							id="hascoa-label"
						>
							Certificate of Authenticity 
						</Label>

						{props.findMode ? <FindModeInput field="has_coa" /> : 
							<Select
								IconComponent={ExpandMoreRoundedIcon}
								name="has_coa"
								
								labelId="hascoa-label"
								input={<Dropdown />}
								value={input.has_coa ? 'Yes' : 'No'}
								className={(!state.editable) ? "readonly" : null}
								readOnly={!state.editable}
								onChange={(e) => {
									setInput({
										...input,
										has_coa:  (e.target.value === 'Yes') ? true : false
									})
								}}
							>
								<MenuItem key="Yes" value="Yes">Yes</MenuItem>
								<MenuItem key="No" value="No">No</MenuItem>
							</Select>
						}
					</FormControl>
				</div>
			</div>

			<TransitionsModal
				className="crate-dimension-modal"
				open={crateDimensionModal}
				close={() => {
					setCrateDimensionModal(false)
				}}
			>
				<CrateDimension
					setDimensionModal={setCrateDimensionModal}
					artInput = {input}
					setArtInput = {setInput}
					findMode={props.findMode}
				/>
			</TransitionsModal>

			<Menu
				keepMounted
				open={state.mouseY !== null}
				onClose={handleClose}
				anchorReference="anchorPosition"
				anchorPosition={
					state.mouseY !== null && state.mouseX !== null
						? { top: state.mouseY, left: state.mouseX }
						: undefined
				}
			>
				<MenuItem onClick={() => handleClose('edit')}>Edit</MenuItem>
			</Menu>
		</Paper>
	)
}

export default withApollo(OperationDetails)
