/* eslint-disable eqeqeq */
import React, { useContext, useEffect } from "react"
import {
	Paper,
	TableContainer,
	Table,
	TableRow,
	TableCell,
	TableBody,
	Tooltip,
	Skeleton,
} from "@mui/material"
import { getIconOrLabel, StyledAvatar } from "../Thumbnail/Thumbnail"
import { useNavigate } from "react-router-dom"
import EnhancedTableHead from "../table/EnhancedTableHead"
import Pagination from "@mui/material/Pagination"
import MoreHorizIcon from "@mui/icons-material/MoreHoriz"
import IconButton from "@mui/material/IconButton"
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import { formatter, formatDate, getListHeader } from "../common/helpers"

import { AuthStateContext, LookupContext } from "../store"
import { getArtInventoryNumber, getArtStatusChip } from "../common/helpers"
import { withApollo } from "@apollo/client/react/hoc"
import { getVerifiedIcon } from "./ArtPiece/ArtQuickView"
import { filteredPages } from "../navigation/routes"
import clsx from "clsx"
import FavoriteAndFlag from "../common/FavoriteAndFlag"
import QuillText from "../input/QuillText"
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff"
import Source from "./ArtPiece/Source"

/**
 *
 * @param {art} row
 * @returns {string} - the current location of the art piece
 */
export const getCurrentLocation = (row) => {
	const currentLocation = row.art_location?.find((art) => art.is_current)

	let locationString = [
		currentLocation?.location?.name,
		currentLocation?.sub_location?.name,
		formatDate(currentLocation?.location_change_at, "MMM do, yyyy"),
	]
		.filter((e) => e && e !== "-")
		.join(" / ")

	return locationString
}

/**
 * Determines the activity displayed for the current row.
 * @param {*} row  - the Art Piece supplied
 * @param {*} loading - loading sets the skeleton animation to true
 */
export const getArtActivity = (row) => {
	if (row?.disabled) {
		return <span>-</span>
	}

	if (row?.is_active)
		return (
			<div style={{ color: "var(--available)", fontWeight: "400" }}>ACTIVE</div>
		)
	return <div style={{ color: "var(--sold)", fontWeight: "400" }}>INACTIVE</div>
}

/*
 * The head of the table, each column has an id, label and two booleans:
 * numeric and disablePadding.
 *
 * Object ID must match the GraphQL query's attributes name.
 */

const headCells = [
	{
		id: "isFlagged",
		numeric: false,
		label: "isFlagged",
		isFlagged: true,
		noSort: false,
	},
	{
		id: "art_profile_link",
		numeric: false,
		disablePadding: false,
		label: "",
		noSort: true,
	},
	{
		id: "artist_first_name",
		numeric: false,
		disablePadding: false,
		label: "Artist First Name",
	},
	{
		id: "artist_last_name",
		numeric: false,
		disablePadding: false,
		label: "Artist Last Name",
	},
	{
		id: "title",
		numeric: false,
		disablePadding: false,
		label: "Title",
	},
	{
		id: "year",
		numeric: false,
		disablePadding: false,
		label: "Date",
	},

	{
		id: "searchable_inventory_number",
		numeric: false,
		disablePadding: false,
		label: "Inventory No.",
	},
	{
		id: "medium",
		numeric: false,
		label: "Medium",
		noSort: false,
	},
	{
		numeric: true,
		disablePadding: false,
		data: [
			{
				id: "size",
				label: "Dimension",
				noSort: true,
			},
			{
				id: "height_metric",
				label: "Height",
			},
			{
				id: "width_metric",
				label: "Width",
			},
			{
				id: "depth_metric",
				label: "Depth",
			},
		],
	},
	{
		id: "retail",
		numeric: false,
		disablePadding: false,
		label: "Retail Price",
		noSort: false,
	},
	{
		id: "catalogue_raisonne",
		numeric: false,
		disablePadding: false,
		label: "Catalogue Raisonné",
		noSort: false,
	},
	{
		id: "location",
		numeric: false,
		disablePadding: false,
		label: "Current Location",
		noSort: true,
	},
	{
		id: "source",
		numeric: false,
		disablePadding: false,
		label: "Source",
	},
	{
		id: "verified",
		numeric: false,
		label: "",
		noSort: true,
	},
	{
		id: "is_active",
		numeric: false,
		disablePadding: false,
		label: "Active",
	},
	{
		id: "art_status_value",
		numeric: false,
		disablePadding: false,
		label: "Status",
	},
	{
		id: "action",
		numeric: false,
		disablePadding: false,
		label: "Actions",
	},
]

/**
 * @typedef ArtListProps
 * @property {Object[]} rows
 * @property {Object} selectedRow - a reference to one of the above.
 * @property {function} onSelect - a callback when a row is selected
 * @property {number} totalItems
 * @property {function} setSelectedRow
 * @property {function} handleSubmit
 * @property {boolean} loading
 * @property {'asc' | 'desc'} order
 * @property {string} orderBy
 * @property {function} setOrder
 * @property {function} setOrderBy
 * @property {number} steps
 * @property {function} setCreateArt
 */

/**
 *
 * @param {ArtListProps} props - A List of Art.
 */
function ArtList(props) {
	// handle null response.
	const rows = props.rows || []
	const navigate = useNavigate()
	function saveAndGo(url, id) {
		props.saveAndGo ? props.saveAndGo(url, id) : navigate(url)
	}

	const authState = useContext(AuthStateContext)
	const userPermissions = authState?.user?.permissions

	const [toggle, setToggle] = React.useState(false)

	const handleRequestSort = (event, property) => {
		if (property === "exhibited_pieces") return
		props.setActiveStep(0)
		const isAsc = props.orderBy === property && props.order === "asc"
		props.setOrder(isAsc ? "desc" : "asc")
		props.setOrderBy(property)
	}

	// Menu
	const [anchorEl, setAnchorEl] = React.useState(null)
	const [currentID, setCurrentID] = React.useState(null)
	const handleClose = () => setAnchorEl(null)

	const lookup = useContext(LookupContext)
	const artStatus = lookup.data?.getArtStatuses
	const currencies = lookup.data?.getCurrencies

	// If a row is still selected (in state), bring it into view on render.
	useEffect(() => {
		if (props.selectedRow) {
			const id = props.selectedRow.id
			const el = document.querySelector(`#art-result-${id}`)
			if (el) el.scrollIntoView(false)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<Paper
			className={clsx({
				"search-list": true,
				"padding-margin-scrollbar": true,
				[props.scrollTopOverride]: !!props.scrollTopOverride,
			})}
			data-testid={`card-${props.listHeader ? props.listHeader : "Results "}`}
			style={props.style}>
			<h1 className="card-title">
				{getListHeader(props.listHeader, props.totalItems, props.canSee)}

				{props.hidden ? (
					<Tooltip
						enterDelay={500}
						title={"You do not have permission to view this list of art."}
						arrow
						placement="bottom">
						<VisibilityOffIcon
							style={{
								color: "grey",
								marginLeft: "0.4em",
								marginTop: "0.3em",
							}}
						/>
					</Tooltip>
				) : null}
			</h1>

			<TableContainer>
				<Table
					aria-labelledby="art-list"
					size="small"
					aria-label="enhanced table">
					<EnhancedTableHead
						headCells={props?.headCellsOverride ?? headCells}
						order={props.order}
						orderBy={props.orderBy}
						onRequestSort={handleRequestSort}
						rowCount={rows.length}
						mainSearchPage={props.mainSearchPage}
						makeVariables={props.makeVariables}
						currentFilters={props.currentFilters}
						objectId={props.objectId}
						userId={props?.userId}
						typeName={props.typeName}
						setState={props.setState}
						state={props.state}
						listName={props.listName}
						totalItems={props.totalItems}
						searchResponse="searchDynamicArt"
						onSelect={props.onSelect}
						filterFunction={(r) => !r.is_private || r.title}
						type="Art"
						totalItemsName={props.totalItemsName}
						handleSubmit={props.handleSubmit}
						setFilters={props.setFilters}
						filters={props.filters}
						setSelection={props.setSelection}
						currentSearch={props.currentSearch}
						rows={props.rows}
						setRows={props.setRows}
						setFlaggedCount={props.setFlaggedCount}
						setValue={props.setValue}
					/>

					<TableBody>
						{rows.length === 0 && !props.loading ? (
							<TableRow style={{ height: "6em" }}>
								<TableCell />
								<TableCell />
								<TableCell />
								<TableCell colSpan={3}> No Art Found</TableCell>
								<TableCell />
								<TableCell />
								<TableCell />
								<TableCell />
								<TableCell />
								<TableCell />
								<TableCell />
							</TableRow>
						) : null}

						{props.loading ? (
							<TableRow style={{ height: "6em" }}>
								<TableCell>
									{" "}
									<Skeleton animation="wave" variant="text" />
								</TableCell>
								<TableCell>
									{" "}
									<Skeleton animation="wave" variant="text" />
								</TableCell>
								<TableCell>
									{" "}
									<Skeleton animation="wave" variant="text" />
								</TableCell>
								<TableCell>
									{" "}
									<Skeleton animation="wave" variant="text" />
								</TableCell>
								<TableCell>
									{" "}
									<Skeleton animation="wave" variant="text" />
								</TableCell>
								<TableCell>
									{" "}
									<Skeleton animation="wave" variant="text" />
								</TableCell>
								<TableCell>
									{" "}
									<Skeleton animation="wave" variant="text" />
								</TableCell>
								<TableCell>
									{" "}
									<Skeleton animation="wave" variant="text" />
								</TableCell>
								<TableCell>
									{" "}
									<Skeleton animation="wave" variant="text" />
								</TableCell>
								<TableCell>
									{" "}
									<Skeleton animation="wave" variant="text" />
								</TableCell>
								<TableCell>
									{" "}
									<Skeleton animation="wave" variant="text" />
								</TableCell>
								<TableCell>
									{" "}
									<Skeleton animation="wave" variant="text" />
								</TableCell>
								<TableCell>
									{" "}
									<Skeleton animation="wave" variant="text" />
								</TableCell>
								<TableCell>
									{" "}
									<Skeleton animation="wave" variant="text" />
								</TableCell>
								<TableCell>
									{" "}
									<Skeleton animation="wave" variant="text" />
								</TableCell>
								<TableCell>
									{" "}
									<Skeleton animation="wave" variant="text" />
								</TableCell>
							</TableRow>
						) : null}

						{rows.map((row) => {
							if (row && row.hidden === true) return null

							// Private only if no data comes
							const hidden = row.is_private && !row.created_at
							const isPrivate = row.is_private

							const openContextMenu = (field) => (e) => {
								if (row.disabled || hidden) return
								e.preventDefault()
								e.stopPropagation()
								setCurrentID(row.id)
								setAnchorEl(e[field])
							}

							return (
								<TableRow
									key={row.id}
									style={{ cursor: "pointer" }}
									hover={!hidden && !isPrivate}
									className={hidden || isPrivate ? "private" : ""}
									onContextMenu={openContextMenu("target")}
									onClick={(event) => {
										// Check if row is a placeholder, or private
										if (row.disabled) return

										// eslint-disable-next-line no-unused-expressions
										props.setCreateArt?.(null)

										if (hidden) {
											if (props.setPrivateAccess) props.setPrivateAccess(row)
											props.onSelect(row)
											return
										}

										if (event.metaKey) {
											window.open(`/art/${row.id}`, "_blank")
											return
										}

										if (row === props.selectedRow) {
											const url = `/art/${row.id}`
											saveAndGo(url, row.id)
										}

										props.onSelect(row)
										if (props.setPrivateAccess) props.setPrivateAccess(null)
									}}
									selected={
										row === props.selectedRow ||
										row === props.privateAccess ||
										(Array.isArray(props.selectedRow) &&
											props.selectedRow.includes(row.id))
									}
									data-testid={`search-result`}
									id={`art-result-${row.id}`}>
									<TableCell component="th" scope="row" style={{ padding: 6 }}>
										<FavoriteAndFlag
											entityName="art"
											row={row}
											mainSearchPage={props.mainSearchPage}
											toggle={toggle}
											setToggle={setToggle}
											userId={props?.userId}
											rows={props.rows}
											setRows={props.setRows}
											setEntity={props.setArt}
											entity={props.art}
											setState={props.setState}
											state={props.state}
											listName={props.listName}
											setFlaggedCount={props.setFlaggedCount}
										/>
									</TableCell>
									<TableCell data-testid="art-image">
										<StyledAvatar
											alt={row.title || "Private Entity"}
											src={row.imgUrl}
											variant={"square"}>
											{getIconOrLabel({
												avatar: row.imgUrl,
												name: row.code_name,
												type: "art",
											})}
										</StyledAvatar>
									</TableCell>
									<TableCell data-testid="art-artist-fn">
										{row?.artist?.first_name || "-"}
									</TableCell>
									<TableCell data-testid="art-artist-ln">
										{row?.artist?.last_name || "-"}
									</TableCell>
									<TableCell data-testid="art-title">
										<QuillText noWrap name={row.id}>
											{typeof row.formatted_title === "object"
												? row.formatted_title
												: JSON.parse(row.formatted_title)}
										</QuillText>
									</TableCell>
									<TableCell data-testid="art-date">
										{row?.year || "-"}
									</TableCell>
									<TableCell style={{ width: "13em" }}>
										{getArtInventoryNumber(row, true)}
									</TableCell>
									<TableCell>
										{row?.medium && !isPrivate ? row.medium : <span>-</span>}
									</TableCell>
									<TableCell
										style={{
											width: "20em",
											wordWrap: "break-word",
											whiteSpace: "pre-wrap",
										}}>
										{row?.all_dimensions && !isPrivate ? (
											row.all_dimensions
										) : (
											<span>-</span>
										)}
									</TableCell>
									<TableCell style={{ whiteSpace: "nowrap" }}>
										{row.primaryRetailPrice && !isPrivate
											? formatter(
													currencies?.find(
														(e) => e.id == row.primary_currency_id,
													)?.label || "USD",
											  ).format(row.primaryRetailPrice)
											: "-"}
									</TableCell>
									<TableCell style={{ minWidth: "15em" }}>
										{row.catalogue_raisonne || "-"}
									</TableCell>

									<TableCell style={{ minWidth: "15em" }}>
										{getCurrentLocation(row) || "-"}
									</TableCell>

									<TableCell>
										{row.current_sources?.length && !isPrivate ? (
											<Source
												loading={false}
												canViewOwnerContact={row?.canViewOwnerContact}
												sources={row?.current_sources}
												thumbnailStyle={{
													margin: "8px auto 8px 8px",
												}}
												avatarGroupStyles={{
													marginLeft: "1em",
												}}
											/>
										) : (
											<div> - </div>
										)}
									</TableCell>

									<TableCell style={{ paddingLeft: "0px" }}>
										{!isPrivate ? getVerifiedIcon(row, props.loading) : null}
									</TableCell>

									<TableCell>
										{!isPrivate ? (
											getArtActivity(row, props.loading)
										) : (
											<span>-</span>
										)}
									</TableCell>
									<TableCell>
										{!isPrivate ? (
											getArtStatusChip(row, props.loading, artStatus)
										) : (
											<span>-</span>
										)}
									</TableCell>

									<TableCell>
										<IconButton
											aria-label="More"
											style={{
												padding: "6px",
												marginRight: "-9px",
											}}
											onClick={openContextMenu("currentTarget")}
											size="large">
											<MoreHorizIcon
												style={isPrivate ? { color: "white" } : null}
											/>
										</IconButton>
									</TableCell>
								</TableRow>
							)
						})}
					</TableBody>
				</Table>
			</TableContainer>

			<Menu
				id="simple-menu"
				anchorEl={anchorEl}
				keepMounted
				open={!!anchorEl}
				onClose={handleClose}>
				{filteredPages("artPages", userPermissions).map((page) => (
					<MenuItem
						key={page.name}
						onClick={() => {
							saveAndGo(`/art/${currentID}/${page.route}`, currentID)
							handleClose()
						}}>
						{page.name}
					</MenuItem>
				))}
			</Menu>

			<div
				style={{
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
				}}>
				<Pagination
					style={{
						paddingTop: "2em",
						paddingBottom: "1em",
						margin: "auto",
						background: "transparent",
					}}
					count={props.steps}
					page={props.activeStep + 1}
					onChange={(event, page) => {
						if (props.reloadData) {
							props.reloadData({ cursor: (page - 1) * (props.limit || 10) })
						} else {
							props.handleSubmit(
								{ cursor: (page - 1) * (props.limit || 10) },
								props.currentFilters,
							)
						}
						props.setActiveStep(page - 1)

						// Scroll to top of page
						var elmnt = document.querySelector(
							!!props.scrollTopOverride
								? `.${props.scrollTopOverride}`
								: ".search-list",
						)
						elmnt.scrollIntoView({
							behavior: "smooth",
							block: "start",
						})
					}}
				/>
			</div>
		</Paper>
	)
}

export default withApollo(ArtList)
