/* eslint-disable eqeqeq */
import React, { useContext, useCallback } from 'react'

import TabBar from './TabBar'
import { Route, Navigate, Routes, useNavigate, useLocation } from 'react-router-dom'
import PageNotFound from '../../PageNotFound/PageNotFound'
import { severity } from '../../Snackbar/CustomizedSnackbar'
import { DispatchContext, AuthStateContext } from '../../store'
import { useEffect } from 'react'
import { ThemeProvider, StyledEngineProvider } from '@mui/material'
import privateObjectTheme from '../../styles/muiThemes/privateObjectTheme'
import clsx from 'clsx'
import { permissionValues } from '../../constants/permissions'
import { getContactName, getDealName } from '../../common/helpers'
import useNavigation from '../useNavigation'
import { atom, selectorFamily, useRecoilCallback, useRecoilState, useSetRecoilState } from 'recoil'
import TextBox from '../../input/Text/TextBoxThin'
import LargeTextBox from '../../input/Text/TextBox'
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker'
import { queries } from '../../constants/values'
import AdminToggleSmall from '../../input/Toggle/AdminToggleSmall'
import { useApolloClient } from '@apollo/client'
import { tagBackgroundWithoutHex } from '../../styles/colors/Colors'
import Toggle from '../../input/Toggle/UserToggle'

export const findModeAtom = atom({
	key: 'findMode',
	default: false
})

export const findModeFieldsAtom = atom({
	key: 'findModeFields',
	default: {}
})

export const quickViewAtom = atom({
	key: 'quickView',
	default: null
})

export const rightQuickViewAtom = atom({
	key: 'rightQuickView',
	default: null
})

export const findModeFieldSelector = selectorFamily({
	key: 'findModeField',
	get: (field) => ({ get }) => {
		const findModeFields = get(findModeFieldsAtom)
		return findModeFields[field]
	},
	set: (field) => ({ get, set }, newValue) => {
		const findModeFields = get(findModeFieldsAtom)
		set(findModeFieldsAtom, {
			...findModeFields,
			[field]: newValue
		})
	}
})

export const FindModeInput = ({
	field,
	readOnly=false,
	placeholder='-',
	type='text',
	style,
	openTo,
	views,
	disableFuture,
	largeInput,
	perms,
	permission
}) => {

	const [value, setValue] = useRecoilState(findModeFieldSelector(field))

	if (type === 'date-picker') {

		return <MobileDatePicker
			inputFormat="MMMM do, yyyy"
			disableMaskedInput={true}
			style={style}
			componentsProps={{
				actionBar: {
					actions: ['clear', 'accept']
				}
			}}			openTo={openTo}
			views={views}
			name={field}
			disableFuture={disableFuture}
			value={value ?? null}
			inputVariant="outlined"
			color="secondary"
			className="MUIDatePicker"
			variant="dialog" 
			renderInput={({ inputRef, inputProps, InputProps }) => {
								
				const newProps = { ...inputProps}
				
				newProps.placeholder = placeholder	
				newProps.readOnly = false
				newProps.onKeyDown = () => {}

				if (largeInput) return (
					<LargeTextBox ref={inputRef} endAdornment={InputProps?.endAdornment} {...newProps} />
				)
				

				return (
					<TextBox ref={inputRef} endAdornment={InputProps?.endAdornment} {...newProps} />
				)}}
										
			onChange={(date) => {
				setValue(date ? date?.toDateString() : null)
			}}
		/>
	}

	if (type === 'admin-toggle-small') 
		return <AdminToggleSmall
			name={field}
			checked={value == '1'}
			onChange={(event) => setValue(event.target.checked ? '1' : '2')}
		/>
	if (type === 'listing-pref') {
		return <Toggle
			name={field}
			checked={!!value}
			onChange={event => setValue(event.target.checked)}
		/>
	}


	if (largeInput) return <LargeTextBox
		name={field}
		value={value || ''}
		onChange={(e) => setValue(e.target.value)}
		placeholder={placeholder}
		readOnly={readOnly}
		style={style}
		type={type}
	/>

	return <TextBox
		name={field}
		value={value || ''}
		onChange={(e) => setValue(e.target.value)}
		placeholder={placeholder}
		readOnly={readOnly}
		style={style}
		type={type}
	/>

}

function TabbedPage(props) {

	const setFindModeFields = useSetRecoilState(findModeFieldsAtom)

	const [findMode, setFindMode] = useRecoilState(findModeAtom)

	const [QVOverride, setQVOverride] = useRecoilState(quickViewAtom)

	const navigate = useNavigate()
	const location = useLocation()
	const client = useApolloClient()

	const params = props.params || {}
	const prevSearch = location?.state

	// Clear quickview on navigation in tabbed page
	useEffect(() => {
		setQVOverride(null)
	}, [location.pathname, setQVOverride])

	// Clear quickview on navigation out of tabbed page
	useEffect(() => {
		return () => {
			setQVOverride(null)
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const { routes, Quickview, query, options } = props
	const {
		prevSearch: previousSearch,
		searchNav,
		setSearchNav,
		setSearchNavLoading
	} = useNavigation()

	const atAdminConsole = location.pathname.includes('admin')
	const [atPrivateObject, setAtPrivateObject] = React.useState(null)
	const [atFavoriteObject, setAtFavoriteObject] = React.useState(false)
	const [atFlaggedObject, setAtFlaggedObject] = React.useState(false)
	const [tabbedPageFileRefetch, setTabbedPageFileRefetch] = React.useState(false)

	const authState = useContext(AuthStateContext)
	const permissions = authState?.user?.permissions


	const searchLocation = {
		'art': 'art',
		'contact': 'contacts',
		'deal': 'deals',
		'listing': 'listings',
		'artist': 'artists',
		'user': 'admin/users'
	}[props?.options?.object]

	const reset = () => {
		setAtPrivateObject(null)
		setAtFavoriteObject(false)
		setAtFlaggedObject(false)
	}

	//Snackbar
	const dispatch = useContext(DispatchContext)
	const openSnackbar = useCallback(
		(severity, text) => {
			dispatch({ type: 'openSnackBar', payload: { severity, text } })
		},
		[dispatch]
	)

	// Tag Card State
	const [tagState, setTagState] = React.useState({
		mouseX: null,
		mouseY: null,
		editable: false,
	})
	const [newTagModal, setNewTagModal] = React.useState({open: false, label: '', description: null, color_hex: tagBackgroundWithoutHex })

	const catchAuthError = (errors) => {

		for (let i = 0; i < errors?.length; i+=1) {
			if ((errors[i].path[0] === "getContact" ||
				errors[i].path[0] === "getListing" ||
				errors[i].path[0] === "getArtist" ||
				errors[i].path[0] === "getArtPiece" ||
				errors[i].path[0] === "getDeal" ||
				errors[i].path[0] === "adminGetUser")
				&& errors[i].path[1] === "id") {
				openSnackbar(severity.ERROR, "Error - Unauthorized")
				return
			}
		}
	}

	const [state, setState] = React.useState({})
	const [loading, setLoading] = React.useState(false)
	const [error, setError] = React.useState(false)

	useEffect(() => {
		let active = true

		if (params.id && Number(params.id) > 0) {
			setLoading(true)

			const entityQueryName = query.definitions?.[0]?.selectionSet?.selections?.[0]?.name?.value
			const entityQueryCount = query.definitions?.[0]?.selectionSet?.selections?.[1]?.name?.value
			const entityQueryNotes = query.definitions?.[0]?.selectionSet?.selections?.[2]?.name?.value

			// Reset current entities state
			setState({
				...state,
				[entityQueryName]: null,
				[entityQueryCount]: null,
				[entityQueryNotes]: null
			})

			client
				.query({
					query,
					variables: { id: params.id, thumbnailResolution: "1024x1024" },
				})
				.then((result) => {
					if (!active) return

					// edge case for art; should probably extend to all entities
					// eventually
					let resultState = result.data
					if (resultState.getArtPiece) {
						if (!resultState.getArtPiece.success) {
							throw new Error(resultState.getArtPiece.message)
						} else {
							resultState.getArtPiece = resultState.getArtPiece.art
						}
					}
					setState(resultState)
					setLoading(false)

					props.setTotalResultCount(result.data.getArtCount ||
						result.data.getArtistCount ||
						result.data.getContactCount ||
						result.data.getDealCount ||
						result.data.getListingCount || 
						result.data.getUserCount || 
						result.data.getTagCount)

					let entity = result.data[entityQueryName]

					if (!entity && result.errors?.length) {

						if (result.errors.length === 1) {
							throw new Error(result.errors[0].message)

						} else {
							console.error(result.errors)
							throw new Error('There was an error retrieving this entity.')
						}
					}

					if (!atAdminConsole) {
						catchAuthError(result.errors)

						if (entity) {
							setAtPrivateObject(entity.is_private)
							setAtFavoriteObject(entity.isFavorite)
							setAtFlaggedObject(entity.isFlagged)
						} else reset()
					} else reset()
				})
				.catch((error) => {

					setError(true)
					if (
						error.message.includes('GraphQL error: Not Authorised!')
					) {
						openSnackbar(severity.ERROR, 'Not Authorised!')
					} else {
						console.error(error)
						openSnackbar(severity.ERROR, error.message)
					}
				})
		} else if (params.id === 'findmode' || params.id === '0') {
			setFindMode(true)
		}
		// HOO-boi. Ok - load the "stored" query for nav purposes, if it's
		// in the nav stack; otherwise fall back to the passed in query.
		let {
			query: prevQuery,
			state: previousState,
			getId = r => r?.id
		} = (previousSearch || {})

		if ((prevQuery && previousState?.variables) ||
				(prevSearch && prevSearch.variables)) {

			// if a simple list was passed in, just nav through it:
			if (Array.isArray(prevQuery)) {
				const total = prevQuery.length
				const cursor = searchNav.current

				const path = (location.state?.pathTemplate || location.pathname)

				const prevId = cursor >= 0 ? prevQuery[cursor - 1 ] : null
				const current = Math.max(0, cursor)
				const nextId = (cursor < (total - 1)) ? prevQuery[cursor + 1] : null
				const searchNavData = {
					current, total,
					searchPage: prevSearch?.searchPage,
					pathTemplate: path
				}
				if (prevId) {
					searchNavData.prev = prevId
				}
				if (nextId) {
					searchNavData.next = nextId
				}
				setSearchNav(searchNavData)
				return
			}

			// OTHERWISE, do an actual query.
			let query
			if (prevQuery) query = prevQuery
			else query = prevSearch.global ?
				props.searchGlobalQuery : props.searchQuery

			if (!query) {
				console.error('No query provided.')
				return
			}
			let cursor = searchNav.current

			// In order to not throw a backend error if a browser refresh
			// has flubbed the flow of nav, we won't use the "prevSearch" variables
			// if it's all we have.

			setSearchNavLoading(true)

			client
				.query({
					query,
					variables: {
						...(previousState?.variables),
						cursor: Math.max(cursor - 1, 0)
					}
				})
				.then(({data}) => {
					if (data && active) {
						const result = queries.map(q => data[q])
							.reduce((acc, el) => acc || el)

						const cursor = searchNav.current // prevSearch.variables.cursor
						const total = result?.totalItems
						const path = (location.state?.pathTemplate || location.pathname)

						// prev
						const prevId = cursor > 0 ?
							getId(result?.items?.[0]) :
							null
						const current = Math.max(0, cursor) //prevSearch.variables.cursor)
						const nextIndex = cursor > 0 ? 2 : 1
						const nextId = (cursor < (total - 1)) ?
							getId(result?.items?.[nextIndex]) :
							null
						const searchNavData = {
							current, 
							total,
							searchPage: prevSearch?.searchPage,
							pathTemplate: path
						}
						if (prevId) {
							searchNavData.prev = prevId
						}
						if (nextId) {
							searchNavData.next = nextId
						}
						setSearchNav(searchNavData)

						setSearchNavLoading(false)
					}
				})
		}
		return () => active = false

	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [params.id, query])

	useEffect(() => {
		let entity = state.getContact ||
			state.getListing ||
			state.getArtist ||
			state.getArtPiece ||
			state.getDeal ||
			state.getTag
		let fullName = entity?.first_name && entity?.last_name &&
			`${entity?.first_name} ${entity?.last_name}`
		props.onEntity(getContactName(entity) ||
			entity?.title || fullName || entity?.label || getDealName(entity) )
	}, [state, props])

	// turn off findMode when the tabbedPage is unmounted
	// (empty array to only happen once.)
	useEffect(() => {
		return () => setFindMode(false)
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	// Routes filtered by their required user permission key, value pair
	let filteredRoutes = routes.filter((route) => {

		// Checks for permisionedBoolean returning true on tabbed page queries
		if (route.permissionedBooleans) {
			return route.permissionedBooleans.every((permissionBoolean) => {

				const response = state?.[query.definitions?.[0]?.name?.value]?.[permissionBoolean]

				if (response === true) return true // We have permission
				else if (response === undefined) return true // Loading
				return false // We don't have permission
			
			})
		}

		if (route.permissions) {

			if (typeof route.permissions == 'function') {
				return route.permissions(state)
			} else if (Array.isArray(route.permissions)) {

				if (route.everyPermRequired) {
					return route.permissions.every((perm) => {

						const permission = permissions &&
							permissions.find(element => element.permission_id == perm)
			
						if (permission?.permission_value_id == permissionValues.NO) return false
						if (permission?.permission_value_id == permissionValues.CANNOT_SEE) return false
						return true
			
					})
				}
				
				return route.permissions.some((perm) => {

					const permission = permissions &&
						permissions.find(element => element.permission_id == perm)
		
					if (permission?.permission_value_id == permissionValues.NO) return false
					if (permission?.permission_value_id == permissionValues.CANNOT_SEE) return false
					return true
		
				})
			}

			else {

				const id = route.permissions
				const permission = permissions &&
					permissions.find(element => element.permission_id == id)
	
				if (permission?.permission_value_id == permissionValues.NO) return false
				if (permission?.permission_value_id == permissionValues.CANNOT_SEE) return false
	
				return true
			}

	
		}

		return true
	})

	// Filter the tabs by visibility
	let tabs = filteredRoutes.filter(({ visible }) => visible)

	// handle FindMode search
	const handleKeyDown = useRecoilCallback(({ snapshot }) => async (e) => {
		
		if (!findMode || e.key !== 'Enter') return
		e.stopPropagation()
		let fields = await snapshot.getPromise(findModeFieldsAtom)

		fields = Object.fromEntries(Object.entries(fields)
			.filter(([_, v]) => v != null && v !== '')
			.filter(([k, v]) => k !== 'compound_notes'||
				v.type || v.text
			)
			// Nested ES Queries
			.map(field => {
				
				if (field[0].includes('.') && field[0].includes('source')) {
					return field
				}
				
				// Nested queries include periods to separate data 	
				if (field[0].includes('.')) {

					const [key, value] = field

					const data =  key.split('.')[0]
					const newKey = key.split('.')[1]	

					const newValue = {
						text: value,
						[data.split(':')[0]]: data.split(':')[1]
					}

					return [newKey, newValue]

				}

				else return field
			}))

		navigate(`/${searchLocation}`, { state: { fields }})
	})

	return <>
		<TabBar
			value={location.pathname}
			tabs={tabs}
			hideTabBar={props.hideTabBar}
			setQVOverride={setQVOverride}
			options={options}
			permissions={permissions}
			objectId={params.id}
			userId={authState?.user?.id}

			atFavoriteObject={atFavoriteObject}
			atFlaggedObject={atFlaggedObject}
			atPrivateObject={atPrivateObject}
			setAtFavoriteObject={setAtFavoriteObject}
			setAtFlaggedObject={setAtFlaggedObject}

			tagState={tagState}
			setTagState={setTagState}
			newTagModal={newTagModal}
			setNewTagModal={setNewTagModal}
			tabbedPageFileRefetch={tabbedPageFileRefetch}
			setTabbedPageFileRefetch={setTabbedPageFileRefetch}
			atAdminConsole={atAdminConsole}

			entity={state.getContact || state.getArtPiece || state.getDeal || state.getListing || state.getArtist}
			state={state}
			setState={(newState) =>
				setState({
					...state,
					...newState,
				})
			}
			findMode={findMode}
			toggleFindMode={() => {
				const newFindModeVal = !findMode
				setFindMode(newFindModeVal)
				if (newFindModeVal) {
					setFindModeFields({})
				} else if (params.id === 'findmode' || !Object.keys(state).length) {
					navigate(`/${searchLocation}`)
				}
			}}

			showcaseInformation={props.showcaseInformation}
			setShowcaseInformation={props.setShowcaseInformation}
			
			showcaseSelectMode={props.showcaseSelectMode}
			setShowcaseSelectMode={props.setShowcaseSelectMode}
		>
		</TabBar>

		<div
			className={
				atAdminConsole ? 'main-page dark-theme' : 'main-page'
			}
		>
			{QVOverride
				? QVOverride
				: Quickview && (
					<Quickview
						state={state}
						loading={loading}
						atPrivateObject={atPrivateObject}
						atAdminConsole={atAdminConsole}
						setState={(newState) =>
							setState(s => ({
								...s,
								...newState,
							}))
						}
						findMode={findMode}
						setFindMode={setFindMode}
					/>
				)}
			<Routes>
				{filteredRoutes.map((page) => {
					const CurrentComponent = page.component
					const content = (
						<div onKeyDown={handleKeyDown}
							className={clsx({
								'tabbed-content': true,
								'private-object': atPrivateObject,
							})}
						>
							<CurrentComponent
								id={params.id}
								loading={loading}
								error={error}
								state={state}
								setState={(newState) =>
									setState(s => ({
										...s,
										...newState,
									}))
								}
								QVOverride={QVOverride}
								setQVOverride={setQVOverride}
								atPrivateObject={atPrivateObject}
								setAtPrivateObject={setAtPrivateObject}

								tagState={tagState}
								setTagState={setTagState}
								newTagModal={newTagModal}
								setNewTagModal={setNewTagModal}
								fileObjectId={props.options?.fileObjectId}
								options={props.options}

								tabbedPageFileRefetch={tabbedPageFileRefetch}
								setTabbedPageFileRefetch={setTabbedPageFileRefetch}

								pageName={page.name}
								findMode={findMode}

								showcaseInformation={props.showcaseInformation}
								setShowcaseInformation={props.setShowcaseInformation}

								showcaseSelectMode={props.showcaseSelectMode}
								setShowcaseSelectMode={props.setShowcaseSelectMode}
							/>
						</div>
					)

					return atPrivateObject ? (
						<Route
							key={page.route}
							path={page.route}
							element={<StyledEngineProvider injectFirst>
								<ThemeProvider theme={privateObjectTheme}>
									{content}
								</ThemeProvider>
							</StyledEngineProvider>}
						/>
					) : (
						<Route
							key={page.route}
							path={page.route}
							element={<>{content}</>}
						/>
					)
				})}
				<Route path=""
					element={<Navigate
						replace
						to={routes[0].route}
						state={location.state}
					/>}
				/>

				{ /* Other hard-coded redirects */ }
				<Route path="consignments-and-sales"
					element={<Navigate
						replace
						to={'financials-and-ownership'}
						state={location.state}
					/>}
				/>

				<Route path="art-collection"
					element={<Navigate
						replace
						to={'art-collection-and-known-works'}
						state={location.state}
					/>}
				/>

				<Route path="*" element={<PageNotFound />} />
					
			</Routes>
		</div>
	</>
}

export default TabbedPage
