/* eslint-disable eqeqeq */
import React, { useContext, useCallback, useEffect } from "react"
import { LookupContext, DispatchContext } from "../../store"
import {
	GET_ART_LISTINGS,
	DELETE_LISTING_ART,
	UPDATE_LISTING_ART,
} from "./Queries"
import { useMutation } from "@apollo/client"
import {
	Box,
	Paper,
	TableContainer,
	Table,
	TableRow,
	TableCell,
	TableBody,
} from "@mui/material"
import EnhancedTableHead from "../../table/EnhancedTableHead"
import Thumbnail from "../../Thumbnail/Thumbnail"
import { Skeleton } from "@mui/material"
import IconButton from "@mui/material/IconButton"
import { severity } from "../../Snackbar/CustomizedSnackbar"
import { MenuItem, Select } from "@mui/material"
import Dropdown from "../../input/Dropdown/Dropdown"
import { format } from "date-fns"
import ListingQuickView from "../../Listings/Listing/ListingQuickView"
import { ChooseImageButton } from "../../input/Buttons"
import Pagination from "@mui/material/Pagination"
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded"
import { getArtistThumbnailDetail, getListingStatusColor } from "../helpers"
import ListingRemoveModal from "../../common/components/ListingRemoveModal"
import { withApollo } from "@apollo/client/react/hoc"
import useNavigation from "../../navigation/useNavigation"
import ListingAddModal from "./ListingAddModal"
import { GET_ART_LISTINGS_NAV } from "../../navigation/Queries"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import ReadMoreOutlinedIcon from "@mui/icons-material/ReadMoreOutlined"
import PlaylistRemoveIcon from "@mui/icons-material/PlaylistRemove"

const pageSize = 10
const Listings = (props) => {
	const { push: pushNav } = useNavigation()
	const navigate = useNavigate()
	const location = useLocation()
	const params = useParams()
	const prevSearch = location.state

	const headCellsStart = [
		{
			id: "listing.title",
			numeric: false,
			disablePadding: true,
			label: "Title",
		},
		{
			id: "listing:listing_type.value",
			numeric: false,
			disablePadding: true,
			label: "Type",
		},
		{
			id: "location",
			numeric: false,
			disablePadding: false,
			label: "Gallery",
		},
		{
			id: "start_at",
			numeric: false,
			disablePadding: false,
			label: "Start Date",
		},
	]

	const headCellsEnd = [
		{
			id: "status.value",
			numeric: false,
			disablePadding: false,
			label: "Listing Status",
		},
		{
			id: "actions",
			numeric: false,
			disablePadding: false,
			label: "Actions",
		},
	]

	const headCells = props.showArt
		? [
				...headCellsStart,
				{
					id: "art.title",
					numeric: false,
					disablePadding: false,
					label: "Art",
				},
				...headCellsEnd,
		  ]
		: [...headCellsStart, ...headCellsEnd]

	const lookup = useContext(LookupContext)
	const listingArtStatus = lookup.data?.getListingArtStatus

	// Actions
	const [currentID, setCurrentID] = React.useState(null)
	const [selectedRow, setSelectedRow] = React.useState(null)

	// Pagination
	const [steps, setSteps] = React.useState(prevSearch?.steps ?? 1)
	const [activeStep, setActiveStep] = React.useState(
		prevSearch?.activeStep ?? 0,
	)
	const [cursor, setCursor] = React.useState(prevSearch?.cursor ?? 0)
	const [totalItems, setTotalItems] = React.useState(
		prevSearch?.totalItems ?? 0,
	)

	// Sorting
	const isSearchPage = location.pathname === prevSearch?.searchPage
	const [order, setOrder] = React.useState(
		(isSearchPage && prevSearch?.order) || "desc",
	)
	const [orderBy, setOrderBy] = React.useState(
		(isSearchPage && prevSearch?.orderBy) || "listing.start_at",
	)

	const [listingsLoading, setListingsLoading] = React.useState(false)

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === "asc"
		setOrder(isAsc ? "desc" : "asc")
		setOrderBy(property)
	}

	// Data
	const [rows, setRows] = React.useState(prevSearch?.rows ?? [])

	const [removeListingModal, setRemoveListingModal] = React.useState(false)

	//Snackbar
	const dispatch = useContext(DispatchContext)
	const openSnackbar = useCallback(
		(severity, text) => {
			dispatch({ type: "openSnackBar", payload: { severity, text } })
		},
		[dispatch],
	)

	// Delete
	const [deleteRelation, { loading }] = useMutation(DELETE_LISTING_ART)

	// Update
	const [updateRelation] = useMutation(UPDATE_LISTING_ART)

	const [addListingModal, setAddListingModal] = React.useState(false)

	function saveAndGo(path, row) {
		const state = updateHistory(row)
		pushNav(
			{
				url: location.pathname,
				state,
				query: GET_ART_LISTINGS_NAV,
				getId: (r) => r?.listing?.id,
			},
			state.variables.cursor,
		)
		navigate(path, { state })
	}
	// Save Search
	function updateHistory(index) {
		const savedSearch = {
			cursor,
			rows,
			selectedRow,
			orderBy,
			order,
			steps,
			activeStep,
			totalItems,
			searchPage: location.pathname,
		}
		const offset = index ?? rows.indexOf(selectedRow)
		const nextcursor = activeStep * pageSize + offset
		const variables = {
			art_id: props.art_id,
			artist_id: props.artist_id,
			cursor: nextcursor,
			limit: 3,
			field: orderBy,
			direction: order.toUpperCase(),
		}
		savedSearch.variables = variables
		navigate(location.pathname, { state: savedSearch })
		return savedSearch
	}

	const handleResponse = (data) => {
		setListingsLoading(false)
		setRows(data?.getArtListings.items || [])

		if (data?.getArtListings.items?.length < 1) setSteps(1)
		else setSteps(Math.ceil(data?.getArtListings.totalItems / pageSize))

		setTotalItems(data?.getArtListings.totalItems || 0)
	}

	const handleError = (error) => {
		setListingsLoading(false)
		if (error.message.includes("GraphQL error: Not Authorised!")) {
			openSnackbar(severity.ERROR, "Error - Unauthorized")
			return
		} else {
			console.error(error)
			openSnackbar(
				severity.ERROR,
				"There was an error retrieving the listings.",
			)
		}
	}

	const searchArtListings = (cursorOverride = null) => {
		return props.client.query({
			query: GET_ART_LISTINGS,
			variables: {
				art_id: !location.pathname.includes("artist") ? params.id : null,
				artist_id: location.pathname.includes("artist") ? params.id : null,
				cursor: cursorOverride ?? cursor,
				field: orderBy,
				direction: order.toUpperCase(),
				limit: 10,
			},
		})
	}

	useEffect(() => {
		let active = true

		if (!params?.id) return undefined

		setListingsLoading(true)
		setRows([])
		setTotalItems(0)
		searchArtListings()
			.then((data) => {
				if (active) {
					handleResponse(data.data)
				}
			})
			.catch((error) => {
				handleError(error)
			})

		return () => {
			active = false
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [params?.id, orderBy, order])

	// Deselect Row on change of Main Entity
	useEffect(() => {
		setSelectedRow(null)
	}, [props.artist_id])

	const handleDeleteListing = () => {
		deleteRelation({
			variables: {
				id: currentID,
			},
		})
			.then((response) => {
				if (response && response.data?.deleteListingArt?.success !== true) {
					openSnackbar(
						severity.ERROR,
						response?.data?.deleteListingArt?.message ||
							"There was an error deleting this relation.",
					)
				} else {
					openSnackbar(severity.SUCCESS, "Successfully deleted relation.")
					searchArtListings()
						.then((response) => handleResponse(response.data))
						.catch((error) => handleError(error))
				}
			})
			.catch((error) => {
				console.log(error)
				openSnackbar(
					severity.ERROR,
					"There was an error deleting this relation.",
				)
			})
	}

	return (
		<Paper className="padding-margin search-list" data-testid="card-listings">
			<h1 className="card-title" style={{ justifyContent: "space-between" }}>
				<span>Listings {"(" + totalItems + ")"}</span>

				{!props.hideAddButton ? (
					<ChooseImageButton
						variant="contained"
						color="secondary"
						size="small"
						onClick={() => {
							setAddListingModal(true)
							setSelectedRow(null)
							props.setQVOverride(null)
						}}>
						Add Listing
					</ChooseImageButton>
				) : null}
			</h1>
			<TableContainer>
				<Table
					aria-labelledby="tableTitle"
					size="medium"
					aria-label="enhanced table">
					<EnhancedTableHead
						headCells={headCells}
						order={order}
						orderBy={orderBy}
						onRequestSort={handleRequestSort}
						rowCount={rows.length}
					/>

					<TableBody>
						{!listingsLoading && rows?.length === 0 ? (
							<TableRow>
								<TableCell></TableCell>
								<TableCell>
									<div
										style={{
											height: 62,
											display: "flex",
											flexDirection: "row",
											alignItems: "center",
										}}>
										No Listings Found
									</div>
								</TableCell>
								<TableCell></TableCell>
								<TableCell></TableCell>
								{props.showArt ? <TableCell></TableCell> : null}
								<TableCell></TableCell>
								<TableCell></TableCell>
							</TableRow>
						) : null}

						{listingsLoading ? (
							<TableRow>
								<TableCell>
									<Thumbnail type="listing" animation="wave" />
								</TableCell>
								<TableCell>
									<Skeleton animation="wave" />
								</TableCell>
								<TableCell>
									<Skeleton animation="wave" />
								</TableCell>
								<TableCell>
									<Skeleton animation="wave" />
								</TableCell>
								{props.showArt ? (
									<TableCell>
										<Thumbnail type="art" animation="wave" />
									</TableCell>
								) : null}
								<TableCell>
									<Skeleton animation="wave" />
								</TableCell>
								<TableCell></TableCell>
							</TableRow>
						) : null}

						{rows?.map((row, index) => {
							if (row && row.hidden === true) return null

							const isPrivate = row.art?.is_private
							const hidden = row.art?.is_private && !row.art?.created_at

							return (
								<TableRow
									key={row.id}
									style={{ cursor: "pointer" }}
									hover={true}
									selected={row === selectedRow}
									onClick={(event) => {
										// Check if row is a placeholder, or private
										if (row.disabled) return

										if (event.metaKey) {
											window.open(`/listings/${row.listing.id}`, "_blank")
											return
										}

										if (row === selectedRow) {
											saveAndGo(`/listings/${row.listing.id}`, index)
										}

										setSelectedRow(row)
										props.setQVOverride(() => (
											<ListingQuickView
												id={row.listing.id}
												onClose={() => {
													setSelectedRow(null)
													props.setQVOverride(null)
												}}
											/>
										))
									}}>
									<TableCell
										component="th"
										scope="row"
										data-testid="listing-thumbnail"
										style={{ width: "20em" }}>
										<Thumbnail
											name={row.listing?.title}
											detail={row.listing?.subtitle || "-"}
											avatar={row.listing?.imgUrl}
											type="listing"
											largeText></Thumbnail>
									</TableCell>
									<TableCell>
										{row?.listing?.listing_type?.value ? (
											row.listing.listing_type.value
										) : (
											<span>-</span>
										)}
									</TableCell>
									<TableCell>
										{row?.listing?.location ? (
											row.listing.location
										) : (
											<span>-</span>
										)}
									</TableCell>
									<TableCell>
										{row?.listing?.start_at ? (
											format(new Date(row.listing.start_at), "MMMM do, yyyy")
										) : (
											<span>-</span>
										)}
									</TableCell>
									{props.showArt ? (
										<TableCell>
											<div
												className={isPrivate ? "shadow-group" : null}
												style={
													isPrivate
														? { backgroundColor: "#2E3134", margin: 0 }
														: { margin: 0 }
												}>
												<Thumbnail
													formatted_name={row.art?.formatted_title}
													name={row.art?.code_name}
													artist={getArtistThumbnailDetail(row.art)}
													date={row.art?.year}
													avatar={row.art?.imgUrl}
													type="art"
													style={{ paddingLeft: "1em" }}
													animation={props.loading ? "wave" : false}
													largeText
													darkText={!isPrivate}
													hasAccess={!hidden}></Thumbnail>
											</div>
										</TableCell>
									) : null}
									<TableCell>
										<Select
											IconComponent={ExpandMoreRoundedIcon}
											id="art-listings-status"
											input={<Dropdown />}
											className="hideborder"
											value={row.status_id}
											style={{
												color: getListingStatusColor(
													row.status_id,
													listingArtStatus,
												),
											}}
											onClick={(e) => {
												e.preventDefault()
												e.stopPropagation()
											}}
											onChange={(event, value) => {
												const variables = {
													id: row.id,
													status_id: event.target.value,
												}

												// Send to server
												updateRelation({
													variables,
												})
													.then((response) => {
														if (
															response &&
															!response.data.updateListingArt.id
														) {
															openSnackbar(
																severity.ERROR,
																"There was an error updating this status.",
															)
														} else {
															openSnackbar(
																severity.SUCCESS,
																"Successfully updated status.",
															)
															setRows(
																rows.map((row) => {
																	if (
																		row.id == response.data.updateListingArt.id
																	)
																		return response.data.updateListingArt
																	else return row
																}),
															)
														}
													})
													.catch((error) => {
														console.log(error)
														openSnackbar(
															severity.ERROR,
															"There was an error updating this status.",
														)
													})
											}}>
											{listingArtStatus &&
												listingArtStatus.map((type) => (
													<MenuItem
														style={{
															color: getListingStatusColor(
																type.id,
																listingArtStatus,
															),
														}}
														key={type.id}
														value={type.id}>
														{type.value}
													</MenuItem>
												))}
										</Select>
									</TableCell>
									<TableCell>
										<Box
											sx={{
												display: "flex",
												justifyContent: "flex-end",
												flexDirection: "row",
											}}>
											<IconButton
												style={{ padding: "6px" }}
												aria-label="Go to Listing"
												title="Go to Listing"
												onClick={() => {
													const row = rows.find((item) => item.id == row.id)
													saveAndGo(`/listings/${row?.listing?.id}/details`)
												}}>
												<ReadMoreOutlinedIcon />
											</IconButton>
											<IconButton
												style={{ padding: "6px" }}
												onClick={() => {
													setCurrentID(row.id)
													setRemoveListingModal(true)
												}}>
												<PlaylistRemoveIcon />
											</IconButton>
										</Box>
									</TableCell>
								</TableRow>
							)
						})}
					</TableBody>
				</Table>
			</TableContainer>
			<ListingRemoveModal
				disabled={loading}
				removeListingModal={removeListingModal}
				setRemoveListingModal={setRemoveListingModal}
				itemToRemove={
					props.art?.title ||
					rows.find((row) => row.id == currentID)?.art.title ||
					""
				}
				handleDeleteListing={handleDeleteListing}
				isPrivate={props.art?.is_private}
			/>

			<ListingAddModal
				addListingModal={addListingModal}
				setAddListingModal={setAddListingModal}
				isPrivate={props.art?.is_private}
				type="art"
				requery={() =>
					searchArtListings()
						.then((response) => handleResponse(response.data))
						.catch((error) => handleError(error))
				}
				id={props.art?.id}
			/>

			<div
				style={{
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
				}}>
				<Pagination
					style={{
						paddingTop: "2em",
						paddingBottom: "1em",
						margin: "auto",
						background: "transparent",
					}}
					count={steps}
					page={activeStep + 1}
					onChange={(event, page) => {
						setActiveStep(page - 1)
						setCursor((page - 1) * pageSize)

						searchArtListings((page - 1) * pageSize)
							.then((response) => handleResponse(response.data))
							.catch((error) => handleError(error))

						// Scroll to top of page
						var elmnt = document.querySelector(".search-list")
						elmnt.scrollIntoView({ behavior: "smooth", block: "start" })
					}}
				/>
			</div>
		</Paper>
	)
}

export default withApollo(Listings)
