import EnhancedTableHead from "../table/EnhancedTableHead"
import { AvatarGroup, Box } from "@mui/material"
import {
	TableContainer,
	Table,
	TableRow,
	TableCell,
	TableBody,
} from "@mui/material"
import {} from "react-router-dom"
import IconButton from "@mui/material/IconButton"
import React, { useCallback, useContext } from "react"
import { formatDate, getArtistThumbnailDetail } from "../common/helpers"
import Thumbnail from "../Thumbnail/Thumbnail"
import orderBy from "lodash/orderBy"
import ReadMoreOutlinedIcon from "@mui/icons-material/ReadMoreOutlined"

import { DispatchContext } from "../store"
import { severity } from "../Snackbar/CustomizedSnackbar"
import UserAvatar from "../common/components/UserAvatar"

function GlobalTable(props) {
	// Cells for table
	const headCells = [
		{
			id: "artpiece",
			numeric: false,
			disablePadding: false,
			label: "Artwork",
			noSort: true,
		},
		{
			id: "salesperson",
			numeric: false,
			disablePadding: false,
			label: "Gallery Contacts",
			noSort: true,
		},
		{
			id: "expiration",
			numeric: false,
			disablePadding: false,
			label: "Expiration",
			noSort: true,
		},
		{
			id: "action",
			numeric: false,
			disablePadding: false,
			label: "Actions",
			noSort: true,
		},
	]

	const dispatch = useContext(DispatchContext)

	//Snackbar
	const openSnackbar = useCallback(
		(severity, text) => {
			dispatch({ type: "openSnackBar", payload: { severity, text } })
		},
		[dispatch],
	)

	return (
		<>
			<div>
				<TableContainer style={{ overflowX: "hidden", paddingBottom: "2em" }}>
					<Table
						aria-labelledby="tableTitle"
						size="medium"
						aria-label="enhanced table">
						<EnhancedTableHead
							headCells={headCells}
							rowCount={props.currentArts?.length}
						/>
						<TableBody>
							{!props.loading && props.currentArts?.length === 0 ? (
								<TableRow>
									<TableCell></TableCell>
									<TableCell style={{ padding: "1em" }}>
										No {props.name}s found.
									</TableCell>
									<TableCell></TableCell>
									<TableCell></TableCell>
								</TableRow>
							) : null}

							{props.currentArts?.map((row, index) => {
								const hidden = row.art?.is_private && !row.art?.created_at

								return (
									<TableRow key={row.id} selected={row === props.selectedRow}>
										<TableCell style={{ width: "15em" }}>
											<Thumbnail
												formatted_name={row.art?.formatted_title}
												name={row.art?.code_name}
												artist={getArtistThumbnailDetail(row.art)}
												date={row.art?.year}
												style={{ paddingLeft: "1em" }}
												avatar={row.art?.imgUrl}
												type="art"
												animation={props.loading ? "wave" : false}
												largeText
												hasAccess={!hidden}></Thumbnail>
										</TableCell>

										<TableCell
											className="home-page-tasklist"
											component="th"
											scope="row"
											data-testid="contact-thumbnail"
											style={{ width: "10em" }}>
											{!row.deal?.salesperson ||
											row.deal?.salesperson?.length === 0 ? (
												<span
													style={{
														marginLeft: "1em",
													}}>
													{" "}
													-{" "}
												</span>
											) : null}
											<AvatarGroup key={"AvatarGroup-" + index}>
												{orderBy(
													row.deal?.salesperson,
													["is_lead"],
													["desc"],
												).map((sp, index) => (
													<UserAvatar
														key={sp.id}
														sp={sp}
														index={index}
														length={row?.deal?.salesperson?.length}
													/>
												))}
											</AvatarGroup>
										</TableCell>

										<TableCell
											style={{
												width: "15em",
												color:
													props.name === "consignment" &&
													row.art?.is_active &&
													new Date() > row[props.expirationField]
														? "red"
														: null,
											}}>
											{row[props.expirationField] ? (
												formatDate(
													row[props.expirationField],
													"MMMM do, yyyy 'at' h':'mma",
												)
											) : (
												<span>-</span>
											)}
										</TableCell>
										<TableCell>
											{!row?.disabled ? (
												<Box sx={{ display: "flex", gap: 1 }}>
													<IconButton
														aria-label={
															row?.is_private
																? "Go to Private Deal"
																: "Go to Deal"
														}
														onClick={(e) => {
															const noNav =
																(row?.deal?.is_private ||
																	row?.deal?.is_gallery_private) &&
																!row?.deal?.created_at

															if (noNav) {
																openSnackbar(
																	severity.WARNING,
																	"You do not have access to this Private Deal.",
																)
															} else {
																props.saveAndGo(
																	`/deals/${row.deal_id}/details`,
																	row.id,
																)
															}
														}}>
														<ReadMoreOutlinedIcon />
													</IconButton>
													<IconButton
														aria-label={
															row?.art?.is_private
																? "Go to Private Art"
																: "Go to Art"
														}
														onClick={(e) => {
															const noNavArt =
																row?.art?.is_private && !row?.art?.created_at
															if (noNavArt) {
																openSnackbar(
																	severity.WARNING,
																	"You do not have access to this Private Art.",
																)
															} else {
																props.saveAndGo(
																	`/art/${row.art_id}/details`,
																	row.id,
																)
															}
														}}>
														<ReadMoreOutlinedIcon />
													</IconButton>
												</Box>
											) : null}
										</TableCell>
									</TableRow>
								)
							})}
						</TableBody>
					</Table>
				</TableContainer>
			</div>
		</>
	)
}

export default GlobalTable
