import { useQuery } from "@apollo/client"
import HomePageCard from "./HomePageCard"
import { DYNAMIC_LISTING_SEARCH } from "../Search/Queries"
import { ITEMS_PER_HOME_PAGE_WIDGET } from "../constants/values"
import { useCallback, useContext, useEffect, useState } from "react"
import { DataGridPro } from "@mui/x-data-grid-pro"
import IconButton from "@mui/material/IconButton"
import Thumbnail from "../Thumbnail/Thumbnail"
import { formatDate } from "../common/helpers"
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined"
import ReadMoreOutlinedIcon from "@mui/icons-material/ReadMoreOutlined"
import { DispatchContext } from "../store"
import { severity } from "../Snackbar/CustomizedSnackbar"
import { useNavigate } from "react-router-dom"
import InfoCard from "../InfoCard/InfoCard"
import { add, sub } from "date-fns"
import { Box } from "@mui/material"

const dataGridOperatorsToApi = (input) => {
	return (
		{
			is: "contains",
		}[input] || input
	)
}

export default function ArtFairsAndExhibitions(props) {
	// Snackbar
	const dispatch = useContext(DispatchContext)
	const openSnackbar = useCallback(
		(severity, text) => {
			dispatch({ type: "openSnackBar", payload: { severity, text } })
		},
		[dispatch],
	)
	const navigate = useNavigate()

	// Table table
	const [activeStep, setActiveStep] = useState(0)
	const [sortModel, setSortModel] = useState([])
	const [filterModel, setFilterModel] = useState({ items: [] })

	const [infoModal, setInfoModal] = useState({ open: false, row: null })

	const defaultFilters = [
		{
			field: "listing_type",
			type: "contains",
			value: "Art Fair OR Exhibition",
			isOptional: false,
		},
		{
			field: "start_at",
			type: "lte",
			value: add(new Date(), { months: 1 }).toDateString(),
			isOptional: false,
		},
		{
			field: "end_at",
			type: "gte",
			value: sub(new Date(), { weeks: 1 }).toDateString(),
			isOptional: false,
		},
	]

	// Listing Query
	const cursor = activeStep * ITEMS_PER_HOME_PAGE_WIDGET
	const variables = {
		cursor,
		limit: ITEMS_PER_HOME_PAGE_WIDGET,
		field: sortModel[0]?.field || "start_at",
		direction: (sortModel[0]?.sort || "asc").toUpperCase(),
		filters: [
			...defaultFilters,
			...filterModel.items.map((f) => ({
				field: f.columnField,
				value: f.value,
				type: dataGridOperatorsToApi(f.operatorValue),
			})),
		],
	}

	const { data, refetch, loading, error } = useQuery(DYNAMIC_LISTING_SEARCH, {
		variables,
	})
	const steps =
		data?.searchDynamicListings?.items?.length < 1
			? 1
			: Math.ceil(
					(data?.searchDynamicListings?.totalItems || 0) /
						ITEMS_PER_HOME_PAGE_WIDGET,
			  )

	// Error Handling
	useEffect(() => {
		if (error) openSnackbar(severity.ERROR, error.message)
	}, [error, openSnackbar])

	return (
		<HomePageCard
			title="Art Fairs &amp; Exhibitions"
			paginate
			totalItems={data?.searchDynamicListings?.totalItems || 0}
			steps={steps}
			activeStep={activeStep}
			setActiveStep={setActiveStep}
			handleSubmit={(newCursor) =>
				refetch({
					...variables,
					cursor: newCursor,
				})
			}>
			<div style={{ height: "calc(100% - 4em)", width: "100%" }}>
				<DataGridPro
					rowCount={data?.searchDynamicListings?.totalItems || 0}
					rows={data?.searchDynamicListings?.items || []}
					onRowClick={(params) =>
						navigate(`/listings/${params?.row?.id}/details`)
					}
					columns={[
						{
							field: "title",
							headerName: "Name",
							width: 230,
							renderCell: (params) => (
								<Thumbnail
									name={params.row?.title}
									detail={params.row?.subtitle || "-"}
									avatar={params.row?.imgUrl}
									type="listing"
									animation={loading ? "wave" : false}
									largeText></Thumbnail>
							),
						},
						{
							field: "listing_type",
							headerName: "Type",
							renderCell: (params) => params.row?.listing_type?.value,
							type: "singleSelect",
							valueOptions: ["Art Fair", "Exhibition"],
						},
						{
							headerName: "Start Date",
							field: "start_at",
							width: 230,
							filterable: false,
							type: "date",
							renderCell: (params) =>
								params.row?.start_at ? (
									formatDate(params.row?.start_at, "MMMM do, yyyy")
								) : (
									<span>-</span>
								),
						},
						{
							headerName: "Location",
							field: "location",
						},
						{
							field: "actions",
							type: "actions",
							renderCell: (params) => (
								<Box sx={{ display: "flex", gap: 1 }}>
									<IconButton
										onClick={() => {
											navigate(`/listings/${params?.row?.id}/details`)
										}}
										title="Go to Listing"
										aria-label="Go to Listing">
										<ReadMoreOutlinedIcon />
									</IconButton>
									<IconButton
										onClick={() => {
											setInfoModal({
												open: true,
												row: params.row,
											})
										}}
										aria-label="Info">
										<InfoOutlinedIcon />
									</IconButton>
								</Box>
							),
						},
					]}
					autoHeight={true}
					density={"comfortable"}
					paginationMode="server"
					sortingMode="server"
					filterMode="server"
					disableSelectionOnClick
					sortModel={props.sortModel}
					onSortModelChange={setSortModel}
					filterModel={filterModel}
					onFilterModelChange={setFilterModel}
					loading={loading}
				/>
			</div>
			<InfoCard
				open={infoModal.open}
				object={infoModal.row}
				setInfoModal={setInfoModal}
				allAttributes
			/>
		</HomePageCard>
	)
}
