/* eslint-disable eqeqeq */
import { AuthStateContext, DispatchContext, LookupContext } from "../store"
import {
	Checkbox,
	CircularProgress,
	FormControl,
	IconButton,
	Box,
	MenuItem,
	Paper,
	Select,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableRow,
	Tooltip,
} from "@mui/material"
import { CancelButton, ChooseImageButton, SubmitButton } from "../input/Buttons"
import { compareAsc, isEqual } from "date-fns"
import { MobileDateTimePicker } from "@mui/x-date-pickers-pro"
import {
	DealEntryDateTimePicker,
	DealEntryWhiteDateTimePicker,
} from "../input/DealEntry/DateTimePicker"
import { DealEntryStyle } from "../input/DealEntry/TextEntry"
import { DealEntryWhiteStyle } from "../input/DealEntry/TextEntry"
import EnhancedTableHead from "../table/EnhancedTableHead"
import {
	formatDate,
	formatter,
	getArtInventoryNumber,
	getArtistThumbnailDetail,
	returnDate,
} from "../common/helpers"
import { GET_DEAL_ENTRIES, UPSERT_DEAL_ENTRY, GET_DEAL_IDS } from "./Queries"
import { autoCompleteErrorStyles, typeStyle } from "../styles/makesStyles"
import { Pagination, Skeleton } from "@mui/material"
import { permissions, permissionValues } from "../constants/permissions"
import { severity } from "../Snackbar/CustomizedSnackbar"
import { useMutation } from "@apollo/client"
import { withApollo } from "@apollo/client/react/hoc"
import { useLocation, useNavigate, useParams } from "react-router-dom"

import clsx from "clsx"
import ConfirmationDialog from "../navigation/ConfirmationDialog/ConfirmationDialog"
import ContactThumbnail from "../Thumbnail/ContactThumbnail"
import CreateDealQuickView from "../Deals/CreateDealQuickView"
import DealEntryQueue from "./DealEntryQueue"
import DealEntryStatusModal from "./DealEntryStatusModal"
import Dropdown from "../input/Dropdown/DropdownNoBorder"
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded"
import InfoCard from "../InfoCard/InfoCard"
import React, { useCallback, useContext, useEffect, useState } from "react"
import { GalleryContactList } from "../common/components/GalleryContactList"
import TransitionsModal from "../navigation/TransitionsModal/TransitionsModal"
import PriceModal from "../Deals/Deal/PriceModal"
import Thumbnail from "../Thumbnail/Thumbnail"
import Label from "../input/Label"
import LimitedAutocomplete from "../common/LimitedAutocomplete"
import { GET_POSSIBLE_DEAL_ART } from "../Deals/Queries"
import { OfferPrice } from "../Deals/Deal/OfferPrice"
import useNavigation from "../navigation/useNavigation"
import { deal_entry_status_categories } from "./constants"
import EventIcon from "@mui/icons-material/Event"
import TextBoxThinForAutocomplete from "../input/Text/TextBoxThinForAutocomplete"
import ReadMoreIcon from "@mui/icons-material/ReadMore"
import ReorderIcon from "@mui/icons-material/Reorder"
import InfoOutlined from "@mui/icons-material/InfoOutlined"
import PlaylistRemoveIcon from "@mui/icons-material/PlaylistRemove"

const PAGE_LIMIT = 4
export const REJECTED_STATUS = 4
export const PRIMARY_CONTACT_ROLE = 1
export const OWNER_CONTACT_ROLE = 8

export const initialStatusModalState = {
	open: false,
	id: null,
	statusId: null,
	statusNote: "",
	is_interested: false,
	offer_amount: null,
	offer_currency: "1",
	sale_amount: null,
	sale_currency: null,
	reserve_begin_at: null,
	reserve_end_at: null,
}

export const initialCreateModalState = {
	open: false,
	art_id: null,
	offer_amount: null,
	offer_currency: "1",
}

export const initialPriceModalState = {
	open: false,
	rowId: null,
	currency: "1",
	amount: null,
	save: () => {},
}

export function filterStatuses(statuses, currentStatus) {
	return [
		currentStatus,
		...statuses.filter((s) =>
			currentStatus?.workflow_graph?.includes(Number(s.id)),
		),
	].filter(Boolean)
}

const DealEntryList = (props) => {
	const {
		category,
		initialSort,
		privateEntity,
		selectedRow,
		setSelectedRow,
		setQVOverride,
		page,
		creation,
		creationButtonName,
		deal,
		contact,
		art,
		otherPermissionPair,
		requeryDealEntry,
		setRequeryDealEntry,
	} = props

	const params = useParams()
	const entityId = params.id
	const navigate = useNavigate()
	const location = useLocation()

	const title = category?.value || ""

	// Styles
	const classes = autoCompleteErrorStyles()

	const [loading, setLoading] = useState(false)

	// State to controls edits
	const [currentChange, setCurrentChange] = useState(null)
	const [openDate, setOpenDate] = React.useState(null)
	const [showWarning, setShowWarning] = useState(true)
	const [attempt, setAttempt] = useState(false)

	// Modals
	const [infoModal, setInfoModal] = useState({ open: false })
	const [toggleRemoveArt, setRemoveArt] = useState(false)
	const [rearrangeQueueOpen, setRearrangeQueueOpen] = useState(false)
	const [statusModal, setStatusModal] = useState(initialStatusModalState)
	const [createModal, setCreateModal] = useState(initialCreateModalState)
	const [priceModal, setPriceModal] = useState(initialPriceModalState)

	const [currentDateField, setCurrentDateField] = React.useState(null)

	const quickviewDispatch = useContext(DispatchContext)
	const openQuickView = useCallback(
		(severity, text) => {
			quickviewDispatch({ type: "openQuickView" })
		},
		[quickviewDispatch],
	)

	// Art Autocomplete
	const [artOpen, setArtOpen] = useState(false)
	const [options, setOptions] = React.useState([])
	const [selectedOption, setSelectedOption] = useState()
	const [loadArt, setLoadArt] = React.useState(false)
	const loadingArt = artOpen && !!loadArt

	// Our main dealEntries array
	const [dealEntries, setDealEntries] = useState([
		{
			id: Date.now(),
			disabled: true,
		},
	])

	// Quick nav
	const { push: pushNav } = useNavigation()

	useEffect(() => {
		let active = true

		if (!loadingArt) {
			return undefined
		}

		props.client
			.query({
				query: GET_POSSIBLE_DEAL_ART,
				variables: { deal_id: deal?.id, query: loadArt },
			})
			.then((result) => {
				if (active) {
					setLoadArt(false)
					setOptions(
						[...result.data.getPossibleDealArt, selectedOption].filter(
							(a) => a,
						),
					)
				}
			})

		return () => (active = false)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [loadArt, loadingArt, props.client, deal])

	// Mutations
	const [updateRelation, { loading: loadingUpdateRelation }] =
		useMutation(UPSERT_DEAL_ENTRY)

	const updateRow = (row, notify) => {
		const { id, is_interested } = row

		const deal_entry_status_id = row.statusId || row.deal_entry_status?.id

		const variables = {
			id,
			deal_entry_status_id,
			status_note: row.statusNote,
			is_interested,
			project_code_id: row.project_code?.id,
		}

		if (row.reserve_begin_at) {
			variables.reserve_begin_at = returnDate(row.reserve_begin_at)
		}
		if (row.reserve_end_at) {
			variables.reserve_end_at = returnDate(row.reserve_end_at)
		}

		if (row.start_at) variables.start_at = returnDate(row.start_at)
		if (row.end_at) variables.end_at = returnDate(row.end_at)

		if (row.offer_amount) {
			variables.offer_amount = Number(row.offer_amount)
			variables.offer_currency_id = Number(
				row.offer_currency?.id || row.offer_currency || "1",
			)
		}
		if (row.sale_amount) {
			variables.sale_amount = Number(row.sale_amount)
			variables.sale_currency_id = Number(
				row.sale_currency?.id || row.sale_currency || "1",
			)
		}
		if (row.counter_offer_amount) {
			variables.counter_offer_amount = Number(row.counter_offer_amount)
			variables.counter_offer_currency_id = Number(
				row.counter_offer_currency?.id || row.counter_offer_currency || "1",
			)
		}

		// Send to server
		return updateRelation({
			variables: {
				DealEntryRequest: variables,
				notify,
			},
		})
			.then((response) => {
				if (response && response.data?.upsertDealEntryV2?.success) {
					openSnackbar(severity.SUCCESS, "Successfully updated entry.")
					if (props.reloadDeal) {
						props.reloadDeal()
					}

					const newDealEntry = response.data.upsertDealEntryV2.dealEntry

					// Stay on same card, as status remained the same, just
					// update current deal entries state
					if (
						newDealEntry.deal_entry_status?.deal_entry_status_category?.id ===
						category.id
					) {
						setDealEntries(
							dealEntries.map((dealEntry) => {
								if (dealEntry.id == id) return newDealEntry
								else return dealEntry
							}),
						)
					}

					// deal status changed, query current card and query card
					// that deal entry is going to
					if (
						newDealEntry.deal_entry_status?.deal_entry_status_category?.id !=
						category.id
					) {
						handleSubmit(0)
						setRequeryDealEntry &&
							setRequeryDealEntry(
								newDealEntry.deal_entry_status?.deal_entry_status_category,
							)
					}

					// Query other necessary cards

					// On reserves card, if the user changed the deal status
					if (
						category.id == deal_entry_status_categories.reserves &&
						newDealEntry.deal_entry_status?.deal_entry_status_category !=
							deal_entry_status_id
					) {
						// Requery the reserves card
						handleSubmit(0)

						// Query for offers card
						if (page === "art" || page === "contact") {
							setRequeryDealEntry &&
								setRequeryDealEntry(
									dealEntryStatusCategories?.find(
										(e) => e.id == deal_entry_status_categories.offers,
									),
								)
						}

						// Query for just Purchases Card
						if (page === "deal") {
							setRequeryDealEntry &&
								setRequeryDealEntry(
									dealEntryStatusCategories?.find(
										(e) => e.id == deal_entry_status_categories.purchases,
									),
								)
						}
					}
					return newDealEntry
				} else if (response?.data && !response.data.success) {
					openSnackbar(
						severity[response.data.upsertDealEntryV2.severity] ??
							severity.ERROR,
						response.data.upsertDealEntryV2.message ??
							"There was an error updating this entry.",
					)
				} else throw response.errors
			})
			.catch((error) => {
				console.error(error)
				const message = error[0]?.message
				openSnackbar(
					severity.ERROR,
					message ?? "There was an error updating this entry.",
				)
			})
	}

	// actions menu
	const [currentRow, setCurrentRow] = useState(null)

	// Lookups
	const lookup = useContext(LookupContext)
	const authState = useContext(AuthStateContext)
	const statusTypes = lookup?.data?.getDealEntryStatuses
	const dealEntryStatusCategories = lookup.data?.getDealEntryStatusCategories

	// User Permissions
	const userPermissions = authState?.user?.permissions
	const canRearrange =
		userPermissions?.find((p) => p.permission_id == permissions.ADMIN_CONSOLE)
			?.permission_value_id == permissionValues.YES

	// State to control hiding second button on card
	const [showOtherButton, setShowOtherButton] = React.useState(true)
	useEffect(() => {
		if (otherPermissionPair && userPermissions) {
			const permission = userPermissions?.find(
				(e) => e.permission_id == otherPermissionPair[0],
			).permission_value_id

			if (permission < otherPermissionPair[1]) {
				setShowOtherButton(false)
			}
		}
	}, [otherPermissionPair, userPermissions])

	// Tooltips
	const [open, setOpen] = React.useState({})
	const handleTTClose = (row) =>
		setOpen({
			...open,
			[row.id]: false,
		})
	const handleTTOpen = (row) =>
		setOpen({
			...open,
			[row.id]: true,
		})

	// Pagination
	const [steps, setSteps] = useState(1)
	const [activeStep, setActiveStep] = useState(0)
	const [totalItems, setTotalItems] = useState(0)

	// Order of table
	const [order, setOrder] = useState(
		!initialSort || initialSort == "reserve_position" ? "asc" : "desc",
	)
	const [orderBy, setOrderBy] = useState(initialSort)

	//Snackbar
	const dispatch = useContext(DispatchContext)
	const openSnackbar = useCallback(
		(severity, text) => {
			dispatch({ type: "openSnackBar", payload: { severity, text } })
		},
		[dispatch],
	)

	// Handle the case where a search is queued by sorting
	const handleRequestSort = (event, property) => {
		setActiveStep(0)
		const isAsc = orderBy === property && order === "asc"
		setOrder(isAsc ? "desc" : "asc")
		setOrderBy(property)
	}

	const handleSubmit = useCallback(
		(cursor = 0) => {
			if (!category?.id || !entityId || loading) return

			setLoading(true)

			const input = {
				field: orderBy,
				direction: order.toUpperCase(),
				cursor,
				limit: PAGE_LIMIT,
				filters: {
					entity: page,
					entityId: entityId,
					dealEntryStatusCategoryId: category.id,
				},
			}

			props.client
				.query({
					query: GET_DEAL_ENTRIES,
					variables: { GetDealEntriesRequest: input },
				})
				.then((result) => {
					const { data } = result

					setLoading(false)
					setDealEntries(data.getDealEntries.items)
					setTotalItems(data.getDealEntries.totalItems)

					if (data.getDealEntries.items?.length < 1) setSteps(1)
					else setSteps(Math.ceil(data.getDealEntries.totalItems / PAGE_LIMIT))
				})
				.catch((error) => {
					setLoading(false)
					console.error(error)
				})
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[category, entityId, orderBy, order, page, props.client],
	)

	// Submit on page load, order
	useEffect(() => {
		handleSubmit()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [category?.id, entityId, order, orderBy])

	// clear on page nav
	useEffect(() => {
		setDealEntries([
			{
				id: Date.now(),
				disabled: true,
			},
		])
	}, [entityId])

	// Submit on request from other deal entry list
	useEffect(() => {
		if (requeryDealEntry && category && requeryDealEntry?.id == category?.id) {
			handleSubmit(activeStep * PAGE_LIMIT)
			setRequeryDealEntry({})
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [activeStep, category, requeryDealEntry])

	// Check if the user doesn't have access to view deals, return null
	const canSeeDeals =
		userPermissions?.find((e) => e.permission_id == permissions.DEALS)
			.permission_value_id > permissionValues.CANNOT_SEE

	if (!canSeeDeals) return <></>

	/* ==========================================================================
	Helper Functions
	========================================================================== */

	const formatPrice = (currency, amt) =>
		amt ? formatter(currency || "USD").format(amt) : null

	/**
	 * Queues quick navigation after execution of special query for ids of
	 * deal entry list.
	 */
	const quickNavigate = (row, page, index, artOverride) => {
		let route = ""
		let field = ""

		if (page == "deal" || artOverride) {
			route = "art"
			field = "art_id"
		} else {
			route = "deals"
			field = "deal_id"
		}

		const hidden =
			(row?.deal.is_private || row?.deal?.is_gallery_private) &&
			!row?.deal?.created_at

		// Check if you have access to navigate
		if (hidden) {
			return
		}

		if (row.deal?.publicAccess === null) {
			return
		}

		const input = {
			field: orderBy,
			direction: order.toUpperCase(),
			cursor: null, // intentionally null
			limit: null,
			filters: {
				entity: page,
				entityId: entityId,
				dealEntryStatusCategoryId: category.id,
			},
		}

		props.client
			.query({
				query: GET_DEAL_IDS,
				variables: { GetDealEntriesRequest: input },
			})
			.then((result) => {
				const { data } = result
				const tempDealEntries = data.getDealIds

				let ids = []

				if (page == "deal") {
					ids = tempDealEntries.map((e) => e.art_id)
				} else {
					ids = tempDealEntries.map((e) => e.deal_id)
				}

				const state = { variables: { cursor: index } }

				pushNav(
					{
						url: location.pathname,
						state,
						query: ids,
					},
					index,
				)

				navigate(`/${route}/${row[field]}/details`, { state })
			})
			.catch((error) => {
				console.error(error)
				openSnackbar(
					severity.ERROR,
					"There was an error attempting to quick navigate.",
				)
			})
	}

	/**
	 * Sets quick view or starts quick navigation.
	 */
	const dealEntryClick = (row, page, index, event) => {
		if (currentChange || !!openDate || row.disabled) return null

		const hidden =
			(row.deal?.is_private || row.deal?.is_gallery_private) &&
			!row.deal?.created_at

		if (!hidden && event.metaKey) {
			let route = ""
			let field = ""

			if (page == "deal") {
				route = "art"
				field = "art_id"
			} else {
				route = "deals"
				field = "deal_id"
			}
			window.open(`/${route}/${row[field]}/details`, "_blank")
			return
		}

		setSelectedRow(row)

		if (selectedRow?.id === row?.id && !hidden) {
			quickNavigate(row, page, index)
		}
	}

	const hasNoReserve = !dealEntries?.find((row) => row.id == statusModal.id)
		?.reserveQueue?.hasReserve

	/* ==========================================================================
	Cells
	========================================================================== */

	const getPrimaryContactCell = (row) => {
		if (loading || !category)
			return (
				<TableCell scope="row" component="th" style={{ minWidth: "17em" }}>
					<Skeleton animation="wave" variant="text" />
				</TableCell>
			)

		const primaryContact = row?.deal?.contacts?.find(
			(e) => e.contactRole?.id == PRIMARY_CONTACT_ROLE,
		)

		const inner = primaryContact ? (
			<div
				style={{
					borderRadius: 8,
					backgroundColor:
						primaryContact?.is_private && !row.is_private ? "#2E3134" : null,
				}}>
				<ContactThumbnail
					contact={primaryContact}
					darkText={!primaryContact.is_private}
					style={{ padding: "1em" }}
				/>
			</div>
		) : (
			<span>-</span>
		)

		return (
			<TableCell scope="row" component="th" style={{ minWidth: "17em" }}>
				{inner}
			</TableCell>
		)
	}

	const getGalleryContactsCell = (row) => {
		if (loading || !category)
			return (
				<TableCell>
					<Skeleton animation="wave" variant="text" />
				</TableCell>
			)

		const inner = (
			<GalleryContactList
				salespersons={row?.deal?.salesperson}
				findMode={false}
				max={2}
			/>
		)

		return <TableCell>{inner}</TableCell>
	}

	const DateCell = ({ row, change, field, format = "MMM d, yyyy" }) => {
		const [dateTimeVal, setDateTimeVal] = React.useState(returnDate(row[field]))

		if (loading || !category)
			return (
				<TableCell>
					<Skeleton animation="wave" variant="text" />
				</TableCell>
			)

		return (
			<TableCell>
				<div
					style={{
						display: "flex",
					}}>
					<MobileDateTimePicker
						name="date"
						showTodayButton
						disableMaskedInput={true}
						todayLabel="Now"
						inputFormat={format}
						open={openDate === row.id && currentDateField == field}
						inputVariant="outlined"
						variant="dialog"
						onOpen={() => setOpenDate(row.id)}
						onClose={() => {
							setOpenDate(null)
							setCurrentDateField(null)
						}}
						onAccept={(date) => {
							const startDate = returnDate(row.start_at)
							const endDate = returnDate(row.end_at)

							if (
								(field === "end_at" || field == "start_at") &&
								compareAsc(startDate, endDate) === 1
							) {
								openSnackbar(
									severity.WARNING,
									'The "End Date" is before the "Start Date". ',
								)
							}

							if (isEqual(returnDate(row[field]), date)) return // No Op

							change({
								...row,
								[field]: date,
							})
						}}
						value={dateTimeVal}
						inputProps={{ multiline: "true" }}
						labelFunc={(date) => formatDate(date, format) || "-"}
						renderInput={({ inputRef, inputProps, InputProps } = props) => {
							inputProps.placeholder = "-"

							if (row?.is_private)
								return (
									<DealEntryWhiteDateTimePicker
										ref={inputRef}
										{...inputProps}
									/>
								)
							return <DealEntryDateTimePicker ref={inputRef} {...inputProps} />
						}}
						onChange={setDateTimeVal}
					/>

					<IconButton
						disabled={
							(row.deal?.is_private || row.deal?.is_gallery_private) &&
							!row.deal?.created_at
						}
						sx={{ height: 40, width: 40, marginTop: "4px" }}
						color="primary"
						aria-label="choose date"
						component="span"
						onClick={(event) => {
							event.stopPropagation()
							event.preventDefault()
							setOpenDate(row.id)
							setCurrentDateField(field)
						}}>
						<EventIcon sx={{ color: "#919191" }} />
					</IconButton>
				</div>
			</TableCell>
		)
	}

	const getPriceCell = (row, currencyField, amountField, name) => {
		if (loading || !category)
			return (
				<TableCell>
					<Skeleton animation="wave" variant="text" />
				</TableCell>
			)

		const hiddenDeal =
			(row.deal?.is_private || row.deal?.is_gallery_private) &&
			!row.deal?.created_at

		const onClick = () => {
			setPriceModal({
				...priceModal,
				open: true,
				rowId: row.id,
				currency: row[currencyField]?.id,
				amount: row[amountField],
				name,
				save: (values) => {
					updateRow({
						...row,
						[amountField]: values.amount,
						[currencyField]: values.currency,
					})
				},
			})
		}

		if (hiddenDeal)
			return (
				<TableCell>
					<span>-</span>
				</TableCell>
			)

		return (
			<TableCell onClick={onClick}>
				{row?.is_private ? (
					<DealEntryWhiteStyle
						onMouseEnter={() => setCurrentChange(true)}
						onMouseLeave={() => setCurrentChange(false)}
						value={
							formatPrice(row[currencyField]?.label, row[amountField]) || "-"
						}
					/>
				) : (
					<DealEntryStyle
						onMouseEnter={() => setCurrentChange(true)}
						onMouseLeave={() => setCurrentChange(false)}
						value={
							formatPrice(row[currencyField]?.label, row[amountField]) || "-"
						}
					/>
				)}
			</TableCell>
		)
	}

	const getClientInterestCell = (row, change) => {
		return (
			<TableCell padding="checkbox">
				<Checkbox
					style={{
						color: row?.is_private ? "white" : null,
					}}
					checked={row.is_interested}
					disabled={
						((row.deal?.is_private || row.deal?.is_gallery_private) &&
							!row.deal?.created_at) ||
						loading
					}
					onClick={(e) => {
						e.preventDefault()
						e.stopPropagation()

						change({
							...row,
							is_interested: !row.is_interested,
						})
					}}
				/>
			</TableCell>
		)
	}

	const getDealStatusCell = (row) => {
		if (loading || !category)
			return (
				<TableCell>
					<Skeleton animation="wave" variant="text" />
				</TableCell>
			)

		return (
			<TableCell className={row?.is_private ? "private-object" : null}>
				<Tooltip
					title={row.status_note || ""}
					arrow
					open={open[row.id] || false}
					disableFocusListener
					disableTouchListener
					disableHoverListener
					onClose={() => handleTTClose(row)}
					onOpen={() => handleTTOpen(row)}
					placement="bottom"
					enterDelay={1000}>
					<Select
						IconComponent={ExpandMoreRoundedIcon}
						id="listing-contacts-status"
						input={<Dropdown />}
						className="hideborder"
						value={(statusTypes.length && row.deal_entry_status?.id) || ""}
						onClick={(e) => {
							e.preventDefault()
							e.stopPropagation()
						}}
						onOpen={() => handleTTClose(row)}
						onMouseEnter={() => handleTTOpen(row)}
						onMouseLeave={() => handleTTClose(row)}
						onChange={(event) => {
							// The current row should be set on status change
							setCurrentRow(row)
							let newStatusModalValue = {}
							if (hasNoReserve && event.target.value == 4) {
								newStatusModalValue = {
									offer_amount: row.offer_amount,
									offer_currency: row.offer_currency?.id || null,
								}
							} else if (event.target.value == 7) {
								newStatusModalValue = {
									sale_amount: row.offer_amount,
									sale_currency: row.offer_currency?.id || null,
								}
							}
							setStatusModal({
								...statusModal,
								open: true,
								id: row.id,
								statusId: event.target.value,
								...newStatusModalValue,
							})
						}}
						style={{
							color: privateEntity || row?.is_private ? "white" : null,
						}}
						data-testid="deal-entry-status-select">
						{statusTypes &&
							filterStatuses(statusTypes, row.deal_entry_status).map((type) => (
								<MenuItem
									style={{
										color: privateEntity ? "white" : null,
									}}
									key={type.id}
									value={type.id}>
									{type.id == 4 &&
									row.deal_entry_status?.id == 4 &&
									!row.has_reserve
										? "In Queue - " + row.reserve_position
										: type.value}
								</MenuItem>
							))}
					</Select>
				</Tooltip>
			</TableCell>
		)
	}

	const getActionCell = (row, index) => {
		const hidden =
			(row?.deal?.is_private || row?.deal?.is_gallery_private) &&
			!row?.deal?.created_at
		const hiddenArt = row?.art?.is_private && !row?.art?.created_at

		return (
			<TableCell>
				<Box flexDirection="row" flex={1} flexWrap={"nowrap"}>
					{props.page != "deal" ? (
						<IconButton
							aria-label={hidden ? "Go to Private Deal" : "Go to Deal"}
							title={hidden ? "Go to Private Deal" : "Go to Deal"}
							onClick={(e) => {
								e.stopPropagation()
								if (hidden) {
									openSnackbar(
										severity.WARNING,
										"You do not have access to this Private Deal.",
									)
								}
								if (row.deal?.publicAccess === null) {
									openSnackbar(
										severity.WARNING,
										"You do not have access to this Public Deal.",
									)
								} else {
									quickNavigate(row, page, index)
								}
							}}>
							<ReadMoreIcon />
						</IconButton>
					) : null}
					{props.page !== "art" ? (
						<IconButton
							aria-label={hiddenArt ? "Go to Private Art" : "Go to Art"}
							title={hiddenArt ? "Go to Private Art" : "Go to Art"}
							onClick={(e) => {
								e?.stopPropagation()
								if (hiddenArt) {
									openSnackbar(
										severity.WARNING,
										"You do not have access to this Private Art.",
									)
								} else {
									quickNavigate(row, page, index, true)
								}
							}}>
							<ReadMoreIcon />
						</IconButton>
					) : null}

					{canRearrange && row?.deal_entry_status?.id == REJECTED_STATUS ? (
						<IconButton
							aria-label="Change Queue Order"
							title="Change Queue Order"
							onClick={(e) => {
								e?.stopPropagation()
								setCurrentRow(row)
								setRearrangeQueueOpen(true)
							}}>
							<ReorderIcon />
						</IconButton>
					) : null}
					<IconButton
						aria-label="Information"
						title="Information"
						onClick={(e) => {
							e?.stopPropagation()
							setCurrentRow(row)
							setInfoModal({ open: true })
						}}>
						<InfoOutlined />
					</IconButton>
					<IconButton
						aria-label="Remove from Deal"
						title="Remove from Deal"
						onClick={(e) => {
							e?.stopPropagation()
							setCurrentRow(row)
							setRemoveArt(true)
						}}>
						<PlaylistRemoveIcon />
					</IconButton>
				</Box>
			</TableCell>
		)
	}

	const getArtworksCell = (row) => {
		const hidden =
			(row?.deal?.is_private || row?.deal?.is_gallery_private) &&
			!row?.deal?.created_at
		const isPrivate = row.art?.is_private

		return (
			<TableCell>
				<div
					className={isPrivate ? "shadow-group" : null}
					style={
						isPrivate
							? { backgroundColor: "#2E3134", margin: 0 }
							: { margin: 0 }
					}>
					<Thumbnail
						formatted_name={
							loading || !category ? null : row.art?.formatted_title
						}
						name={loading || !category ? null : row.art?.code_name}
						artist={
							loading || !category ? null : getArtistThumbnailDetail(row.art)
						}
						date={loading || !category ? null : row.art?.year}
						avatar={loading || !category ? null : row.art?.imgUrl}
						type="art"
						style={{ paddingLeft: "1em" }}
						animation={loading || !category ? "wave" : false}
						largeText
						darkText={!isPrivate}
						hasAccess={!hidden}></Thumbnail>
				</div>
			</TableCell>
		)
	}

	const getInventoryNumCell = (row) => {
		if (loading || !category)
			return (
				<TableCell>
					<Skeleton animation="wave" variant="text" />
				</TableCell>
			)

		return <TableCell>{getArtInventoryNumber(row.art)}</TableCell>
	}

	/* ==========================================================================
	Rows
	========================================================================== */

	const getSoldRow = (row, page, index) => {
		const hidden = row.is_private && !row.created_at
		const isPrivate = row.is_private

		return (
			<TableRow
				key={row.id}
				className={clsx({ private: isPrivate && !privateEntity })}
				style={{ cursor: "pointer" }}
				hover={!hidden && !isPrivate}
				selected={row?.id == selectedRow?.id}
				onClick={(event) => {
					event.stopPropagation()
					dealEntryClick(row, page, index, event)
				}}>
				{page === "art" && getPrimaryContactCell(row)}
				{page !== "art" && getArtworksCell(row)}
				{page !== "art" && getInventoryNumCell(row)}
				{page !== "deal" && getGalleryContactsCell(row)}
				<DateCell row={row} change={(row) => updateRow(row)} field="end_at" />
				{getPriceCell(row, "sale_currency", "sale_amount", "Sale Price")}
				{getDealStatusCell(row)}
				{getActionCell(row, index)}
			</TableRow>
		)
	}

	const getConsignmentInRow = (row, page, index) => {
		const hidden = row.is_private && !row.created_at
		const isPrivate = row.is_private

		return (
			<TableRow
				key={row.id}
				className={clsx({
					private: isPrivate && !privateEntity,
				})}
				style={{ cursor: "pointer" }}
				hover={!hidden && !isPrivate}
				selected={row?.id == selectedRow?.id}
				onClick={(event) => dealEntryClick(row, page, index, event)}>
				{page === "art" && getPrimaryContactCell(row)}
				{page !== "art" && getArtworksCell(row)}
				{page !== "art" && getInventoryNumCell(row)}
				{getGalleryContactsCell(row)}

				{page !== "deal" ? (
					<DateCell
						row={row}
						change={(row) => updateRow(row)}
						field="start_at"
					/>
				) : null}
				{page !== "deal" ? (
					<DateCell row={row} change={(row) => updateRow(row)} field="end_at" />
				) : null}
				{getPriceCell(row, "offer_currency", "offer_amount", "Offer Price")}
				{getDealStatusCell(row)}
				{getActionCell(row, index)}
			</TableRow>
		)
	}

	const getConsignmentOutRow = (row, page, index) => {
		const hidden = row.is_private && !row.created_at
		const isPrivate = row.is_private

		return (
			<TableRow
				key={row.id}
				className={clsx({
					private: isPrivate && !privateEntity,
				})}
				style={{ cursor: "pointer" }}
				hover={!hidden && !isPrivate}
				selected={row?.id == selectedRow?.id}
				onClick={(event) => dealEntryClick(row, page, index, event)}>
				{getPrimaryContactCell(row)}
				{getGalleryContactsCell(row)}
				{page !== "deal" ? (
					<DateCell
						row={row}
						change={(row) => updateRow(row)}
						field="start_at"
					/>
				) : null}
				{page !== "deal" ? (
					<DateCell row={row} change={(row) => updateRow(row)} field="end_at" />
				) : null}
				{getPriceCell(row, "offer_currency", "offer_amount", "Offer Price")}
				{getDealStatusCell(row)}
				{getActionCell(row, index)}
			</TableRow>
		)
	}

	const getOffersRow = (row, page, index) => {
		const hidden = row.is_private && !row.created_at
		const isPrivate = row.is_private

		return (
			<TableRow
				key={row.id}
				className={clsx({
					private: isPrivate && !privateEntity,
				})}
				style={{ cursor: "pointer" }}
				hover={!hidden && !isPrivate}
				selected={row?.id == selectedRow?.id}
				onClick={(event) => dealEntryClick(row, page, index, event)}>
				{page === "art" && getPrimaryContactCell(row)}
				{page !== "art" && getArtworksCell(row)}
				{page !== "art" && getInventoryNumCell(row)}
				{page !== "deal" && getGalleryContactsCell(row)}
				<DateCell row={row} change={(row) => updateRow(row)} field="start_at" />
				{getClientInterestCell(row, (row) => updateRow(row))}
				{getPriceCell(row, "offer_currency", "offer_amount", "Offer Price")}
				{getDealStatusCell(row)}
				{getActionCell(row, index)}
			</TableRow>
		)
	}

	const getReservesRow = (row, page, index) => {
		const hidden = row.is_private && !row.created_at
		const isPrivate = row.is_private

		return (
			<TableRow
				key={row.id}
				className={clsx({
					private: isPrivate && !privateEntity,
				})}
				style={{ cursor: "pointer" }}
				hover={!hidden && !isPrivate}
				selected={row?.id == selectedRow?.id}
				onClick={(event) => {
					dealEntryClick(row, page, index, event)
				}}>
				{page === "art" && getPrimaryContactCell(row)}
				{page !== "art" && getArtworksCell(row)}
				{page !== "art" && getInventoryNumCell(row)}
				{page !== "deal" && getGalleryContactsCell(row)}
				<DateCell
					row={row}
					change={(row) => updateRow(row)}
					field="reserve_end_at"
				/>
				{getPriceCell(
					row,
					"counter_offer_currency",
					"counter_offer_amount",
					"Counter Offer",
				)}
				{getPriceCell(row, "offer_currency", "offer_amount", "Offer Price")}
				{getDealStatusCell(row)}
				{getActionCell(row, index)}
			</TableRow>
		)
	}

	const getLoanRow = (row, page, index) => {
		const hidden = row.is_private && !row.created_at
		const isPrivate = row.is_private

		return (
			<TableRow
				key={row.id}
				className={clsx({
					private: isPrivate && !privateEntity,
				})}
				style={{ cursor: "pointer" }}
				hover={!hidden && !isPrivate}
				selected={row?.id == selectedRow?.id}
				onClick={(event) => dealEntryClick(row, page, index, event)}>
				{getArtworksCell(row)}
				{getInventoryNumCell(row)}
				{page !== "deal" ? (
					<DateCell
						row={row}
						change={(row) => updateRow(row)}
						field="start_at"
					/>
				) : null}
				{page !== "deal" ? (
					<DateCell row={row} change={(row) => updateRow(row)} field="end_at" />
				) : null}
				{getPriceCell(row, "offer_currency", "offer_amount", "Offer Price")}
				{getDealStatusCell(row)}
				{getActionCell(row, index)}
			</TableRow>
		)
	}

	const getGalleryPurchaseRow = (row, page, index) => {
		const hidden = row.is_private && !row.created_at
		const isPrivate = row.is_private

		return (
			<TableRow
				key={row.id}
				className={clsx({
					private: isPrivate && !privateEntity,
				})}
				style={{ cursor: "pointer" }}
				hover={!hidden && !isPrivate}
				selected={row?.id == selectedRow?.id}
				onClick={(event) => dealEntryClick(row, page, index, event)}>
				{getArtworksCell(row)}
				{getInventoryNumCell(row)}
				<DateCell row={row} change={(row) => updateRow(row)} field="start_at" />
				<DateCell row={row} change={(row) => updateRow(row)} field="end_at" />
				{getPriceCell(row, "offer_currency", "offer_amount", "Offer Price")}
				{getPriceCell(row, "sale_currency", "sale_amount", "Sale Price")}
				{getDealStatusCell(row)}
				{getActionCell(row, index)}
			</TableRow>
		)
	}

	const getKnownPurchaseRow = (row, page, index) => {
		const hidden = row.is_private && !row.created_at
		const isPrivate = row.is_private

		return (
			<TableRow
				key={row.id}
				className={clsx({
					private: isPrivate && !privateEntity,
				})}
				style={{ cursor: "pointer" }}
				hover={!hidden && !isPrivate}
				selected={row?.id == selectedRow?.id}
				onClick={(event) => dealEntryClick(row, page, index, event)}>
				{getArtworksCell(row)}
				<DateCell row={row} change={(row) => updateRow(row)} field="start_at" />
				{getPriceCell(row, "sale_currency", "sale_amount", "Purchase Price")}
				{getDealStatusCell(row)}
				{getActionCell(row, index)}
			</TableRow>
		)
	}

	/* ==========================================================================
	Columns
	========================================================================== */

	const primaryContactCol = {
		id: "primary_contact",
		numeric: false,
		disablePadding: false,
		label: "Primary Contact",
		noSort: true,
	}
	const galleryContactsCol = {
		id: "gallery_contacts",
		numeric: false,
		disablePadding: false,
		label: "Gallery Contacts",
		noSort: true,
	}
	const dealStatusCol = {
		id: "deal_entry_status.value",
		numeric: false,
		disablePadding: false,
		label: "Deal Status",
	}
	const actionCol = {
		id: "actions",
		numeric: false,
		disablePadding: false,
		label: "",
		noSort: true,
	}
	const startAtCol = (label = "Start Date") => ({
		id: "start_at",
		numeric: false,
		disablePadding: false,
		label,
	})
	const endAtCol = (label) => ({
		id: "end_at",
		numeric: false,
		disablePadding: false,
		label,
	})
	const offerPriceCol = {
		id: "offer_amount",
		numeric: false,
		disablePadding: false,
		label: "Offer Price",
	}
	const artworkCol = {
		id: "artwork",
		numeric: false,
		disablePadding: false,
		label: "Artwork",
		noSort: true,
	}
	const inventoryNumberCol = {
		id: "inventory_number",
		numeric: false,
		disablePadding: false,
		label: "Inventory No.",
		noSort: true,
	}
	const salePriceCol = {
		id: "sale_amount",
		numeric: false,
		disablePadding: false,
		label: "Sale Price",
	}
	const offerDateCol = {
		id: "start_at",
		numeric: false,
		disablePadding: false,
		label: "Offer Date",
	}
	const interestCol = {
		id: "is_interested",
		numeric: false,
		disablePadding: false,
		label: "Client Interest",
	}
	const expirationCol = {
		id: "reserve_end_at",
		numeric: false,
		disablePadding: false,
		label: "Reserve Expiration Date",
	}
	const counterOfferCol = {
		id: "counter_offer_amount",
		numeric: false,
		disablePadding: false,
		label: "Counter Offer",
	}

	/* ==========================================================================
	Mapping
	========================================================================== */

	const listMapping = {
		Purchases: {
			headCell: {
				art: [
					primaryContactCol,
					galleryContactsCol,
					endAtCol("Sale Date"),
					salePriceCol,
					dealStatusCol,
					actionCol,
				],
				contact: [
					artworkCol,
					inventoryNumberCol,
					galleryContactsCol,
					endAtCol("Sale Date"),
					salePriceCol,
					dealStatusCol,
					actionCol,
				],
				deal: [
					artworkCol,
					inventoryNumberCol,
					endAtCol("Sale Date"),
					salePriceCol,
					dealStatusCol,
					actionCol,
				],
			},
			row: (row, index) => getSoldRow(row, page, index),
		},
		"Consignment In": {
			headCell: {
				art: [
					primaryContactCol,
					galleryContactsCol,
					startAtCol(),
					endAtCol("End Date"),
					offerPriceCol,
					dealStatusCol,
					actionCol,
				],
				contact: [
					artworkCol,
					inventoryNumberCol,
					galleryContactsCol,
					startAtCol(),
					endAtCol("End Date"),
					offerPriceCol,
					dealStatusCol,
					actionCol,
				],
				deal: [
					artworkCol,
					inventoryNumberCol,
					galleryContactsCol,
					offerPriceCol,
					dealStatusCol,
					actionCol,
				],
			},
			row: (row, index) => getConsignmentInRow(row, page, index),
		},
		"Consignment Out": {
			headCell: {
				art: [
					primaryContactCol,
					galleryContactsCol,
					startAtCol(),
					endAtCol("End Date"),
					offerPriceCol,
					dealStatusCol,
					actionCol,
				],
				deal: [
					primaryContactCol,
					galleryContactsCol,
					offerPriceCol,
					dealStatusCol,
					actionCol,
				],
			},
			row: (row, index) => getConsignmentOutRow(row, page, index),
		},
		Offers: {
			headCell: {
				art: [
					primaryContactCol,
					galleryContactsCol,
					offerDateCol,
					interestCol,
					offerPriceCol,
					dealStatusCol,
					actionCol,
				],
				contact: [
					artworkCol,
					inventoryNumberCol,
					galleryContactsCol,
					offerDateCol,
					interestCol,
					offerPriceCol,
					dealStatusCol,
					actionCol,
				],
				deal: [
					artworkCol,
					inventoryNumberCol,
					offerDateCol,
					interestCol,
					offerPriceCol,
					dealStatusCol,
					actionCol,
				],
			},
			row: (row, index) => getOffersRow(row, page, index),
		},
		Reserves: {
			headCell: {
				art: [
					primaryContactCol,
					galleryContactsCol,
					expirationCol,
					counterOfferCol,
					offerPriceCol,
					dealStatusCol,
					actionCol,
				],
				contact: [
					artworkCol,
					inventoryNumberCol,
					galleryContactsCol,
					expirationCol,
					counterOfferCol,
					offerPriceCol,
					dealStatusCol,
					actionCol,
				],
				deal: [
					artworkCol,
					inventoryNumberCol,
					expirationCol,
					counterOfferCol,
					offerPriceCol,
					dealStatusCol,
					actionCol,
				],
			},
			row: (row, index) => getReservesRow(row, page, index),
		},
		"Loan In": {
			headCell: {
				deal: [
					artworkCol,
					inventoryNumberCol,
					offerPriceCol,
					dealStatusCol,
					actionCol,
				],
			},
			row: (row, index) => getLoanRow(row, page, index),
		},
		"Loan Out": {
			headCell: {
				deal: [
					artworkCol,
					inventoryNumberCol,
					offerPriceCol,
					dealStatusCol,
					actionCol,
				],
			},
			row: (row, index) => getLoanRow(row, page, index),
		},
		"Gallery Purchase": {
			headCell: {
				deal: [
					artworkCol,
					inventoryNumberCol,
					startAtCol("Last Known Date"),
					endAtCol("Sale Date"),
					offerPriceCol,
					salePriceCol,
					dealStatusCol,
					actionCol,
				],
			},
			row: (row, index) => getGalleryPurchaseRow(row, page, index),
		},
		"Known Source": {
			headCell: {
				deal: [
					artworkCol,
					startAtCol("Purchase Date"),
					salePriceCol,
					dealStatusCol,
					actionCol,
				],
			},
			row: (row, index) => getKnownPurchaseRow(row, page, index),
		},
	}

	return (
		<>
			<Paper className={`qv-margin category-${category?.id}`}>
				<h1 className="card-title">
					<span>
						{title ? (
							title
						) : (
							<Skeleton style={{ width: "5em" }} animation="wave" />
						)}
						{category && !loading ? ` (${totalItems})` : null}
					</span>
					{creation && category && showOtherButton ? (
						<ChooseImageButton
							variant="contained"
							color="secondary"
							size="medium"
							style={{
								marginLeft: "auto",
							}}
							onClick={() => {
								if (page === "deal") {
									setCreateModal({
										...createModal,
										open: true,
									})
								} else {
									setQVOverride(() => (
										<CreateDealQuickView
											typeId={category.deal_type_id}
											contacts={contact ? [{ ...contact }] : []}
											art={art ? [{ ...art }] : []}
											setCreateDeal={() => setQVOverride(null)}
											darkTheme={privateEntity || false}></CreateDealQuickView>
									))
								}

								openQuickView()
							}}>
							{creationButtonName}
						</ChooseImageButton>
					) : null}
				</h1>
				<TableContainer
					className={privateEntity ? "search-list dark-theme" : "search-list"}>
					<Table
						aria-labelledby="Deal Art List Table"
						size="medium"
						aria-label="Deal Art List">
						<EnhancedTableHead
							headCells={
								category?.value
									? listMapping[category?.value].headCell[page]
									: []
							}
							order={order}
							orderBy={orderBy}
							onRequestSort={handleRequestSort}
							rowCount={dealEntries?.length ?? 0}
						/>

						<TableBody>
							{dealEntries
								?.filter((f) => f.id)
								?.map((dealEntry, index) =>
									listMapping[category?.value]?.row(dealEntry, index),
								)}
						</TableBody>
					</Table>
				</TableContainer>

				<div
					style={{
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
					}}>
					<Pagination
						style={{
							paddingTop: "2em",
							paddingBottom: "1em",
							margin: "auto",
							background: "transparent",
						}}
						count={steps}
						page={activeStep + 1}
						onChange={(event, page) => {
							handleSubmit((page - 1) * PAGE_LIMIT)
							setActiveStep(page - 1)

							// Scroll to top of current deal art list card
							var elmnt = document.querySelector(`.category-${category?.id}`)
							elmnt.scrollIntoView({
								behavior: "smooth",
								block: "start",
							})
						}}
					/>
				</div>
			</Paper>

			{/* Modals */}
			<InfoCard
				open={infoModal.open}
				object={currentRow}
				setInfoModal={setInfoModal}
				allAttributes
			/>

			<ConfirmationDialog
				open={toggleRemoveArt || false}
				handleClose={setRemoveArt}
				title={"Remove from Deal?"}
				acceptText={"Remove"}
				text={`This will remove this artwork from the current deal.`}
				onYes={() => {
					const variables = {
						id: currentRow.id,
						delete: true,
					}

					// Send to server
					updateRelation({
						variables: {
							DealEntryRequest: variables,
						},
					})
						.then((response) => {
							if (response && !response.errors) {
								openSnackbar(severity.SUCCESS, "Successfully deleted relation.")
								setCurrentRow(null)
								handleSubmit(0)
								if (props.reloadDeal) props.reloadDeal()
							} else throw response.errors
						})
						.catch((error) => {
							console.error(error)
							openSnackbar(
								severity.ERROR,
								"There was an error deleting this relation.",
							)
						})
				}}
			/>

			<DealEntryQueue
				open={rearrangeQueueOpen}
				close={() => setRearrangeQueueOpen(false)}
				setDealEntries={setDealEntries}
				handleSubmit={handleSubmit}
				dealEntryStatusCategoryId={category?.id}
				currentRow={currentRow}
				entityId={currentRow?.art?.id}
				limit={PAGE_LIMIT}
				activeStep={activeStep}></DealEntryQueue>

			<DealEntryStatusModal
				statusModal={statusModal}
				setStatusModal={setStatusModal}
				hasNoReserve={hasNoReserve}
				lookup={lookup}
				disabled={loadingUpdateRelation}
				updateRow={(row, notification) => updateRow(row, notification)}
				currentRow={currentRow}
				setCurrentRow={setCurrentRow}
				page={page}
				entityId={currentRow?.art?.id}
				order={order}
				orderBy={orderBy}
				category={category}
				privateEntity={privateEntity}
				onUpdateArtStatus={props.onUpdateArtStatus}
			/>

			<TransitionsModal
				open={priceModal.open}
				close={() =>
					setPriceModal({ ...priceModal, ...initialPriceModalState })
				}>
				<div className={privateEntity ? "dark-theme" : null}>
					<PriceModal
						name={priceModal.name}
						close={() => setPriceModal(initialPriceModalState)}
						amount={priceModal.amount}
						currency={priceModal.currency}
						save={priceModal.save}
						disabled={loadingUpdateRelation}></PriceModal>
				</div>
			</TransitionsModal>

			<TransitionsModal
				className="add-art-modal"
				open={createModal.open}
				close={() => {
					setCreateModal(initialCreateModalState)
					setAttempt(false)
				}}>
				<div
					className={
						privateEntity
							? "dark-theme deal-contact-modal"
							: "deal-contact-modal"
					}>
					<h2 style={{ fontWeight: 500 }} className="card-title">
						Add an Artwork
					</h2>
					<div>
						<FormControl
							style={{
								width: "100%",
								paddingRight: "0px",
								marginBottom: "1em",
							}}>
							<Label
								id="contact-label"
								style={typeStyle}
								disableAnimation
								error={attempt && !createModal.art_id}
								shrink>
								Artwork
							</Label>
							<LimitedAutocomplete
								query={loadArt}
								setQuery={setLoadArt}
								style={{ marginTop: "1.75em" }}
								isOptionEqualToValue={(a, b) => a?.id === b.id}
								open={artOpen && !!options?.length}
								forcePopupIcon
								popupIcon={<ExpandMoreRoundedIcon />}
								onOpen={() => setArtOpen(true)}
								onClose={() => setArtOpen(false)}
								loading={loadingArt}
								options={options}
								classes={classes}
								getOptionDisabled={(option) => option?.disabled}
								getOptionLabel={(row) => {
									const artWork = options?.find((e) => e.id == row.id)

									if (!artWork) return ""

									const inventoryNumber = getArtInventoryNumber(artWork, false)

									if (artWork.title) {
										return `${artWork.title}${
											inventoryNumber ? ` (${inventoryNumber})` : ""
										}`
									} else if (artWork.artist) {
										return `${artWork.artist}${
											inventoryNumber ? ` (${inventoryNumber})` : ""
										}`
									} else {
										return inventoryNumber
									}
								}}
								renderOption={(optionProps, option) => {
									const artWork = options?.find((e) => e.id == option.id) || {}
									const hidden = artWork.is_private && !artWork.created_at
									const isPrivate = artWork.is_private

									const thumbnail = (
										<Thumbnail
											formatted_name={artWork.formatted_title}
											name={artWork.code_name}
											artist={getArtistThumbnailDetail(artWork)}
											date={artWork.year}
											avatar={artWork.imgUrl}
											type="art"
											animation={false}
											largeText
											darkText={!isPrivate && !props.privateEntity}
											hasAccess={!hidden}
										/>
									)
									if (option.disabled)
										return (
											<li
												{...optionProps}
												style={{
													display: "flex",
													flexDirection: "column",
													alignItems: "flex-start",
													backgroundColor: isPrivate ? "#2E3134" : null,
												}}>
												{thumbnail}
												<i style={{ color: isPrivate ? "white" : null }}>
													Already Included
												</i>
											</li>
										)

									return (
										<li
											{...optionProps}
											style={{ backgroundColor: isPrivate ? "#2E3134" : null }}>
											{thumbnail}
										</li>
									)
								}}
								renderInput={(params) => (
									<TextBoxThinForAutocomplete
										autoFocus
										{...params}
										error={attempt && !createModal.art_id}
										variant="outlined"
										InputProps={{
											...params.InputProps,
											endAdornment: (
												<>
													{loadingArt ? (
														<CircularProgress color="inherit" size={20} />
													) : null}
													{params.InputProps.endAdornment}
												</>
											),
										}}
									/>
								)}
								onChange={(event, value) => {
									setSelectedOption(value)
									if (value) {
										setOptions([value])
									} else {
										setOptions([])
									}
									setCreateModal({
										...createModal,
										art_id: value ? value.id : null,
										offer_amount: value ? value.primaryRetailPrice : 0,
										offer_currency: value ? value.primary_currency_id : "1",
									})
								}}
							/>
						</FormControl>

						<OfferPrice
							currency={createModal.offer_currency}
							price={createModal.offer_amount || 0}
							onChange={(field, value) =>
								setCreateModal({
									...createModal,
									[field]: value,
								})
							}
							currencies={lookup.data?.getCurrencies}
						/>
					</div>

					<>
						<CancelButton
							variant="contained"
							style={{ float: "left", marginTop: "2em" }}
							onClick={(e) => {
								e.preventDefault()
								setCreateModal(initialCreateModalState)
								setAttempt(false)
							}}>
							Cancel
						</CancelButton>

						<SubmitButton
							style={{ float: "right", marginTop: "2em" }}
							variant="contained"
							onClick={(e) => {
								setAttempt(true)

								if (attempt && !createModal.art_id) {
									openSnackbar(
										severity.WARNING,
										"Please complete the fields in red.",
									)
								} else if (
									showWarning &&
									createModal.art_id &&
									!createModal.offer_amount
								) {
									openSnackbar(severity.WARNING, "Offer price is zero.")

									setShowWarning(false)
								} else if (createModal.art_id) {
									const variables = {
										art_id: createModal.art_id,
										deal_id: props.deal.id,
										offer_amount: Number(createModal.offer_amount),
										offer_currency_id: Number(createModal.offer_currency),
									}

									// Send to server
									updateRelation({
										variables: {
											DealEntryRequest: variables,
										},
									})
										.then((response) => {
											if (response && response.data.upsertDealEntryV2.success) {
												openSnackbar(
													severity.SUCCESS,
													"Successfully added Artwork.",
												)
												setCurrentRow(null)
												setCreateModal(initialCreateModalState)
												setAttempt(false)

												// Requery
												handleSubmit(0)
												if (props.reloadDeal) props.reloadDeal()
												// Remove newly added art from options
												setOptions(
													options.filter((e) => e.id != createModal.art_id),
												)
											} else {
												throw response.errors
											}
										})
										.catch((error) => {
											console.error(error)
											openSnackbar(
												severity.ERROR,
												"There was an error adding this artwork.",
											)
										})
								}
							}}>
							Submit
						</SubmitButton>
					</>
				</div>
			</TransitionsModal>
		</>
	)
}

export default withApollo(DealEntryList)
