/* eslint-disable react/jsx-no-comment-textnodes */
import React from "react"
import IconButton from "@mui/material/IconButton"
import { useContext } from "react"
import { LookupContext } from "../../store"
import { formatDate } from "../../common/helpers"
import Zoom from "react-medium-image-zoom"
import Box from "@mui/material/Box"
import EditIcon from "@mui/icons-material/Edit"
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline"
import InfoOutlined from "@mui/icons-material/InfoOutlined"

export default function NoteListItem(props) {
	const lookup = useContext(LookupContext)
	const noteTypes = lookup.data?.getNoteTypes

	const isPrivate = props.note?.is_private

	return (
		<Box
			className={isPrivate ? "private-object private" : null}
			sx={{
				paddingLeft: isPrivate ? ".5em" : 0,
				paddingRight: isPrivate ? ".5em" : 0,
			}}>
			<p style={{ textAlign: "left", fontSize: "18px", marginBottom: "0px" }}>
				<span style={props.note.is_critical ? { color: "red" } : null}>
					{noteTypes &&
						noteTypes.find((item) => item.id === props.note.type_id).value}
				</span>
				<span style={{ float: "right", fontSize: "14px" }}>
					{props.note.applied_at
						? formatDate(
								props.note.applied_at,
								"iiii, MMMM do, yyyy 'at' h':'mma",
						  )
						: null}
				</span>
			</p>
			{!props.disableEdits ? (
				<p style={{ textAlign: "left", fontSize: "14px", marginTop: "4px" }}>
					{props.note.user?.first_name + " " + props.note.user?.last_name}
					{props.note.note_text?.trim()?.length ? (
						<span
							style={{ float: "right", fontSize: "18px", marginTop: "-4px" }}>
							<Box sx={{ display: "flex", flexDirection: "row" }}>
								<IconButton
									aria-label="Info"
									title="Info"
									onClick={(e) => {
										props.setCurrentNote(props.note)
										props.setInfoModal({ open: true })
									}}>
									<InfoOutlined />
								</IconButton>
								<IconButton
									aria-label="Edit"
									title="Edit"
									onClick={(e) => {
										props.setEditNoteModal({ note: props.note, open: true })
									}}>
									<EditIcon />
								</IconButton>
								<IconButton
									aria-label="Delete"
									title="Delete"
									onClick={(e) => props.deleteNote(props.note.id)}>
									<DeleteOutlineIcon />
								</IconButton>
							</Box>
						</span>
					) : null}
				</p>
			) : null}
			<p style={{ whiteSpace: "pre-line" }}>
				{props.note.note_text?.trim() ?? "Private Note"}
			</p>
			<div
				style={{
					display: "flex",
					overflowX: "auto",
					flexGrow: 2,
					alignItems: "center",
					paddingBottom: "0.5em",
				}}>
				{props.note.images?.map((image) => (
					<div key={image.id || image.key}>
						<Zoom
							overlayBgColorEnd="rgba(0, 0, 0, 0.5)"
							overlayBgColorStart="rgba(255, 255, 255, 0)">
							<img
								style={{
									maxHeight: "160px",
									marginRight: ".7em",
								}}
								src={image.imgUrl}
								alt={image.caption}
							/>
						</Zoom>
					</div>
				))}
			</div>
		</Box>
	)
}
