/* eslint-disable eqeqeq */
import { autoCompleteErrorStyles } from "../../../styles/makesStyles"
import {
	ChooseImageButton,
	CancelButton,
	SubmitButton,
} from "../../../input/Buttons"
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker"
import { DispatchContext, LookupContext } from "../../../store"
import {
	formatter,
	formatDate,
	shorten,
	readableBytes,
	returnDate,
} from "../../../common/helpers"
import {
	GET_ART_LOCATION_HISTORY,
	CREATE_LOCATION_HISTORY,
	DELETE_ART_LOCATION,
	UPDATE_ART_LOCATION,
	SIMPLE_SEARCH_LOCATION,
	SIMPLE_SEARCH_SUB_LOCATION,
	SIMPLE_SEARCH_VIA,
} from "./Queries"

import {
	Paper,
	TableContainer,
	Table,
	TableHead,
	TableCell,
	TableRow,
	TableBody,
	ListItem,
	ListItemText,
	FormControl,
	CircularProgress,
	MenuItem,
	Menu,
	IconButton,
	Select,
	Skeleton,
	Box,
} from "@mui/material"
import { severity } from "../../../Snackbar/CustomizedSnackbar"
import { Pagination } from "@mui/material"
import { useQuery, useMutation } from "@apollo/client"
import { withApollo } from "@apollo/client/react/hoc"
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded"
import Label from "../../../input/Label"
import MoreHorizIcon from "@mui/icons-material/MoreHoriz"
import NumberFormat from "react-number-format"
import React, { useCallback, useContext } from "react"
import TextBox from "../../../input/Text/TextBox"
import TransitionsModal from "../../../navigation/TransitionsModal/TransitionsModal"
import ConfirmationDialog from "../../../navigation/ConfirmationDialog/ConfirmationDialog"
import InfoCard from "../../../InfoCard/InfoCard"
import Dropdown from "../../../input/Dropdown/Dropdown"
import { getFileType } from "../../../Files/FileList"
import Thumbnail from "../../../Thumbnail/Thumbnail"
import UploadModal from "../../../Files/UploadModal"
import { GET_DOWNLOAD_LINK, GET_PREVIEW_LINK } from "../../../Files/Queries"
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward"
import TextBoxThinForAutocomplete from "../../../input/Text/TextBoxThinForAutocomplete"
import LimitedAutocomplete from "../../../common/LimitedAutocomplete"
import { useParams } from "react-router-dom"
import EditIcon from "@mui/icons-material/Edit"
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline"
import { InfoOutlined } from "@mui/icons-material"

const initialState = {
	mouseX: null,
	mouseY: null,
	editable: false,
}

const LocationHistory = (props) => {
	const classes = autoCompleteErrorStyles()
	const params = useParams()

	// Location History
	const [tempRows, setTempRows] = React.useState([])
	const [rows, setRows] = React.useState([])
	const [currentRow, setCurrentRow] = React.useState(null)
	const [currentFile, setCurrentFile] = React.useState(null)

	const [cursor, setCursor] = React.useState(0)
	const [activeStep, setActiveStep] = React.useState(null)
	const [totalItems, setTotalItems] = React.useState(0)
	const [steps, setSteps] = React.useState(0)

	const [locationModal, setLocationModal] = React.useState({ open: false })

	const [deleteRow, setDeleteRow] = React.useState(null)
	const [infoObject, setInfoObject] = React.useState(null)
	const [uploadModal, setUploadModal] = React.useState(false)

	const [anchorEl, setAnchorEl] = React.useState(null)
	const handleFileClick = (event) => setAnchorEl(event.currentTarget)
	const handleFileClose = () => setAnchorEl(null)
	const [editMode, setEditMode] = React.useState(false)

	const lookup = useContext(LookupContext)
	const currencies = lookup.data?.getCurrencies
	const itemsPerPage = 5

	const [createLocation, { loading: createLoading }] = useMutation(
		CREATE_LOCATION_HISTORY,
		{
			onError: (error) => {
				openSnackbar(severity.ERROR, "Could not create art location.")
				console.error(error)
			},
			onCompleted: (response) => {
				if (response.createArtLocation.success === true) {
					// Success
					openSnackbar(severity.SUCCESS, response.createArtLocation.message)

					refetch({
						variables: {
							id: props.art.id,
							cursor: 0,
							limit: itemsPerPage,
						},
					})
						.then(({ data }) => {
							const updatedRows = data.getArtLocationHistory.items
							props.setArt({
								...props.art,
								art_location: updatedRows,
							})
							setRows(updatedRows)
						})
						.catch((_) => {
							openSnackbar(
								severity.ERROR,
								"There was an error while refetching.",
							)
						})

					setAttempt(false)
					setLocationModal({
						open: false,
						input: {
							location_id: null,
							sub_location_id: null,
							via_id: null,
							location_change_at: new Date(),
							estimated_shipping_cost: null,
							final_shipping_cost: null,
							currency_id: 1,
							files: [],
						},
					})
				} else {
					// Error
					openSnackbar(severity.ERROR, response.createArtLocation.message)
				}
			},
		},
	)

	const [updateLocation, { loading: updateLoading }] = useMutation(
		UPDATE_ART_LOCATION,
		{
			onError: (error) => {
				console.error(error)
				openSnackbar(severity.ERROR, "Could not update art location.")
			},
			onCompleted: (response) => {
				if (response.updateArtLocation.success === true) {
					// Success
					openSnackbar(severity.SUCCESS, response.updateArtLocation.message)

					refetch({
						variables: {
							id: props.art.id,
							cursor: 0,
							limit: itemsPerPage,
						},
					})
						.then(({ data }) => {
							const updatedRows = data.getArtLocationHistory.items
							props.setArt({
								...props.art,
								art_location: updatedRows,
							})
							setRows(updatedRows)
						})
						.catch((_) => {
							openSnackbar(
								severity.ERROR,
								"There was an error while refetching.",
							)
						})

					setLocationModal({
						open: false,
						input: {
							...locationModal.input,
							files: response.updateArtLocation.ArtLocation.files,
						},
					})

					setAttempt(false)
				} else {
					// Error
					openSnackbar(severity.ERROR, response.updateArtLocation.message)
				}
			},
		},
	)

	const [deleteArtLocation] = useMutation(DELETE_ART_LOCATION, {
		onError: (error) => {
			console.error(error)
			openSnackbar(severity.ERROR, "Could not delete art location.")
		},
		onCompleted: (response) => {
			if (response.deleteArtLocation.success) {
				openSnackbar(severity.SUCCESS, response.deleteArtLocation.message)
				setRows(rows.filter((row) => row.id != currentRow?.id))
				setTempRows(tempRows.filter((row) => row.id != currentRow?.id))
				setDeleteRow(null)
			}

			refetch({
				variables: {
					id: props.art.id,
					cursor: 0,
					limit: itemsPerPage,
				},
			})
				.then(({ data }) => {
					const updatedRows = data.getArtLocationHistory.items
					props.setArt({
						...props.art,
						art_location: updatedRows,
					})
					setRows(updatedRows)
				})
				.catch((_) => {
					openSnackbar(severity.ERROR, "There was an error while refetching.")
				})

			setAttempt(false)
			setLocationModal({
				open: false,
				input: {
					location_id: null,
					sub_location_id: null,
					via_id: null,
					location_change_at: new Date(),
					estimated_shipping_cost: null,
					final_shipping_cost: null,
					currency_id: 1,
				},
			})
		},
	})

	const handleEditClick = (row) => {
		const {
			id,
			location_id,
			sub_location_id,
			via_id,
			location_change_at,
			estimated_shipping_cost,
			final_shipping_cost,
			currency_id,
			files,
		} = row

		setLocationModal({
			open: true,
			input: {
				id,
				location_id,
				sub_location_id,
				via_id,
				location_change_at: returnDate(location_change_at),
				estimated_shipping_cost,
				final_shipping_cost,
				currency_id,
				files,
			},
		})
	}

	const handleDeleteClick = (row) => {
		setDeleteRow(row?.id)
	}

	const handleInfoClick = (row) => {
		setInfoObject(row)
	}

	const handleSubmit = (cursor = 0) => {
		setCursor(cursor)
	}

	//Snackbar
	const dispatch = useContext(DispatchContext)
	const openSnackbar = useCallback(
		(severity, text) => {
			dispatch({ type: "openSnackBar", payload: { severity, text } })
		},
		[dispatch],
	)

	const [attempt, setAttempt] = React.useState(false)

	const { error, loading, data, refetch } = useQuery(GET_ART_LOCATION_HISTORY, {
		variables: {
			id: params.id,
			cursor,
			limit: itemsPerPage,
		},
		skip: !params?.id,
	})

	if (error) openSnackbar(severity.ERROR, error)

	React.useEffect(() => {
		setRows(data?.getArtLocationHistory?.items || [])
		const nItems = data?.getArtLocationHistory.totalItems

		if (nItems < 1 || !nItems) {
			setSteps(1)
		} else {
			setSteps(Math.ceil(nItems / itemsPerPage))
		}

		setTotalItems(nItems)

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data])

	const [locationOpen, setLocationOpen] = React.useState(false)
	const [locationOptions, setLocationOptions] = React.useState([])
	const [loadLocation, setLoadLocation] = React.useState(false)
	const locationLoading = locationOpen && !!loadLocation
	const [locationValue, setLocationValue] = React.useState(null)

	const [subLocationOpen, setSubLocationOpen] = React.useState(false)
	const [subLocationOptions, setSubLocationOptions] = React.useState([])
	const [loadSubLocation, setLoadSubLocation] = React.useState(false)
	const subLocationLoading = subLocationOpen && !!loadSubLocation
	const [subLocationValue, setSubLocationValue] = React.useState(null)

	const [viaOpen, setViaOpen] = React.useState(false)
	const [viaOptions, setViaOptions] = React.useState([])
	const [loadVia, setLoadVia] = React.useState(false)
	const viaLoading = viaOpen && !!loadVia
	const [viaValue, setViaValue] = React.useState(null)

	React.useEffect(() => {
		if (currentRow) {
			setLocationOptions([currentRow.location])
			setLocationValue(currentRow.location)
			setSubLocationOptions(
				currentRow.sub_location ? [currentRow.sub_location] : [],
			)
			setSubLocationValue(
				currentRow.sub_location ? currentRow.sub_location : null,
			)
			setViaOptions(currentRow.via ? [currentRow.via] : [])
			setViaValue(currentRow.via ? currentRow.via : null)
		}
	}, [currentRow])

	React.useEffect(() => {
		let active = true

		if (!locationLoading) {
			return undefined
		}

		props.client
			.query({
				query: SIMPLE_SEARCH_LOCATION,
				variables: { query: loadLocation },
			})
			.then((result) => {
				if (active) {
					setLoadLocation(false)
					setLocationOptions(result.data?.simpleSearchLocation || [])
				}
			})

		return () => {
			active = false
		}
	}, [loadLocation, locationLoading, props.client])

	React.useEffect(() => {
		let active = true

		if (!subLocationLoading) {
			return undefined
		}

		props.client
			.query({
				query: SIMPLE_SEARCH_SUB_LOCATION,
				variables: { query: loadSubLocation },
			})
			.then((result) => {
				if (active) {
					setLoadSubLocation(false)
					setSubLocationOptions(result.data?.simpleSearchSubLocation || [])
				}
			})

		return () => {
			active = false
		}
	}, [loadSubLocation, subLocationLoading, props.client])

	React.useEffect(() => {
		let active = true

		if (!viaLoading) {
			return undefined
		}

		props.client
			.query({
				query: SIMPLE_SEARCH_VIA,
				variables: { query: loadVia },
			})
			.then((result) => {
				if (active) {
					setLoadVia(false)
					setViaOptions(result.data?.simpleSearchVia || [])
				}
			})

		return () => {
			active = false
		}
	}, [loadVia, viaLoading, props.client])

	return (
		<>
			<Paper
				className="qv-margin"
				id="location-history"
				data-testid="card-location-history">
				<h1 className="card-title">
					<span>Location History {totalItems ? `(${totalItems})` : ""}</span>
					<>
						<div className="spacer"></div>
						<Pagination
							style={{
								paddingRight: "1em",
							}}
							size="small"
							count={steps}
							siblingCount={0}
							page={activeStep + 1}
							onChange={(event, page) => {
								setActiveStep(page - 1)
								handleSubmit((page - 1) * itemsPerPage)
							}}></Pagination>
						<ChooseImageButton
							variant="contained"
							color="secondary"
							size="small"
							onClick={() => {
								setCurrentRow(null)
								setLocationModal({
									open: true,
									input: {
										location_id: null,
										sub_location_id: null,
										via_id: null,
										location_change_at: new Date(),
										estimated_shipping_cost: null,
										final_shipping_cost: null,
										currency_id: 1,
										files: [],
									},
								})
							}}>
							Add
						</ChooseImageButton>
					</>
				</h1>

				<TableContainer
					style={{
						overflowX: "auto",
						height: "90%",
					}}>
					<Table>
						<TableHead>
							<TableRow>
								<TableCell>Location &amp; Sub-Location</TableCell>
								<TableCell>Via</TableCell>
								<TableCell>
									Date
									<IconButton size="small" disabled>
										<ArrowDownwardIcon
											style={{ fontSize: "1rem", marginBottom: ".2em" }}
										/>
									</IconButton>
								</TableCell>
								<TableCell>Estimated Shipping Costs</TableCell>
								<TableCell>Final Shipping Costs</TableCell>
								<TableCell>Associated Files</TableCell>
								<TableCell></TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{rows?.length
								? rows.map((row) => {
										return (
											<TableRow key={`history-${row.id}`}>
												<TableCell>
													<ListItem style={{ padding: "0" }}>
														<ListItemText
															primary={row.location?.name}
															secondary={row.sub_location?.name}
														/>
													</ListItem>
												</TableCell>
												<TableCell>{row.via?.name || "-"}</TableCell>
												<TableCell>
													{row.location_change_at
														? formatDate(
																row.location_change_at,
																"MMMM do, yyyy",
														  )
														: "-"}
												</TableCell>
												<TableCell>
													{row.estimated_shipping_cost
														? `${formatter(row.currency.label || "USD").format(
																row.estimated_shipping_cost,
														  )}`
														: "-"}
												</TableCell>
												<TableCell>
													{row.final_shipping_cost
														? `${formatter(row.currency.label || "USD").format(
																row.final_shipping_cost,
														  )}`
														: "-"}
												</TableCell>
												<TableCell>{row.files?.length || 0}</TableCell>
												<TableCell>
													<Box
														sx={{
															display: "flex",
															justifyContent: "flex-end",
														}}>
														<IconButton
															title="View/Edit"
															aria-label="View/Edit"
															style={{
																padding: "6px",
															}}
															onClick={(e) => {
																handleEditClick(row)
															}}>
															<EditIcon />
														</IconButton>
														<IconButton
															title="Delete"
															aria-label="Delete"
															style={{
																padding: "6px",
															}}
															onClick={(e) => {
																handleDeleteClick(row)
															}}>
															<DeleteOutlineIcon />
														</IconButton>
														<IconButton
															title="Info"
															aria-label="Info"
															style={{
																padding: "6px",
															}}
															onClick={(e) => {
																handleInfoClick(row)
															}}>
															<InfoOutlined />
														</IconButton>
													</Box>
												</TableCell>
											</TableRow>
										)
								  })
								: null}

							{loading ? (
								<TableRow key="placeholder">
									<TableCell>
										<Skeleton animation="wave" />
									</TableCell>
									<TableCell>
										<Skeleton animation="wave" />
									</TableCell>
									<TableCell>
										<Skeleton animation="wave" />
									</TableCell>
									<TableCell>
										<Skeleton animation="wave" />
									</TableCell>
									<TableCell>
										<Skeleton animation="wave" />
									</TableCell>
									<TableCell>
										<Skeleton animation="wave" />
									</TableCell>
									<TableCell></TableCell>
								</TableRow>
							) : null}

							{!loading && data && rows?.length === 0 ? (
								<TableRow key="placeholder">
									<TableCell>No Location History</TableCell>
									<TableCell></TableCell>
									<TableCell></TableCell>
									<TableCell></TableCell>
									<TableCell></TableCell>
									<TableCell></TableCell>
									<TableCell></TableCell>
								</TableRow>
							) : null}
						</TableBody>
					</Table>
				</TableContainer>
			</Paper>

			<TransitionsModal
				className="location-modal"
				open={locationModal.open}
				close={() => {
					setAttempt(false)
					setLocationModal({ ...locationModal, open: false })
					setCurrentRow(null)
				}}>
				<div
					style={{ minWidth: "70em" }}
					className={props.art?.is_private ? "private-object" : null}>
					<h1 className="card-title">
						<span>{currentRow ? "Edit " : "New "}Location</span>
						<>
							<div className="spacer"></div>
							<CancelButton
								variant="contained"
								style={{ float: "left" }}
								onClick={() => {
									setAttempt(false)
									setLocationModal({
										open: false,
										input: {
											location_id: null,
											sub_location_id: null,
											via_id: null,
											location_change_at: new Date(),
											estimated_shipping_cost: null,
											final_shipping_cost: null,
											currency_id: 1,
											files: [],
										},
									})
								}}>
								Cancel
							</CancelButton>

							<SubmitButton
								style={{
									float: "right",
									marginLeft: "1em",
								}}
								type="submit"
								variant="contained"
								disabled={updateLoading || createLoading}
								onClick={() => {
									setAttempt(true)

									// Throw warning when no location is supplied
									if (
										attempt &&
										!(
											locationModal.input.location_id ||
											locationModal.input.location
										)
									) {
										openSnackbar(
											severity.WARNING,
											"Please correct field in red.",
										)
									}

									// Throw warning when location is supplied, and sub location is too long
									if (
										attempt &&
										(locationModal.input.location_id ||
											locationModal.input.location) &&
										locationModal.input.sub_location?.length > 100
									) {
										openSnackbar(
											severity.WARNING,
											"Please supply a sub-location with length less than 100.",
										)
									}

									// Submit when location is supplied, and either sub location is either passed and less than 100 chars or not passed at all
									if (
										(locationModal.input.location_id ||
											locationModal.input.location) &&
										((locationModal.input.sub_location &&
											locationModal.input.sub_location?.length <= 100) ||
											!locationModal.input.sub_location)
									) {
										const est_shipping =
											locationModal.input?.estimated_shipping_cost
										const final_shipping =
											locationModal.input?.final_shipping_cost

										const variables = {
											...locationModal.input,
											location_change_at:
												locationModal.input.location_change_at &&
												formatDate(
													locationModal.input.location_change_at,
													"yyyy-MM-dd",
												),
											art_id: props.art.id,
											estimated_shipping_cost: est_shipping
												? Number(est_shipping)
												: null,
											final_shipping_cost: final_shipping
												? Number(final_shipping)
												: null,
											files: (locationModal.input.files || []).map((file) => {
												const {
													file_group_id,
													user,
													fileCategory,
													__typename,
													fileGroup,
													versionHistory,
													type,
													userName,
													fileObject,
													created_at,
													created_by,
													modified_at,
													modified_by,
													...rest
												} = file

												return { ...rest, version: 1 }
											}),
										}

										if (currentRow) {
											// Update
											updateLocation({
												variables: {
													UpdateArtLocationInput: variables,
												},
											})
										} else {
											// Create
											createLocation({
												variables: {
													CreateArtLocationInput: variables,
												},
											})
										}
									}
								}}>
								Save
							</SubmitButton>
						</>
					</h1>

					<div className="row" style={{ justifyContent: "left" }}>
						<FormControl style={{ width: "20em" }}>
							<Label
								id="location-label"
								style={{ fontWeight: 500 }}
								disableAnimation
								error={
									attempt &&
									!locationModal.input?.location_id &&
									!locationModal.input?.location
								}
								shrink>
								Location*
							</Label>
							<LimitedAutocomplete
								query={loadLocation}
								setQuery={setLoadLocation}
								freeSolo
								id="location-autocomplete"
								classes={classes}
								open={locationOpen}
								onOpen={() => {
									setLocationOpen(true)
								}}
								onClose={() => {
									setLocationOpen(false)
								}}
								style={{ marginTop: "1.5em" }}
								isOptionEqualToValue={(option, value) => option.id == value.id}
								getOptionLabel={(option) => option?.name || option || ""}
								options={locationOptions}
								value={currentRow ? locationValue : null}
								forcePopupIcon
								filterSelectedOptions
								popupIcon={<ExpandMoreRoundedIcon />}
								loading={locationLoading}
								onInputChange={(event, value) => {
									if (!value) {
										setLocationModal({
											...locationModal,
											input: {
												...locationModal.input,
												location_id: null,
												location: null,
											},
										})
									}
								}}
								onChange={(event, value) => {
									if (typeof value === "string") {
										setLocationModal({
											...locationModal,
											input: {
												...locationModal.input,
												location_id: null,
												location: value,
											},
										})
									} else
										setLocationModal({
											...locationModal,
											input: {
												...locationModal.input,
												location_id: value?.id || null,
												location: null,
											},
										})
								}}
								renderInput={(params) => (
									<TextBoxThinForAutocomplete
										{...params}
										onChange={(event) => {
											setLocationModal({
												...locationModal,
												input: {
													...locationModal.input,
													location: event.target.value,
													location_id: null,
												},
											})
										}}
										variant="outlined"
										error={
											attempt &&
											!locationModal.input?.location_id &&
											!locationModal.input?.location
										}
										InputProps={{
											...params.InputProps,
											endAdornment: (
												<React.Fragment>
													{locationLoading ? (
														<CircularProgress color="inherit" size={20} />
													) : null}
													{params.InputProps.endAdornment}
												</React.Fragment>
											),
										}}
									/>
								)}
							/>
						</FormControl>
						<FormControl style={{ width: "20em", marginLeft: "1em" }}>
							<Label
								id="sub-location-label"
								style={{ fontWeight: 500 }}
								disableAnimation
								shrink
								error={
									attempt && locationModal.input.sub_location?.length > 100
								}>
								Sub-Location
							</Label>
							<LimitedAutocomplete
								query={loadSubLocation}
								setQuery={setLoadSubLocation}
								freeSolo
								id="sub-location-autocomplete"
								classes={classes}
								open={subLocationOpen}
								onOpen={() => {
									setSubLocationOpen(true)
								}}
								onClose={() => {
									setSubLocationOpen(false)
								}}
								style={{ marginTop: "1.5em" }}
								isOptionEqualToValue={(option, value) => option.id == value.id}
								getOptionLabel={(option) => option.name || option}
								options={subLocationOptions}
								value={currentRow ? subLocationValue : null}
								forcePopupIcon
								filterSelectedOptions
								popupIcon={<ExpandMoreRoundedIcon />}
								loading={subLocationLoading}
								onInputChange={(event, value) => {
									if (!value) {
										setLocationModal({
											...locationModal,
											input: {
												...locationModal.input,
												sub_location_id: null,
												sub_location: null,
											},
										})
									}
								}}
								onChange={(event, value) => {
									if (typeof value === "string") {
										setLocationModal({
											...locationModal,
											input: {
												...locationModal.input,
												sub_location: value,
												sub_location_id: null,
											},
										})
									} else
										setLocationModal({
											...locationModal,
											input: {
												...locationModal.input,
												sub_location: null,
												sub_location_id: value?.id || null,
											},
										})
								}}
								renderInput={(params) => (
									<TextBoxThinForAutocomplete
										{...params}
										error={
											attempt && locationModal.input.sub_location?.length > 100
										}
										onChange={(event) => {
											setLocationModal({
												...locationModal,
												input: {
													...locationModal.input,
													sub_location_id: null,
													sub_location: event.target.value,
												},
											})
										}}
										variant="outlined"
										InputProps={{
											...params.InputProps,
											endAdornment: (
												<React.Fragment>
													{subLocationLoading ? (
														<CircularProgress color="inherit" size={20} />
													) : null}
													{params.InputProps.endAdornment}
												</React.Fragment>
											),
										}}
									/>
								)}
							/>
						</FormControl>
						<FormControl style={{ width: "20em", marginLeft: "1em" }}>
							<Label
								id="via-label"
								style={{ fontWeight: 500 }}
								disableAnimation
								shrink>
								Via
							</Label>
							<LimitedAutocomplete
								query={loadVia}
								setQuery={setLoadVia}
								freeSolo
								id="via-autocomplete"
								classes={classes}
								open={viaOpen}
								onOpen={() => {
									setViaOpen(true)
								}}
								onClose={() => {
									setViaOpen(false)
								}}
								style={{ marginTop: "1.5em" }}
								isOptionEqualToValue={(option, value) => option.id == value.id}
								getOptionLabel={(option) => option.name || option}
								options={viaOptions}
								value={currentRow ? viaValue : null}
								forcePopupIcon
								filterSelectedOptions
								popupIcon={<ExpandMoreRoundedIcon />}
								loading={viaLoading}
								onInputChange={(event, value) => {
									if (!value) {
										setLocationModal({
											...locationModal,
											input: {
												...locationModal.input,
												via_id: null,
												via: null,
											},
										})
									}
								}}
								onChange={(event, value) => {
									if (typeof value === "string") {
										setLocationModal({
											...locationModal,
											input: {
												...locationModal.input,
												via: value,
												via_id: null,
											},
										})
									} else
										setLocationModal({
											...locationModal,
											input: {
												...locationModal.input,
												via: null,
												via_id: value?.id || null,
											},
										})
								}}
								renderInput={(params) => (
									<TextBoxThinForAutocomplete
										{...params}
										onChange={(event) => {
											setLocationModal({
												...locationModal,
												input: {
													...locationModal.input,
													via_id: null,
													via: event.target.value,
												},
											})
										}}
										variant="outlined"
										InputProps={{
											...params.InputProps,
											endAdornment: (
												<React.Fragment>
													{viaLoading ? (
														<CircularProgress color="inherit" size={20} />
													) : null}
													{params.InputProps.endAdornment}
												</React.Fragment>
											),
										}}
									/>
								)}
							/>
						</FormControl>

						<FormControl style={{ marginLeft: "1em", width: "20em" }}>
							<Label
								id="date-label"
								style={{
									fontWeight: 500,
								}}
								disableAnimation
								shrink>
								Date
							</Label>

							<MobileDatePicker
								inputFormat="MMM do, yyyy"
								disableMaskedInput={true}
								cancelText={null}
								name="date"
								inputVariant="outlined"
								style={{ marginTop: "17px" }}
								className="MUIDatePicker"
								variant="dialog"
								showTodayButton
								todayLabel="Today"
								value={locationModal.input?.location_change_at || null}
								renderInput={({ inputRef, inputProps, InputProps }) => {
									const newProps = { ...inputProps }

									newProps.readOnly = false

									return (
										<TextBox
											ref={inputRef}
											endAdornment={InputProps?.endAdornment}
											{...newProps}
										/>
									)
								}}
								onChange={(date) => {
									setLocationModal({
										...locationModal,
										input: {
											...locationModal.input,
											location_change_at: date,
										},
									})
								}}
							/>
						</FormControl>
					</div>

					<div>
						<FormControl style={{ width: "20em" }}>
							<Label id="currency-label" disableAnimation shrink>
								Currency
							</Label>

							<Select
								className="padded-select"
								IconComponent={ExpandMoreRoundedIcon}
								name="currency"
								labelId="currency-label"
								input={<Dropdown />}
								value={locationModal.input?.currency_id || ""}
								onChange={(e) => {
									setLocationModal({
										...locationModal,
										input: {
											...locationModal.input,
											currency_id: e.target.value,
										},
									})
								}}>
								{currencies?.map((currency) => (
									<MenuItem key={currency.id} value={currency.id}>
										{`${currency.symbol} - ${currency.name}`}
									</MenuItem>
								))}
							</Select>
						</FormControl>

						<FormControl style={{ marginLeft: "1em", width: "20em" }}>
							<Label
								id="est-cost-label"
								style={{ fontWeight: 500 }}
								disableAnimation
								shrink>
								Estimated Shipping Costs
							</Label>
							<NumberFormat
								id="est-cost"
								customInput={TextBox}
								value={locationModal.input?.estimated_shipping_cost || ""}
								prefix={
									currencies?.find(
										(e) => e.id == locationModal.input?.currency_id,
									)?.symbol || "$"
								}
								placeholder="0"
								thousandSeparator
								allowNegative={false}
								onChange={(e) => {
									setLocationModal({
										...locationModal,
										input: {
											...locationModal.input,
											estimated_shipping_cost: e.target.value.replace(
												/\D/g,
												"",
											),
										},
									})
								}}
								allowLeadingZeros={false}
							/>
						</FormControl>

						<FormControl style={{ marginLeft: "1em", width: "20em" }}>
							<Label
								id="final-cost-label"
								style={{ fontWeight: 500 }}
								disableAnimation
								shrink>
								Final Shipping Costs
							</Label>
							<NumberFormat
								id="final-cost"
								customInput={TextBox}
								value={locationModal.input?.final_shipping_cost || ""}
								prefix={
									currencies?.find(
										(e) => e.id == locationModal.input?.currency_id,
									)?.symbol || "$"
								}
								placeholder="0"
								allowNegative={false}
								thousandSeparator
								onChange={(e) => {
									setLocationModal({
										...locationModal,
										input: {
											...locationModal.input,
											final_shipping_cost: e.target.value.replace(/\D/g, ""),
										},
									})
								}}
								allowLeadingZeros={false}
							/>
						</FormControl>

						<div
							style={{
								display: "flex",
								marginTop: "2em",
								justifyContent: "space-between",
							}}>
							<h1 className="card-title">
								Associated Files
								{locationModal.input?.files?.filter((e) => !e.delete)?.length
									? ` (${
											locationModal.input?.files?.filter((e) => !e.delete)
												?.length
									  })`
									: ""}
							</h1>

							<ChooseImageButton
								style={{ height: "36px", marginLeft: "1em" }}
								color="primary"
								variant="contained"
								onClick={() => {
									setUploadModal(true)
								}}>
								Add a File
							</ChooseImageButton>
						</div>

						<TableContainer>
							<Table>
								<TableHead>
									<TableRow>
										<TableCell></TableCell>
										<TableCell align="left">Type</TableCell>
										<TableCell align="left">
											Filename &amp; Description
										</TableCell>
										<TableCell align="left">
											Uploaded Date
											<IconButton size="small" disabled>
												<ArrowDownwardIcon
													style={{ fontSize: "1rem", marginBottom: ".2em" }}
												/>
											</IconButton>
										</TableCell>
										<TableCell align="left">Size</TableCell>
										<TableCell align="left">User</TableCell>
										<TableCell align="left"></TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{locationModal.input?.files
										?.filter((e) => !e.delete)
										.sort((a, b) => b?.created_at - a?.created_at)
										?.map((row) => (
											<TableRow key={row.id || row.filename}>
												<TableCell>{getFileType(row.filetype)}</TableCell>
												<TableCell>
													{typeof row.fileCategory === "string"
														? row.fileCategory
														: row.fileCategory?.value}
												</TableCell>
												<TableCell
													style={{ maxWidth: "50em", overflowX: "scroll" }}>
													<ListItem style={{ padding: "0" }}>
														<ListItemText
															primary={row.filename}
															secondary={shorten(row.description, 240)}
														/>
													</ListItem>
												</TableCell>
												<TableCell style={{ minWidth: "10em" }}>
													{formatDate(row.created_at, "MMM d, yyyy")}
												</TableCell>
												<TableCell>{readableBytes(row.filesize)}</TableCell>
												<TableCell style={{ minWidth: "15em" }}>
													<Thumbnail
														name={
															row.user?.first_name + " " + row.user?.last_name
														}
														detail={row.user?.gallery}
														avatar={row.user?.imgUrl}
														type="contact"></Thumbnail>
												</TableCell>
												<TableCell>
													<IconButton
														aria-label="More"
														style={{
															padding: "6px",
															marginRight: "-9px",
														}}
														onClick={(e) => {
															e.preventDefault()
															e.stopPropagation()
															setCurrentFile({
																...row,
																file_category_id: row.fileCategory.id,
															})
															handleFileClick(e)
														}}
														size="large">
														<MoreHorizIcon />
													</IconButton>
												</TableCell>
											</TableRow>
										))}
									{locationModal.input?.files?.filter((e) => !e.delete)
										?.length === 0 ? (
										<TableRow>
											<TableCell />
											<TableCell>No Files</TableCell>
											<TableCell />
											<TableCell />
											<TableCell />
											<TableCell />
											<TableCell />
										</TableRow>
									) : null}
								</TableBody>
							</Table>
						</TableContainer>
					</div>
				</div>

				<Menu
					id="simple-menu"
					anchorEl={anchorEl}
					keepMounted
					open={Boolean(anchorEl)}
					onClose={handleFileClose}>
					{currentRow && currentFile?.id ? (
						<MenuItem
							onClick={(e) => {
								props.client
									.query({
										query: GET_PREVIEW_LINK,
										variables: {
											id: currentFile.id,
										},
									})
									.then((result) => {
										if (result.data.getPreviewLink)
											window.open(result.data.getPreviewLink, "_blank")
										else openSnackbar(severity.ERROR, "Error - Unauthorized")
									})
									.catch((error) => {
										openSnackbar(severity.ERROR, "Could not preview file")
									})

								handleFileClose()
							}}>
							Open
						</MenuItem>
					) : null}

					{currentRow && currentFile?.id ? (
						<MenuItem
							onClick={(e) => {
								props.client
									.query({
										query: GET_DOWNLOAD_LINK,
										variables: {
											id: currentFile.id,
										},
									})
									.then((result) => {
										if (result.data.getDownloadLink)
											window.location.href = result.data.getDownloadLink
										else openSnackbar(severity.ERROR, "Error - Unauthorized")
									})
									.catch((error) => {
										openSnackbar(severity.ERROR, "Could not download file.")
									})

								handleFileClose()
							}}>
							Download
						</MenuItem>
					) : null}

					{currentRow && currentFile?.id ? (
						<MenuItem
							onClick={(e) => {
								setEditMode(true)
								handleFileClose()
							}}>
							Edit File
						</MenuItem>
					) : null}

					{currentRow && currentFile?.id ? (
						<MenuItem
							onClick={(e) => {
								setInfoObject(currentFile)
								handleFileClose()
							}}>
							Information
						</MenuItem>
					) : null}

					<MenuItem
						onClick={(e) => {
							handleFileClose()

							if (currentRow && currentFile?.id) {
								// new location
								setLocationModal({
									open: true,
									input: {
										...locationModal.input,
										files: locationModal.input.files.map((e) => {
											if (e.key == currentFile.key) {
												return {
													...e,
													delete: true,
												}
											} else return e
										}),
									},
								})
							} else {
								// new location
								setLocationModal({
									open: true,
									input: {
										...locationModal.input,
										files: locationModal.input.files.filter(
											(e) => e.key != currentFile.key,
										),
									},
								})
							}
						}}>
						{currentRow && currentFile?.id ? "Delete" : "Remove"}
					</MenuItem>
				</Menu>
			</TransitionsModal>
			<InfoCard
				open={!!infoObject}
				object={infoObject}
				setInfoModal={() => setInfoObject(null)}
				allAttributes
			/>

			<UploadModal
				titleOverride="art location"
				open={uploadModal || editMode}
				setOpen={() => {
					setUploadModal(false)
					setEditMode(false)
					setCurrentFile(null)
				}}
				object="art"
				objectId={props.art.id}
				atPrivateObject={props.atPrivateObject}
				fileObjectId={6}
				fileId={currentFile?.id}
				editMode={editMode}
				fileInput={currentFile}
				locationModal={locationModal}
				setLocationModal={setLocationModal}
				files={currentRow?.files || []}
				setFiles={(files) =>
					setLocationModal({
						...locationModal,
						input: {
							...locationModal.input,
							files: files,
						},
					})
				}
			/>

			<ConfirmationDialog
				open={!!deleteRow}
				handleClose={() => setDeleteRow(null)}
				title={"Are you sure?"}
				acceptText={"Delete"}
				text={
					"This will delete all associated files, as well as this location history entry."
				}
				onYes={() => {
					deleteArtLocation({
						variables: {
							id: deleteRow,
						},
					})
				}}
			/>
		</>
	)
}

export default withApollo(LocationHistory)
