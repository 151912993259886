/* eslint-disable eqeqeq */
import React, { useContext, useCallback } from 'react'
import { DispatchContext } from '../../store'
import TextBox from '../../input/Text/TextBox'
import Label from '../../input/Label'
import {
	FormControl,
	Select,
	MenuItem,
	Divider,
	Button,
} from '@mui/material'
import Dropdown from '../../input/Dropdown/Dropdown'
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded'
import { ChooseImageButton } from '../../input/Buttons'
import { severity } from '../../Snackbar/CustomizedSnackbar'
import DeleteIcon from '@mui/icons-material/Delete'
import { CancelButton } from '../../input/Buttons'
import NumberFormat from 'react-number-format'
import { getAreaImperial, getAreaMetric, getInchFractional, getInchRoot, getVolumeImperial, getVolumeMetric, roundTo10th, selectionOptions } from './DimensionSelection'

/* ======================================================================
	Styles
	===================================================================== */

const labelStyle = {
	fontWeight: 500,
}

const sideBySide = {
	display: 'flex',
	flexDirection: 'inherit',
	alignItems: 'center'
}

const paddingLeft = {
	paddingLeft: '5px',
	marginTop: '0px',
}

const spanStyle = {
	paddingRight: 5
}

const textBoxStyle = { marginTop: '0px', width: '100%' }

export default function CrateDimension(props) {

	//Snackbar
	const dispatch = useContext(DispatchContext)
	const openSnackbar = useCallback(
		(severity, text) => {
			dispatch({ type: 'openSnackBar', payload: { severity, text } })
		},
		[dispatch]
	)

	const [currentDimension, setCurrentDimension] = React.useState(null)

	const { artInput, setDimensionModal } = props
	const onClose = useCallback(() =>  {
		setDimensionModal(false)
	}, [setDimensionModal])

	const handleKeyDown = useCallback((e) => {
		if (e.target?.name == 'description') return
		if (e.key !== 'Enter') return
		e.stopPropagation()
		onClose()
	}, [onClose])

	const {
		crate_depth_metric,
		crate_description,
		crate_width_metric,
		crate_height_metric,
	} = artInput

	// Imperial
	const depthInt = getInchRoot(crate_depth_metric)
	const depthFraction = getInchFractional(
		crate_depth_metric
	)

	const widthInt = getInchRoot(crate_width_metric)
	const widthFraction = getInchFractional(crate_width_metric)

	const heightInt = getInchRoot(crate_height_metric)
	const heightFraction = getInchFractional(
		crate_height_metric
	)

	const areaImperial = getAreaImperial(
		heightInt,
		heightFraction,
		widthInt,
		widthFraction
	)
	const volumeImperial = getVolumeImperial(
		depthInt,
		depthFraction,
		widthInt,
		widthFraction,
		heightInt,
		heightFraction
	)

	// Metric
	const areaMetric = getAreaMetric(crate_height_metric, crate_width_metric)
	const volumeMetric = getVolumeMetric(
		crate_depth_metric,
		crate_width_metric,
		crate_height_metric
	)

	const handleChange = (e) => {
		let value = e.target.value
		if (e.target.name != 'crate_description') {
			if (value == '') value = null
			else value = Number(value)
		}

		props.setArtInput({
			...props.artInput,
			[e.target.name]: value
		})
	}

	const handleIntChange = (e) => {

		let value = e.target.value
		let decimal
		let dist

		if (value == '') {
			value = null
			decimal = null
			dist = null
		} else {
			value = Number(value)

			switch (e.target.name) {
			case 'depth':
				decimal = depthFraction?.decimal ?? 0
				break

			case 'width':
				decimal = widthFraction?.decimal ?? 0
				break

			case 'height':
				decimal = heightFraction?.decimal ?? 0
				break

			default:
				break
			}

			if (decimal)
				dist = (value + decimal) * 2.54
			else
				dist = value * 2.54
		}

		props.setArtInput({
			...props.artInput,
			[`crate_${e.target.name}_metric`]: dist
		})
	}

	const handleDecimalChange = (e) => {

		let integer
		switch (e.target.name) {
		case 'depth':
			integer = depthInt
			break

		case 'width':
			integer = widthInt
			break

		case 'height':
			integer = heightInt
			break

		default:
			break
		}

		let dist
		if (integer)
			dist = (integer + e.target.value.decimal) * 2.54
		else dist = (e.target.value.decimal) * 2.54

		props.setArtInput({
			...props.artInput,
			[`crate_${e.target.name}_metric`]: dist
		})
	}

	return (
		<div
			style={{
				width: '70em',
				maxHeight: '90vh'
			}}
			className={props.art?.is_private ? 'private-object' : null}
		>
			<div>

				<h1 className="card-title">Crate Dimension

					<div className="spacer"></div>

					<Button
						variant="contained"
						startIcon={<DeleteIcon />}
						style={{
							backgroundColor: '#CC3333',
							color: 'white',
							visibility: currentDimension == null ? 'hidden' : 'visible',
						}}
						onClick={() => {
							setCurrentDimension(null)
						}}
						data-testid="dimension-delete"
					>
						Remove
					</Button>
					<CancelButton
						variant="contained"
						onClick={onClose}
						style={{ display: 'flex', marginLeft: '1em', padding: '.2em'}}
					>Close
					</CancelButton>
				</h1>



				<Divider direction="horizontal" />
			</div>
			<div 
				style={{
					overflow: 'scroll',
					maxHeight: '80vh'
				}}
				onKeyDown={handleKeyDown}
			>
				<div>
					<div style={{ display: 'flex', alignItems: 'center' }}>
						<div>
							{ !props.findMode && <div style={{ display: 'flex' }}>
								<FormControl sx={{width: '100%'}}>
									<Label
										error={props.attempt && crate_description.length === 0}
										style={labelStyle}
										disableAnimation
										shrink
									>
										Label*
									</Label>
									<TextBox
										multiline
										required
										error={props.attempt && crate_description.length === 0}
										name="crate_description"
										value={crate_description}
										onChange={handleChange}
										data-testid={`dimension-label`}
									/>
								</FormControl>

								<ChooseImageButton
									style={{
										marginLeft: '1.3em',
										height: '41px',
										marginTop: '1.7em',
									}}
									onClick={() => {


										if (!heightInt && !widthInt && !depthInt) {

											openSnackbar(severity.WARNING, "Invalid dimensions supplied.")
											props.setArtInput({
												...props.artInput,
												crate_description: '',
											})
											return
										}

										let description = ''

										if (heightInt)
											description += `${heightInt}`

										if (heightFraction)
											description += `${heightFraction.label}`

										if ((heightInt || heightFraction) && (widthInt || widthFraction))
											description += ' x '

										if (widthInt)
											description += `${widthInt}`

										if (widthFraction)
											description += `${widthFraction.label}`

										if ((widthInt || widthFraction) && (depthInt || depthFraction))
											description += ' x '

										if (depthInt)
											description += `${depthInt}`

										if (depthFraction)
											description += `${depthFraction.label}`

										description += ' inches\n('

										if (crate_height_metric)
											description += `${roundTo10th(
												crate_height_metric
											)}`

										if (crate_width_metric && crate_height_metric)
											description += ` x ${roundTo10th(
												crate_width_metric
											)}`
										else if (crate_width_metric && !crate_height_metric)
											description += `${roundTo10th(
												crate_width_metric
											)}`

										if (crate_depth_metric && crate_width_metric)
											description += ` x ${roundTo10th(
												crate_depth_metric
											)}`
										else if (crate_depth_metric && !crate_width_metric)
											description += `${roundTo10th(
												crate_depth_metric
											)}`

										description += ' cm)'

										props.setArtInput({
											...props.artInput,
											crate_description: description,
										})
									}}
									data-testid={`dimension-generate`}
								>
									Generate
								</ChooseImageButton>
							</div>}

							<div
								className="row"
								style={{padding: 5}}
							>
								<div className="column">
									<Label
										style={{
											...labelStyle,
											marginBottom: -14,
											marginLeft: 33
										}}
										disableAnimation
										shrink
									>
										Height
									</Label>
									<div className="row"  style={{padding: 5}}>
										<div style={sideBySide}>
											<span style={spanStyle}> in. </span>
											<NumberFormat
												style={{
													...textBoxStyle,
													marginLeft: 5,
												}}
												name="height"
												step="any"
												value={heightInt ?? ''}
												onChange={handleIntChange}
												data-testid={`dimension-height-inch`}
												customInput={TextBox}
												decimalSeparator=''
												allowLeadingZeros={true}
											/>
											<Select
												style={paddingLeft}
												input={<Dropdown />}
												name="height"
												IconComponent={
													ExpandMoreRoundedIcon
												}
												value={heightFraction ?? ''}
												onChange={handleDecimalChange}
												data-testid={`dimension-height-fraction`}
											>
												{selectionOptions.map(
													(fraction, index) => (
														<MenuItem
															key={index}
															value={fraction}
															data-testid={`dimension-height-fraction-item`}
														>
															{
																fraction.fraction
															}
														</MenuItem>
													)
												)}
											</Select>
										</div>
									</div>
									<div
										className="row"
										style={{padding: 5 }}
									>
										<FormControl 
											sx={{
												display: 'flex',
												flexDirection: 'row',
												alignItems: 'center'
											}}
										>
											<span style={spanStyle}>
												cm
											</span>
											<NumberFormat
												style={textBoxStyle}
												name="crate_height_metric"
												step="any"
												onChange={handleChange}
												value={
													roundTo10th(
														crate_height_metric
													) ?? ''
												}
												data-testid={`dimension-height-cm`}
												customInput={TextBox}
												allowLeadingZeros={false}
											/>
										</FormControl>
									</div>
								</div>


								<div className="column">
									<Label
										style={{
											...labelStyle,
											marginBottom: -14,
											marginLeft: 28
										}}
										disableAnimation
										shrink
									>
										Width
									</Label>
									<div className="row" style={{
										padding: 5
									}}>
										<div style={sideBySide}>
											<span style={spanStyle}>
												by
											</span>
											<NumberFormat
												style={textBoxStyle}
												name="width"
												step="any"
												value={widthInt ?? ''}
												onChange={handleIntChange}
												data-testid={`dimension-width-inch`}
												customInput={TextBox}
												decimalSeparator=''
												allowLeadingZeros={true}
											/>
											<Select
												style={paddingLeft}
												name="width"
												input={<Dropdown />}
												IconComponent={
													ExpandMoreRoundedIcon
												}
												value={widthFraction ?? ''}
												onChange={handleDecimalChange}
												data-testid={`dimension-width-fraction`}
											>
												{selectionOptions.map(
													(fraction, index) => (
														<MenuItem
															key={index}
															value={fraction}
															data-testid={`dimension-width-fraction-item`}
														>
															{
																fraction.fraction
															}
														</MenuItem>
													)
												)}
											</Select>
										</div>
									</div>
									<div
										className="row"
										style={{ padding: 5 }}
									>
										<FormControl
											sx={{
												display: 'flex',
												flexDirection: 'row',
												alignItems: 'center'
											}}
										>
											<span style={spanStyle}>
												by
											</span>
											<NumberFormat
												style={textBoxStyle}
												name="crate_width_metric"
												step="any"
												onChange={handleChange}
												value={
													roundTo10th(
														crate_width_metric
													) ?? ''
												}
												data-testid={`dimension-width-cm`}
												customInput={TextBox}
												allowLeadingZeros={false}
											/>
										</FormControl>
									</div>
								</div>


								<div className="column">
									<Label
										style={{
											...labelStyle,
											marginBottom: -14,
											marginLeft: 28

										}}
										disableAnimation
										shrink
									>
										Depth
									</Label>
									<div style={{padding: 5}}>
										<div style={sideBySide}>
											<span style={spanStyle}>
												by
											</span>
											<NumberFormat
												style={textBoxStyle}
												name="depth"
												step="any"
												value={depthInt ?? ''}
												onChange={handleIntChange}
												data-testid={`dimension-depth-inch`}
												customInput={TextBox}
												decimalSeparator=''
												allowLeadingZeros={true}
											/>
											<Select
												style={paddingLeft}
												name="depth"
												value={depthFraction ?? ''}
												input={<Dropdown />}
												IconComponent={
													ExpandMoreRoundedIcon
												}
												onChange={handleDecimalChange}
												data-testid={`dimension-depth-fraction`}
											>
												{selectionOptions.map(
													(fraction, index) => (
														<MenuItem
															key={index}
															value={fraction}
															data-testid={`dimension-depth-fraction-${index}-item`}
														>
															{
																fraction.fraction
															}
														</MenuItem>
													)
												)}
											</Select>
										</div>
									</div>
									<div
										className="row"
										style={{padding: 5}}
									>
										<FormControl sx={{
											display: 'flex',
											flexDirection: 'row',
											alignItems: 'center'
										}}>
											<span style={spanStyle}>by</span>
											<NumberFormat
												style={textBoxStyle}
												name="crate_depth_metric"
												step="any"
												onChange={handleChange}
												value={
													crate_depth_metric ?? ''
												}
												data-testid={`dimension-depth-cm`}
												customInput={TextBox}
												allowLeadingZeros={false}
											/>
										</FormControl>
									</div>
								</div>

								<div
									className="column"
									style={{ flexGrow: '0.5' }}
								>
									<Label
										style={{
											...labelStyle,
											marginBottom: -14,
											marginLeft: 4
										}}
										disableAnimation
										shrink
									>
										Area
									</Label>
									<div className="row" style={{padding: 5}}>
										<FormControl>
											<TextBox
												value={areaImperial ?? ''}
												onChange={() => {}}
												data-testid={`dimension-area-inch`}
											/>
										</FormControl>
									</div>
									<div
										className="row"
										style={{padding: 5}}
									>
										<FormControl>
											<TextBox
												value={areaMetric ?? ''}
												onChange={() => {}}
												data-testid={`dimension-area-cm`}
											/>
										</FormControl>
									</div>
								</div>

								<div
									className="column"
									style={{ flexGrow: '0.5' }}
								>
									<Label
										style={{
											...labelStyle,
											marginBottom: -14,
											marginLeft: 4

										}}
										disableAnimation
										shrink
									>
										Volume
									</Label>
									<div className="row"  style={{padding: 5}}>
										<FormControl>
											<TextBox
												value={volumeImperial ?? ''}
												onChange={() => {}}
												data-testid={`dimension-volume-inch`}
											/>
										</FormControl>
									</div>
									<div
										className="row"
										style={{padding: 5}}
									>
										<FormControl>
											<TextBox
												value={volumeMetric ?? ''}
												onChange={() => {}}
												data-testid={`dimension-volume-cm`}
											/>
										</FormControl>
									</div>
								</div>
							</div>
						</div>
					</div>
					<Divider
						direction="horizontal"
						style={{ marginTop: '1.5em' }}
					/>
				</div>
			</div>

		</div>
	)
}
