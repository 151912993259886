import { Window } from '@mui/icons-material'
import { Button, TablePagination } from '@mui/material'
import { useFormik } from 'formik'
import { useEffect } from 'react'

import { ArtDetail } from '../../../components/Art'
import RenderInput from '../../../components/Input'
import { useDebounce } from '../../../util'

const GridViewFilters = (props) => {
	const { formik } = props || {}

	const inputs = [
		{
			className: 'w-[200px]',
			label: 'Sort results by',
			name: 'orderBy',
			options: [
				{ label: 'Art Title', value: 'art.title' },
				{ label: 'Artist First Name', value: 'artist.first_name' },
				{ label: 'Artist Last Name', value: 'artist.last_name' },
				{ label: 'Inventory No', value: 'art.inventory_number_prefix' },

				{ label: 'Activity', value: 'art.is_active' },
				{ label: 'Art Status', value: 'art.status_id' },
				{ label: 'Listing Status', value: 'listing_x_art.status_id' },

			],
			type: 'select',
		},
		{
			className: 'w-[480px]',
			name: 'search',
			type: 'search',
		}
	]

	return (
		<div className='flex justify-between gap-4 mb-12'>
			{
				inputs?.map((input, index) => {
					const inputProps = {
						formik,
						...input
					}

					return (
						<RenderInput key={index} {...inputProps} />
					)
				})
			}
		</div>
	)
}


const ListingGridView = (props) => {
	const { isGrid, handleSort, orderBy: defaultOrderBy = '', pagination, setIsGrid, searchListingArt, rows } = props || {}

	const formik = useFormik({
		initialValues: {
			orderBy: defaultOrderBy,
			search: '',
		}
	})
	const { orderBy, search } = formik?.values || {}
	const debouncedValues = useDebounce(formik?.values, 500)

	useEffect(() => {
		handleSort?.({ orderBy })
	},[orderBy])

	useEffect(() => {
		searchListingArt?.(search?.length ? search : null)
	},[debouncedValues?.search])

	const gridViewFilterProps = {
		formik,
	}

	return (
		<div className='flex flex-col justify-between h-full'>
			<div className='flex justify-end'>
				<Button
					size="small"
					startIcon={<Window />}
					onClick={() => setIsGrid(!isGrid)}
				>
          Display
				</Button>
			</div>
			<GridViewFilters {...gridViewFilterProps} />
			<div className="grid grid-cols-4 gap-x-6 gap-y-6 overflow-y-auto"
				style={{ height: 'calc(100% - 52px)' }}
			>
				{
					rows?.map((row, index) => {
						return (
							<ArtDetail art={row?.art} key={index} />
						)
					})
				}
			</div>
			<div className='flex justify-end h-[52px]'>
				<TablePagination
					component="div"
					count={100}
					{...pagination}
				/>
			</div>
		</div>

	)
}

export default ListingGridView
