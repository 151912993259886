import React, { useContext, useCallback } from "react"
import {
	TableContainer,
	Table,
	TableRow,
	TableCell,
	TableBody,
	Box,
} from "@mui/material"
import Thumbnail from "../../Thumbnail/Thumbnail"
import IconButton from "@mui/material/IconButton"
import { DispatchContext } from "../../store"
import { severity } from "../../Snackbar/CustomizedSnackbar"
import EnhancedTableHead from "../../table/EnhancedTableHead"
import InfoCard from "../../InfoCard/InfoCard"
import { useNavigate } from "react-router-dom"
import { Skeleton } from "@mui/material"
import {
	formatDate,
	getActionColor,
	getArtistThumbnailDetail,
	getDealName,
} from "../../common/helpers"
import startCase from "lodash/startCase"
import toLower from "lodash/toLower"
import DealThumbnail from "../../Deals/DealThumbnail"
import ReadMoreOutlinedIcon from "@mui/icons-material/ReadMoreOutlined"
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined"

function Activity(props) {
	// Cells for table
	const headCells = [
		{
			id: "entity_title",
			noSort: true,
			numeric: false,
			disablePadding: false,
			label: "Object",
		},
		{
			id: "action",
			noSort: true,
			numeric: false,
			disablePadding: false,
			label: "Action",
		},
		{
			id: "item",
			noSort: true,
			numeric: false,
			disablePadding: false,
			label: "Item",
		},
		{
			id: "modified_at",
			noSort: true,
			hideSortIcon: true,
			numeric: false,
			disablePadding: false,
			label: "Date",
		},
		{
			id: "action",
			noSort: true,
			numeric: false,
			disablePadding: false,
			label: "Actions",
		},
	]

	const [infoModal, setInfoModal] = React.useState({ open: false })
	const [currentActivity, setCurrentActivity] = React.useState(null)

	const navigate = useNavigate()

	// Snackbar
	const dispatch = useContext(DispatchContext)
	const openSnackbar = useCallback(
		(severity, text) => {
			dispatch({ type: "openSnackBar", payload: { severity, text } })
		},
		[dispatch],
	)

	const getIgnoredAttributes = () => {
		const common = [
			"type_id",
			"note_status_id",
			"assigned_to_type",
			"assigned_by_user_id",
			"due_at",
			"created_by",
			"modified_by",
			"description",
		]

		return [...common, "is_completed", "contact_name", "artist_name"]
	}

	return (
		<>
			<div>
				<TableContainer style={{ paddingBottom: "2em" }}>
					<Table
						aria-labelledby="tableTitle"
						size="medium"
						aria-label="enhanced table">
						<EnhancedTableHead
							headCells={headCells}
							rowCount={props?.currentActivities?.length}
						/>
						<TableBody>
							{!props.loading && props.currentActivities.length === 0 ? (
								<TableRow>
									<TableCell></TableCell>
									<TableCell style={{ padding: "1em" }}>
										No Activity Found.
									</TableCell>
									<TableCell></TableCell>
									<TableCell></TableCell>
									<TableCell></TableCell>
								</TableRow>
							) : null}

							{(!props.loading
								? props.currentActivities
								: [{ id: 1, disabled: true }]
							).map((row, index) => {
								if (row && row.hidden === true) return null

								let isPrivate = false
								let hidden = false

								switch (row.entity_name) {
									case "contact":
										isPrivate = row.contact?.is_private
										hidden = row.contact?.is_private && !row.contact?.created_at
										break

									case "deal":
										isPrivate = row.deal?.is_private
										hidden = row.deal?.is_private && !row.deal?.created_at
										break

									case "art":
										isPrivate = row.art?.is_private
										hidden = row.art?.is_private && !row.art?.created_at
										break

									default:
										break
								}

								const dealThumbnail = (
									<div style={{ paddingLeft: "1em" }}>
										<DealThumbnail
											is_private={row.deal?.is_private}
											is_gallery_private={row.deal?.is_gallery_private}
											created_at={row.deal?.created_at}
											deal_name={getDealName(row.deal)}
										/>
									</div>
								)

								const artThumbnail = (
									<Thumbnail
										name={
											row.entity_name === "art"
												? row.art?.code_name
												: row?.entity_title
										}
										formatted_name={row.art?.formatted_title}
										artist={getArtistThumbnailDetail(row.art)}
										isCompany={row.contact?.is_company}
										date={row.art?.year}
										detail={
											!isPrivate
												? row?.entity_name === "artist"
													? row?.artist?.genre
															?.map((e) => e.artist_genre)
															.join(", ") || "-"
													: row?.entity_subtitle || "-"
												: "-"
										}
										avatar={row.entity_img}
										animation={props.loading ? "wave" : false}
										largeText
										type={row.entity_name}
										style={{ paddingLeft: "1em" }}
										hasAccess={!hidden}
										isDeceased={row.contact?.is_deceased}></Thumbnail>
								)

								const thumbnail = (
									<Thumbnail
										name={row?.entity_title}
										isCompany={row.contact?.is_company}
										detail={
											!isPrivate
												? row?.entity_name === "artist"
													? row?.artist?.genre
															?.map((e) => e.artist_genre)
															.join(", ") || "-"
													: row?.entity_subtitle || "-"
												: "-"
										}
										avatar={row.entity_img}
										animation={props.loading ? "wave" : false}
										largeText
										type={row.entity_name}
										style={{ paddingLeft: "1em" }}
										hasAccess={!hidden}
										darkText={!isPrivate}
										isDeceased={row.contact?.is_deceased}></Thumbnail>
								)

								const getThumbnail = (entity_name, isPrivate) => {
									if (entity_name === "deal") return dealThumbnail

									if (entity_name === "art") return artThumbnail

									return thumbnail
								}

								return (
									<TableRow key={index}>
										<TableCell
											className="home-page-tasklist"
											component="th"
											scope="row"
											data-testid="contact-thumbnail"
											style={{ width: "15em" }}>
											{getThumbnail(row?.entity_name, isPrivate)}
										</TableCell>
										<TableCell style={{ color: getActionColor(row?.action) }}>
											{row?.action ? (
												row.action
											) : (
												<Skeleton
													variant="text"
													width={props.totalItems ? "100%" : "auto"}
													animation={props.loading ? "wave" : false}
												/>
											)}
										</TableCell>
										<TableCell>
											{row?.item ? (
												<span>{`${startCase(toLower(row.item))} ${
													row.item_id || ""
												}`}</span>
											) : (
												<Skeleton
													variant="text"
													width={props.totalItems ? "100%" : "auto"}
													animation={props.loading ? "wave" : false}
												/>
											)}
										</TableCell>
										<TableCell>
											{row?.modified_at ? (
												formatDate(
													row.modified_at,
													"MMMM do, yyyy 'at' h':'mma",
												)
											) : (
												<Skeleton
													variant="text"
													width={props.totalItems ? "100%" : "auto"}
													animation={props.loading ? "wave" : false}
												/>
											)}
										</TableCell>
										<TableCell>
											{row?.action !== "Deleted" ? (
												<Box sx={{ display: "flex", gap: 1 }}>
													<IconButton
														title="Go to detail page"
														aria-label="Go to detail page"
														onClick={() => {
															switch (row.entity_name) {
																case "art":
																	if (
																		row.art?.is_private &&
																		!row.art?.created_at
																	) {
																		openSnackbar(
																			severity.ERROR,
																			"You do not have access to this Private Artwork.",
																		)
																		return
																	}

																	navigate(`/art/${row.entity_id}/datalog`)
																	break

																case "contact": {
																	if (
																		row.contact?.is_private &&
																		!row.contact?.created_at
																	) {
																		openSnackbar(
																			severity.ERROR,
																			"You do not have access to this Private Contact.",
																		)
																		return
																	}

																	navigate(`/contacts/${row.entity_id}/datalog`)
																	break
																}

																case "listing":
																	navigate(`/listings/${row.entity_id}/datalog`)
																	break

																case "artist":
																	navigate(`/artists/${row.entity_id}/datalog`)
																	break

																case "deal":
																	if (
																		(row.deal?.is_private ||
																			row.deal?.is_gallery_private) &&
																		!row.deal?.created_at
																	) {
																		openSnackbar(
																			severity.ERROR,
																			"You do not have access to this Private Deal.",
																		)
																		return
																	}

																	navigate(`/deals/${row.entity_id}/datalog`)
																	break

																// TODO note, tasks, tags
																case "note":
																	navigate(
																		`/${row.entity_name}s/${row.entity_id}/datalog`,
																	)
																	break

																default:
																	openSnackbar(
																		severity.WARNING,
																		"This activity is not linked to an object.",
																	)
															}
														}}>
														<ReadMoreOutlinedIcon />
													</IconButton>
													<IconButton
														onClick={(e) => {
															setInfoModal({ open: true })
															setCurrentActivity(row)
														}}>
														<InfoOutlinedIcon />
													</IconButton>
												</Box>
											) : null}
										</TableCell>
									</TableRow>
								)
							})}
						</TableBody>
					</Table>
				</TableContainer>
			</div>

			<InfoCard
				open={infoModal.open}
				object={currentActivity}
				setInfoModal={setInfoModal}
				ignoredAttributes={getIgnoredAttributes()}
				allAttributes
			/>
		</>
	)
}

export default Activity
