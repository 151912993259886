import EnhancedTableHead from "../../table/EnhancedTableHead"
import { Box, Skeleton } from "@mui/material"
import {
	TableContainer,
	Table,
	TableRow,
	TableCell,
	TableBody,
} from "@mui/material"
import IconButton from "@mui/material/IconButton"
import React, { useCallback, useContext } from "react"
import {
	returnDate,
	formatDate,
	getArtistThumbnailDetail,
} from "../../common/helpers"
import Thumbnail from "../../Thumbnail/Thumbnail"
import { differenceInDays, differenceInHours } from "date-fns"
import compareAsc from "date-fns/compareAsc"
import orderBy from "lodash/orderBy"
import ReadMoreOutlinedIcon from "@mui/icons-material/ReadMoreOutlined"

import { severity } from "../../Snackbar/CustomizedSnackbar"
import { DispatchContext } from "../../store"
import GalleryContactList from "../../common/components/GalleryContactList"
import { KeyboardTab } from "@mui/icons-material"

function GlobalReserves(props) {
	// Cells for table
	const headCells = [
		{
			id: "artpiece",
			numeric: false,
			disablePadding: false,
			label: "Artwork",
			noSort: true,
		},
		{
			id: "salesperson",
			numeric: false,
			disablePadding: false,
			label: "Gallery Contacts",
			noSort: true,
		},
		{
			id: "expiration",
			numeric: false,
			disablePadding: false,
			label: "Expiration",
			noSort: true,
		},
		{
			id: "action",
			numeric: false,
			disablePadding: false,
			label: "Actions",
			noSort: true,
		},
	]

	const dispatch = useContext(DispatchContext)

	//Snackbar
	const openSnackbar = useCallback(
		(severity, text) => {
			dispatch({ type: "openSnackBar", payload: { severity, text } })
		},
		[dispatch],
	)

	return (
		<>
			<div>
				<TableContainer style={{ overflowX: "hidden", paddingBottom: "2em" }}>
					<Table
						aria-labelledby="tableTitle"
						size="medium"
						aria-label="enhanced table">
						<EnhancedTableHead
							headCells={headCells}
							rowCount={props.currentArts?.length}
						/>
						<TableBody>
							{!props.loading && props.currentArts?.length === 0 ? (
								<TableRow>
									<TableCell></TableCell>
									<TableCell style={{ padding: "1em" }}>
										No global reserves found.
									</TableCell>
									<TableCell></TableCell>
									<TableCell></TableCell>
								</TableRow>
							) : null}

							{props.currentArts?.map((row, index) => {
								let expiration
								let expired = false

								// Get distance from today to reserve_end_date of each art piece.
								// If its expired return formatedDate, if it will expire en 3 days or less, show formatDistanceToNowStrict

								if (row?.reserve_end_at) {
									if (
										compareAsc(returnDate(row.reserve_end_at), new Date()) ===
										-1
									) {
										expiration = formatDate(
											row.reserve_end_at,
											"MMMM do',' yyyy 'at' h':'mma",
										)
										expired = true
									} else {
										if (
											differenceInDays(
												returnDate(row.reserve_end_at),
												new Date(),
											) >= 3
										) {
											expiration = formatDate(
												row.reserve_end_at,
												"MMMM do',' yyyy 'at' h':'mma",
											)
											expired = false
										} else {
											expiration =
												differenceInHours(
													returnDate(row.reserve_end_at),
													new Date(),
												) + " hours"
											expired = false
										}
									}
								}

								const hidden = row.art?.is_private && !row.art?.created_at

								const currentSalespersons = orderBy(
									row.deal?.salesperson,
									["is_lead"],
									["desc"],
								)

								return (
									<TableRow key={row.id} selected={row === props.selectedRow}>
										<TableCell style={{ width: "15em" }}>
											<Thumbnail
												formatted_name={row.art?.formatted_title}
												name={row.art?.code_name}
												artist={getArtistThumbnailDetail(row.art)}
												date={row.art?.year}
												style={{ paddingLeft: "1em" }}
												avatar={row.art?.imgUrl}
												type="art"
												animation={props.loading ? "wave" : false}
												largeText
												hasAccess={!hidden}></Thumbnail>
										</TableCell>

										<TableCell
											className="home-page-tasklist"
											component="th"
											scope="row"
											data-testid="contact-thumbnail"
											style={{ width: "10em" }}>
											<GalleryContactList
												salespersons={currentSalespersons}
												findMode={false}
												max={5}
											/>
										</TableCell>

										<TableCell style={{ width: "15em" }}>
											{row.reserve_end_at ? (
												<div style={expired ? { color: "red" } : null}>
													{expiration}
												</div>
											) : (
												<Skeleton
													variant="text"
													width={props.totalItems ? "100%" : "auto"}
													animation={props.loading ? "wave" : false}
												/>
											)}
										</TableCell>

										<TableCell>
											{!row?.disabled ? (
												<Box sx={{ display: "flex", gap: 1 }}>
													<IconButton
														title={
															row?.is_private
																? "Go to Private Deal"
																: "Go to Deal"
														}
														aria-label={
															row?.is_private
																? "Go to Private Deal"
																: "Go to Deal"
														}
														onClick={(e) => {
															const noNav =
																(row?.deal?.is_private ||
																	row?.deal?.is_gallery_private) &&
																!row?.deal?.created_at

															if (noNav) {
																openSnackbar(
																	severity.WARNING,
																	"You do not have access to this Private Deal.",
																)
															} else {
																props.saveAndGo(
																	`/deals/${row.deal_id}/details`,
																	row.id,
																)
															}
														}}>
														<ReadMoreOutlinedIcon />
													</IconButton>
													<IconButton
														title={
															row?.art?.is_private
																? "Go to Private Art"
																: "Go to Art"
														}
														aria-label={
															row?.art?.is_private
																? "Go to Private Art"
																: "Go to Art"
														}
														onClick={(e) => {
															const noNavArt =
																row?.art?.is_private && !row?.art?.created_at
															if (noNavArt) {
																openSnackbar(
																	severity.WARNING,
																	"You do not have access to this Private Art.",
																)
															} else {
																props.saveAndGo(
																	`/art/${row.art_id}/details`,
																	row.id,
																)
															}
														}}>
														<KeyboardTab />
													</IconButton>
												</Box>
											) : null}
										</TableCell>
									</TableRow>
								)
							})}
						</TableBody>
					</Table>
				</TableContainer>
			</div>
		</>
	)
}

export default GlobalReserves
