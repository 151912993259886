/* eslint-disable eqeqeq */
import React, { useContext, useCallback } from 'react'
import { DispatchContext } from '../../store'
import TextBox from '../../input/Text/TextBox'
import Label from '../../input/Label'
import {
	FormControl,
	Select,
	MenuItem,
	IconButton,
	Divider,
	Button,
	Radio,
} from '@mui/material'
import Dropdown from '../../input/Dropdown/Dropdown'
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded'
import { ChooseImageButton } from '../../input/Buttons'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import { severity } from '../../Snackbar/CustomizedSnackbar'
import CheckRoundedIcon from '@mui/icons-material/CheckRounded'
import DeleteIcon from '@mui/icons-material/Delete'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import { CancelButton } from './../../input/Buttons'
import NumberFormat from 'react-number-format'

const CM_TO_INCH_RATIO = 2.54

/* ======================================================================
	Styles
	===================================================================== */

const labelStyle = {
	fontWeight: 500,
}

const sideBySide = {
	display: 'flex',
	flexDirection: 'inherit',
	alignItems: 'center'
}

const paddingLeft = {
	paddingLeft: '5px',
	marginTop: '0px',
}

const spanStyle = {
	paddingRight: 5
}

const textBoxStyle = { marginTop: '0px', width: '100%' }

/* ======================================================================
	Helper Functions
	===================================================================== */

export const getMantissa = (val) => (val != null) ? val - Math.floor(val) : null

export const roundTo10th = (val) => (val != null) ? Math.round(val * 10) / 10 : null

const roundTo16th = (val) => (val != null) ? Math.round(val * 16) / 16 : null

/**
 * Round the passed metric centimeter to the nearest 1/16th of an inch,
 * return.
 *
 * @param {*} val - the metric distance in cm
 */
const getInch = (val) => {
	if (val == null) return null
	return Math.round((val / CM_TO_INCH_RATIO) * 16) / 16
}

/**
 * Get get the passed metric distance (cm) in inches,
 * return the root only.
 *
 * @param {=int} inch - the metric distance in cm
 */
export const getInchRoot = (inch) => {
	if (inch == null) return null
	return Math.trunc(getInch(inch))
}

/**
 * Get get the passed metric distance (cm) in inches,
 * return the closest fractional member in selectionOptions.
 *
 * @param {*} inch - the metric distance in cm
 */
export const getInchFractional = (inch) => {
	if (inch == null) return null
	inch = getInch(inch)

	const mantissa = getMantissa(inch)
	return selectionOptions[Math.round(mantissa * 16)]
}

/**
 *
 * @param {*} li
 * @param {*} ld
 * @param {*} wi
 * @param {*} wd
 */
export const getAreaImperial = (li, ld, wi, wd) => {
	if ([li, ld, wi, wd].some(v => v == null)) return null

	const area = roundTo16th((li + ld?.decimal) * (wi + wd?.decimal))

	if (area == null) return null

	return `${Math.trunc(area)} ${
		selectionOptions[Math.round(getMantissa(area) * 16)].label
	}`
}

/**
 *
 * @param {*} l
 * @param {*} w
 */
export const getAreaMetric = (l, w) => {
	if (l == null || w == null) return ''
	return roundTo10th(l * w)
}

/**
 *
 * @param {*} li
 * @param {*} ld
 * @param {*} wi
 * @param {*} wd
 * @param {*} hi
 * @param {*} hd
 */
export const getVolumeImperial = (li, ld, wi, wd, hi, hd) => {
	if ([li, ld, wi, wd, hi, hd].some(v => v == null)) return null

	const volume = roundTo16th(
		(li + ld.decimal) * (wi + wd.decimal) * (hi + hd.decimal)
	)

	if (volume == null) return null

	return `${Math.trunc(volume)} ${
		selectionOptions[Math.round(getMantissa(volume) * 16)].label
	}`
}

/**
 *
 * @param {*} l
 * @param {*} w
 * @param {*} h
 */
export const getVolumeMetric = (l, w, h) => {
	if (l == null || w == null || h == null) return null
	return roundTo10th(l * w * h)
}

/* ======================================================================
	Constants
	===================================================================== */

export const selectionOptions = [
	{ decimal: 0, fraction: '0', label: '' },
	{ decimal: 0.0625, fraction: '¹⁄₁₆', label: '¹⁄₁₆' },
	{ decimal: 0.125, fraction: '⅛', label: '⅛' },
	{ decimal: 0.1875, fraction: '³⁄₁₆', label: '³⁄₁₆' },
	{ decimal: 0.25, fraction: '¼', label: '¼' },
	{ decimal: 0.3125, fraction: '⁵⁄₁₆', label: '⁵⁄₁₆' },
	{ decimal: 0.375, fraction: '⅜', label: '⅜' },
	{ decimal: 0.4375, fraction: '⁷⁄₁₆', label: '⁷⁄₁₆' },
	{ decimal: 0.5, fraction: '½', label: '½' },
	{ decimal: 0.5625, fraction: '⁹⁄₁₆', label: '⁹⁄₁₆' },
	{ decimal: 0.625, fraction: '⅝', label: '⅝' },
	{ decimal: 0.6875, fraction: '¹¹⁄₁₆', label: '¹¹⁄₁₆' },
	{ decimal: 0.75, fraction: '¾', label: '¾' },
	{ decimal: 0.8125, fraction: '¹³⁄₁₆', label: '¹³⁄₁₆' },
	{ decimal: 0.875, fraction: '⅞', label: '⅞' },
	{ decimal: 0.9375, fraction: '¹⁵⁄₁₆', label: '¹⁵⁄₁₆' },
]

export default function DimensionSelection(props) {

	//Snackbar
	const dispatch = useContext(DispatchContext)
	const openSnackbar = useCallback(
		(severity, text) => {
			dispatch({ type: 'openSnackBar', payload: { severity, text } })
		},
		[dispatch]
	)

	const [generateIndex, setGenerateIndex] = React.useState(null)
	const [copyButton, setCopyButton] = React.useState(<span>Copy</span>)
	const [currentDimension, setCurrentDimension] = React.useState(null)

	const { artInput, findMode, setArtInput, setDimensionModal } = props
	const onClose = useCallback(() =>  {

		let newDimensions = artInput.dimensions
		if (!findMode) {
			newDimensions = newDimensions?.filter(e => e.description)
		}

		setDimensionModal(false)
		setArtInput(oldArtInput => ({
			...oldArtInput,
			dimensions: newDimensions,
			all_dimensions: newDimensions
				?.map((e) => e.description)
				?.sort((a, b) => Number(a.ordinal) - Number(b.ordinal))
				.join('\n'),
		}))
	}, [artInput, findMode, setArtInput, setDimensionModal])

	const handleKeyDown = useCallback((e) => {
		if (e.target?.name == 'description') return
		if (e.key !== 'Enter') return
		e.stopPropagation()
		onClose()
	}, [onClose])

	return (
		<div
			style={{
				width: '70em',
				maxHeight: '90vh'
			}}
			className={props.art?.is_private ? 'private-object' : null}
		>
			<div>

				<h1 className="card-title">Dimensions { !props.findMode && 
					(props.artInput.dimensions?.length ? "("+props.artInput.dimensions.length+")" : "")
				}

				{!props.findMode && <div>
					<IconButton
						size="small"
						disabled={currentDimension==0}
						onClick={() => {
							let selectedIndex = currentDimension

							if (selectedIndex != null && selectedIndex !== 0) {
								let tempArray = [...props.artInput.dimensions]
									.map((o) => ({...o}))

								tempArray[selectedIndex] = {
									...props.artInput.dimensions[selectedIndex - 1],
									ordinal: selectedIndex + 1
								}

								tempArray[selectedIndex - 1] = {
									...props.artInput.dimensions[selectedIndex],
									ordinal: selectedIndex
								}

								setCurrentDimension(selectedIndex - 1)
								props.setArtInput({
									...props.artInput,
									dimensions: tempArray
								})
							}
						}}
						data-testid="dimension-move-up"
					>
						<ArrowUpwardIcon />
					</IconButton>
					<IconButton
						size="small"
						disabled={currentDimension==props.artInput.dimensions?.length - 1}
						onClick={() => {
							let selectedIndex = currentDimension

							if (selectedIndex != null && selectedIndex !== props.artInput.dimensions?.length - 1) {
								let tempArray = [...props.artInput.dimensions]
									.map((o) => ({...o}))

								tempArray[selectedIndex] = {
									...props.artInput.dimensions[selectedIndex + 1],
									ordinal: selectedIndex + 1
								}

								tempArray[selectedIndex + 1] = {
									...props.artInput.dimensions[selectedIndex],
									ordinal: selectedIndex + 2
								}

								setCurrentDimension(selectedIndex + 1)
								props.setArtInput({
									...props.artInput,
									dimensions: tempArray
								})
							}
						}}
						data-testid="dimension-move-down"
					>
						<ArrowDownwardIcon />
					</IconButton>

				</div>}

				<div className="spacer"></div>

				<Button
					variant="contained"
					startIcon={<DeleteIcon />}
					style={{
						backgroundColor: '#CC3333',
						color: 'white',
						visibility: currentDimension == null ? 'hidden' : 'visible',
					}}
					onClick={() => {
						props.setArtInput({
							...props.artInput,
							dimensions: props.artInput.dimensions
								.filter((_, i) => i != currentDimension)
								.map((dimension, i) => {
									return {
										...dimension,
										ordinal: i + 1,
									}
								}),
						})
						setCurrentDimension(null)
					}}
					data-testid="dimension-delete"
				>
					Remove
				</Button>
				<CancelButton
					variant="contained"
					onClick={onClose}
					style={{ display: 'flex', marginLeft: '1em', padding: '.2em'}}
				>Close
				</CancelButton>
				</h1>



				<Divider direction="horizontal" />
			</div>
			<div 
				style={{
					overflow: 'scroll',
					maxHeight: '80vh'
				}}
				onKeyDown={handleKeyDown}
			>
				{props.artInput.dimensions?.length === 0 ? (
					<div style={{ paddingTop: '1em' }}>
						<span>No dimensions found.</span>
					</div>
				) : null}

				{props.artInput.dimensions?.sort((a,b) => {
					return Number(a.ordinal) - Number(b.ordinal)
				})?.map((dimension, index) => {

					const {
						depth_metric,
						width_metric,
						height_metric,
						description,
					} = dimension

					// Imperial
					const depthInt = getInchRoot(dimension.depth_metric)
					const depthFraction = getInchFractional(
						dimension.depth_metric
					)

					const widthInt = getInchRoot(dimension.width_metric)
					const widthFraction = getInchFractional(dimension.width_metric)

					const heightInt = getInchRoot(dimension.height_metric)
					const heightFraction = getInchFractional(
						dimension.height_metric
					)

					const areaImperial = getAreaImperial(
						heightInt,
						heightFraction,
						widthInt,
						widthFraction
					)
					const volumeImperial = getVolumeImperial(
						depthInt,
						depthFraction,
						widthInt,
						widthFraction,
						heightInt,
						heightFraction
					)

					// Metric
					const areaMetric = getAreaMetric(height_metric, width_metric)
					const volumeMetric = getVolumeMetric(
						depth_metric,
						width_metric,
						height_metric
					)

					let dimensions = [...props.artInput.dimensions]

					const handleChange = (e) => {

						let value = e.target.value
						if (e.target.name != 'description') {
							if (value == '') value = null
							else value = Number(value)
						}

						dimensions[index] = {
							...dimensions[index],
							[e.target.name]: value
						}

						props.setArtInput({
							...props.artInput,
							dimensions,
						})
					}

					const handleIntChange = (e) => {

						let value = e.target.value
						let decimal
						let dist

						if (value == '') {
							value = null
							decimal = null
							dist = null
						} else {
							value = Number(value)

							switch (e.target.name) {
							case 'depth':
								decimal = depthFraction?.decimal ?? 0
								break

							case 'width':
								decimal = widthFraction?.decimal ?? 0
								break

							case 'height':
								decimal = heightFraction?.decimal ?? 0
								break

							default:
								break
							}

							if (decimal)
								dist = (value + decimal) * 2.54
							else
								dist = value * 2.54
						}

						dimensions[index] = {
							...dimensions[index],
							[`${e.target.name}_metric`]: dist,
						}

						props.setArtInput({
							...props.artInput,
							dimensions,
						})
					}

					const handleDecimalChange = (e) => {

						let integer
						switch (e.target.name) {
						case 'depth':
							integer = depthInt
							break

						case 'width':
							integer = widthInt
							break

						case 'height':
							integer = heightInt
							break

						default:
							break
						}

						let dist
						if (integer)
							dist = (integer + e.target.value.decimal) * 2.54
						else dist = (e.target.value.decimal) * 2.54

						dimensions[index] = {
							...dimensions[index],
							[`${e.target.name}_metric`]: dist,
						}

						props.setArtInput({
							...props.artInput,
							dimensions,
						})
					}

					return (
						<div key={'dimension-' + index}>
							<div style={{ display: 'flex', alignItems: 'center' }}>
								{!props.findMode && <Radio
									checked={currentDimension == index}
									onChange={() => {setCurrentDimension(index)}}
									name="radio-button-dimension"
									inputProps={{ 'aria-label': 'current-dimension' }}
									data-testid={`dimension-select-${index}`}
								/>}

								<div>

									{ !props.findMode && <div style={{ display: 'flex' }}>
										<FormControl sx={{width: '100%'}}>
											<Label
												error={props.attempt && description.length === 0}
												style={labelStyle}
												disableAnimation
												shrink
											>
												{`${dimension.ordinal}.`} Label*
											</Label>
											<TextBox
												multiline
												required
												error={props.attempt && description.length === 0}
												name="description"
												value={description}
												onChange={handleChange}
												data-testid={`dimension-label-${index}`}
											/>
										</FormControl>

										<ChooseImageButton
											style={{
												marginLeft: '1.3em',
												height: '41px',
												marginTop: '1.7em',
											}}
											onClick={() => {


												if (!heightInt && !widthInt && !depthInt) {

													openSnackbar(severity.WARNING, "Invalid dimensions supplied.")
													dimensions[index] = {
														...dimensions[index],
														description: '',
													}

													props.setArtInput({
														...props.artInput,
														dimensions,
													})
													return
												}

												setGenerateIndex(index)
												let description = ''

												if (heightInt)
													description += `${heightInt}`

												if (heightFraction)
													description += `${heightFraction.label}`

												if ((heightInt || heightFraction) && (widthInt || widthFraction))
													description += ' x '

												if (widthInt)
													description += `${widthInt}`

												if (widthFraction)
													description += `${widthFraction.label}`

												if ((widthInt || widthFraction) && (depthInt || depthFraction))
													description += ' x '

												if (depthInt)
													description += `${depthInt}`

												if (depthFraction)
													description += `${depthFraction.label}`

												description += ' inches\n('

												if (dimension.height_metric)
													description += `${roundTo10th(
														dimension.height_metric
													)}`

												if (dimension.width_metric && dimension.height_metric)
													description += ` x ${roundTo10th(
														dimension.width_metric
													)}`
												else if (dimension.width_metric && !dimension.height_metric)
													description += `${roundTo10th(
														dimension.width_metric
													)}`

												if (dimension.depth_metric && dimension.width_metric)
													description += ` x ${roundTo10th(
														dimension.depth_metric
													)}`
												else if (dimension.depth_metric && !dimension.width_metric)
													description += `${roundTo10th(
														dimension.depth_metric
													)}`

												description += ' cm)'


												dimensions[index] = {
													...dimensions[index],
													description,
												}

												props.setArtInput({
													...props.artInput,
													dimensions,
												})
											}}
											data-testid={`dimension-generate-${index}`}
										>
											Generate
										</ChooseImageButton>
									</div>}

									{props.showOldLabel && generateIndex === index && props.art?.dimension[index]?.description ?
										<div style={{ display: 'flex' }}>
											<FormControl style={{ width: '72.75%', marginLeft: '25px' }}>
												<Label
													error={props.attempt && description.length === 0}
													style={labelStyle}
													disableAnimation
													shrink
												>
													Old Label
												</Label>
												<TextBox
													multiline
													readOnly={true}
													required
													onChange={()=>{}}
													name="old-label"
													value={props.art?.dimension[index]?.description}
													data-testid={`dimension-oldlabel-${index}`}
												/>
											</FormControl>

											<ChooseImageButton
												style={{
													marginLeft: '1.3em',
													height: '41px',
													marginTop: '1.7em',
												}}
												onClick={(e) => {
													navigator.clipboard.writeText(props.art?.dimension[index].description)
														.then(() => {
															setCopyButton(<CheckRoundedIcon />)
														}).catch((err) =>{
															openSnackbar(
																severity.WARNING,
																'Could not copy selection.'
															)
														})

													setTimeout(function(){ setCopyButton(<span>Copy</span>) }, 3000)

												}}
												data-testid={`dimension-copybutton-${index}`}
											>
												{copyButton}
											</ChooseImageButton>
										</div>

										: null}

									<div
										className="row"
										style={{padding: 5}}
									>
										<div className="column">
											<Label
												style={{
													...labelStyle,
													marginBottom: -14,
													marginLeft: 33
												}}
												disableAnimation
												shrink
											>
												Height
											</Label>
											<div className="row"  style={{padding: 5}}>
												<div style={sideBySide}>
													<span style={spanStyle}> in. </span>
													<NumberFormat
														style={{
															...textBoxStyle,
															marginLeft: 5,
														}}
														name="height"
														step="any"
														value={heightInt ?? ''}
														onChange={handleIntChange}
														data-testid={`dimension-height-inch-${index}`}
														customInput={TextBox}
														decimalSeparator=''
														allowLeadingZeros={true}
													/>
													<Select
														style={paddingLeft}
														input={<Dropdown />}
														name="height"
														IconComponent={
															ExpandMoreRoundedIcon
														}
														value={heightFraction ?? ''}
														onChange={handleDecimalChange}
														data-testid={`dimension-height-fraction-${index}`}
													>
														{selectionOptions.map(
															(fraction, index) => (
																<MenuItem
																	key={index}
																	value={fraction}
																	data-testid={`dimension-height-fraction-${index}-item`}
																>
																	{
																		fraction.fraction
																	}
																</MenuItem>
															)
														)}
													</Select>
												</div>
											</div>
											<div
												className="row"
												style={{padding: 5 }}
											>
												<FormControl 
													sx={{
														display: 'flex',
														flexDirection: 'row',
														alignItems: 'center'
													}}
												>
													<span style={spanStyle}>
														cm
													</span>
													<NumberFormat
														style={textBoxStyle}
														name="height_metric"
														step="any"
														onChange={handleChange}
														value={
															roundTo10th(
																dimension.height_metric
															) ?? ''
														}
														data-testid={`dimension-height-cm-${index}`}
														customInput={TextBox}
														allowLeadingZeros={false}
													/>
												</FormControl>
											</div>
										</div>


										<div className="column">
											<Label
												style={{
													...labelStyle,
													marginBottom: -14,
													marginLeft: 28
												}}
												disableAnimation
												shrink
											>
												Width
											</Label>
											<div className="row" style={{
												padding: 5
											}}>
												<div style={sideBySide}>
													<span style={spanStyle}>
														by
													</span>
													<NumberFormat
														style={textBoxStyle}
														name="width"
														step="any"
														value={widthInt ?? ''}
														onChange={handleIntChange}
														data-testid={`dimension-width-inch-${index}`}
														customInput={TextBox}
														decimalSeparator=''
														allowLeadingZeros={true}
													/>
													<Select
														style={paddingLeft}
														name="width"
														input={<Dropdown />}
														IconComponent={
															ExpandMoreRoundedIcon
														}
														value={widthFraction ?? ''}
														onChange={handleDecimalChange}
														data-testid={`dimension-width-fraction-${index}`}
													>
														{selectionOptions.map(
															(fraction, index) => (
																<MenuItem
																	key={index}
																	value={fraction}
																	data-testid={`dimension-width-fraction-${index}-item`}
																>
																	{
																		fraction.fraction
																	}
																</MenuItem>
															)
														)}
													</Select>
												</div>
											</div>
											<div
												className="row"
												style={{ padding: 5 }}
											>
												<FormControl
													sx={{
														display: 'flex',
														flexDirection: 'row',
														alignItems: 'center'
													}}
												>
													<span style={spanStyle}>
														by
													</span>
													<NumberFormat
														style={textBoxStyle}
														name="width_metric"
														step="any"
														onChange={handleChange}
														value={
															roundTo10th(
																dimension.width_metric
															) ?? ''
														}
														data-testid={`dimension-width-cm-${index}`}
														customInput={TextBox}
														allowLeadingZeros={false}
													/>
												</FormControl>
											</div>
										</div>


										<div className="column">
											<Label
												style={{
													...labelStyle,
													marginBottom: -14,
													marginLeft: 28

												}}
												disableAnimation
												shrink
											>
												Depth
											</Label>
											<div style={{padding: 5}}>
												<div style={sideBySide}>
													<span style={spanStyle}>
														by
													</span>
													<NumberFormat
														style={textBoxStyle}
														name="depth"
														step="any"
														value={depthInt ?? ''}
														onChange={handleIntChange}
														data-testid={`dimension-depth-inch-${index}`}
														customInput={TextBox}
														decimalSeparator=''
														allowLeadingZeros={true}
													/>
													<Select
														style={paddingLeft}
														name="depth"
														value={depthFraction ?? ''}
														input={<Dropdown />}
														IconComponent={
															ExpandMoreRoundedIcon
														}
														onChange={handleDecimalChange}
														data-testid={`dimension-depth-fraction-${index}`}
													>
														{selectionOptions.map(
															(fraction, index) => (
																<MenuItem
																	key={index}
																	value={fraction}
																	data-testid={`dimension-depth-fraction-${index}-item`}
																>
																	{
																		fraction.fraction
																	}
																</MenuItem>
															)
														)}
													</Select>
												</div>
											</div>
											<div
												className="row"
												style={{padding: 5}}
											>
												<FormControl sx={{
													display: 'flex',
													flexDirection: 'row',
													alignItems: 'center'
												}}>
													<span style={spanStyle}>by</span>
													<NumberFormat
														style={textBoxStyle}
														name="depth_metric"
														step="any"
														onChange={handleChange}
														value={
															dimension.depth_metric ?? ''
														}
														data-testid={`dimension-depth-cm-${index}`}
														customInput={TextBox}
														allowLeadingZeros={false}
													/>
												</FormControl>
											</div>
										</div>

										<div
											className="column"
											style={{ flexGrow: '0.5' }}
										>
											<Label
												style={{
													...labelStyle,
													marginBottom: -14,
													marginLeft: 4
												}}
												disableAnimation
												shrink
											>
												Area
											</Label>
											<div className="row" style={{padding: 5}}>
												<FormControl>
													<TextBox
														value={areaImperial ?? ''}
														onChange={() => {}}
														data-testid={`dimension-area-inch-${index}`}
													/>
												</FormControl>
											</div>
											<div
												className="row"
												style={{padding: 5}}
											>
												<FormControl>
													<TextBox
														value={areaMetric ?? ''}
														onChange={() => {}}
														data-testid={`dimension-area-cm-${index}`}
													/>
												</FormControl>
											</div>
										</div>

										<div
											className="column"
											style={{ flexGrow: '0.5' }}
										>
											<Label
												style={{
													...labelStyle,
													marginBottom: -14,
													marginLeft: 4

												}}
												disableAnimation
												shrink
											>
												Volume
											</Label>
											<div className="row"  style={{padding: 5}}>
												<FormControl>
													<TextBox
														value={volumeImperial ?? ''}
														onChange={() => {}}
														data-testid={`dimension-volume-inch-${index}`}
													/>
												</FormControl>
											</div>
											<div
												className="row"
												style={{padding: 5}}
											>
												<FormControl>
													<TextBox
														value={volumeMetric ?? ''}
														onChange={() => {}}
														data-testid={`dimension-volume-cm-${index}`}
													/>
												</FormControl>
											</div>
										</div>
									</div>
								</div>
							</div>
							<Divider
								direction="horizontal"
								style={{ marginTop: '1.5em' }}
							/>
						</div>
					)
				})}

				{ !props.findMode && <div
					style={{
						paddingTop: '1em',
						display: 'flex',
					}}
				>
					<Button onClick={() => {
						const lastDimension = props.artInput.dimensions[props.artInput.dimensions.length-1]
						const newDimension = {
							description: '',
							depth_metric: null,
							width_metric: null,
							height_metric: null,
							ordinal: props.artInput.dimensions.length + 1
						}

						if (lastDimension?.description == '') {
							openSnackbar(
								severity.WARNING,
								'Please complete the previous dimension.'
							)
						} else {
							props.setArtInput({
								...props.artInput,
								dimensions: props.artInput.dimensions.concat(newDimension)
							})
						}
					}}
					data-testid="dimension-new"
					>
						<AddCircleOutlineIcon style={{ color: 'grey' }} />
						<span className="ci-span">&nbsp;Add Dimension</span>
					</Button>
				</div>}
			</div>

		</div>
	)
}
