import React, { useState } from "react"
import { TableCell } from "@mui/material"
import TableSortLabel from "@mui/material/TableSortLabel"
import DropdownNoBorder from "../input/Dropdown/DropdownNoBorder"
import { Select, MenuItem } from "@mui/material"
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded"

const EnhancedTableHeadCell = (props) => {
	const {
		sortCell,
		headCell,
		rowCount,
		setSortCell,
		orderBy,
		order,
		createSortHandler,
	} = props
	const [selected, setSelected] = useState(headCell.data[0].id)

	return (
		<TableCell>
			<Select
				IconComponent={ExpandMoreRoundedIcon}
				name={sortCell || headCell.data[0].label}
				style={{ color: "grey", minWidth: "6em" }}
				disabled={rowCount === 0}
				input={<DropdownNoBorder />}
				defaultValue={props.sortCell || headCell.data[0].id}
				value={selected === sortCell ? selected : headCell.data[0].id}
				onChange={(e) => {
					setSortCell(e.target.value)
					setSelected(e.target.value)
				}}>
				{headCell?.data.map((x) => (
					<MenuItem
						key={`inner-menu-${x.id}`}
						value={x.id}
						onClick={!x.noSort ? createSortHandler(x.id) : null}>
						<TableSortLabel
							active={orderBy === x.id}
							direction={orderBy === x.id ? order : "asc"}
							hideSortIcon={x.noSort}
							style={
								x.noSort
									? { cursor: "default", fontWeight: 400 }
									: { fontWeight: 400 }
							}>
							{x.label}
						</TableSortLabel>
					</MenuItem>
				))}
			</Select>
		</TableCell>
	)
}

export default EnhancedTableHeadCell
