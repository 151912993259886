import React, { createContext, useReducer, useEffect } from 'react'
import { GET_ALL_TYPES } from './constants/Queries'


/**
 * @typedef {Object} SearchNav
 * @property {number} prev - the id of the previous entity in the navigation
 * @property {number} current - current index out of total search results
 * @property {number} total - total number of search results
 * @property {number} next - the id of the previous entity in the navigation
 * @property {*} path - unused?
 * @property {string} pathTemplate - 
 * @property {boolean} global: false
 */

/**
 * @typedef {Object} NavStackEntry
 * @property {string} url - the browser address we left from
 * @property {number[] | DocumentNode} query - the query that's being navigated through
 * @property {*} [getId] - optional function to extract the next and prev ids
 * @property {Object} state - the browser "state" for that address
 */

/**
 * @typedef {Object} Navigation
 * @property {NavStackEntry[]} stack - stack of previous pages
 * @property {SearchNav} searchNav - current search being navigated
 * @property {boolean} searchNavLoading - Whether a load is in progress
 */

type User = {
	id: number
}

const initialState = {
	auth: {
		authenticated: undefined as boolean | undefined,
		user: undefined as User | undefined
	},
	snackbar: {
		open: false,
		severity: undefined,
		text: undefined
	},
	lookups: {},
	openContext: {
		quickView: true,
		rightQuickView: true,
		sideDrawer: false,
	},
	navigation: {
		stack: [],
		searchNav: {
			prev: null,
			current: null,
			total: null,
			next: null,
			path: null,
			pathTemplate: null,
			global: false
		},
		searchNavLoading: false,
	}
}

type Action = {
	type: string;
	payload?: any;
}

const AuthStateContext = createContext(initialState.auth)
const SnackbarStateContext = createContext(initialState.snackbar)
const DispatchContext = createContext((args: Action) => {})
const LookupContext = createContext(initialState.lookups)
const OpenContext = createContext(initialState.openContext)
const NavigationContext = createContext(initialState.navigation)


const StateProvider = ({ children }: any) => {
	const [state, dispatch] = useReducer((state: any, action: Action) => {
		switch (action.type) {
		/* 
		Authentication 
		*/
		case 'login':
			return ({
				...state,
				auth: {
					user: action.payload,
					authenticated: true
				}
			})
		case 'logout': 
			return ({
				...state,
				auth: {
					authenticated: false,
					user: null
				}
			})
		case 'openSnackBar':
			return ({
				...state,
				snackbar: {
					...action.payload,
					open: true
				}
			})
		case 'openQuickView':
			return ({
				...state,
				openContext: {
					...action.payload,
					...state.openContext,
					quickView: true
				}
			})
		case 'closeQuickView':
			return ({
				...state,
				openContext: {
					...action.payload,
					...state.openContext,
					quickView: false
				}
			})
		case 'openRightQuickView':
			return ({
				...state,
				openContext: {
					...action.payload,
					...state.openContext,
					rightQuickView: true
				}
			})
		case 'closeRightQuickView':
			return ({
				...state,
				openContext: {
					...action.payload,
					...state.openContext,
					rightQuickView: false
				}
			})
		case 'openDrawer':
			return ({
				...state,
				openContext: {
					...action.payload,
					...state.openContext,
					drawer: true
				}
			})
		case 'closeDrawer':
			return ({
				...state,
				openContext: {
					...action.payload,
					...state.openContext,
					drawer: false
				}
			})
		case 'closeSnackBar':
			return ({
				...state,
				snackbar: {
					...state.snackbar,
					open: false
				}
			})
		case 'loadLookups':
			return ({
				...state,
				lookups: {
					...action.payload
				}
			})
		
		/*
		 * Navigation stack
		 */
		case 'pushLocation':
			return ({
				...state,
				navigation: {
					...state.navigation,
					stack: [...state.navigation.stack, action.payload]
				}
			})
		case 'popLocation':
			const stack = [...state.navigation.stack]
			stack.pop()
			return ({
				...state,
				navigation: { ...state.navigation, stack }
			})
		case 'clearLocations':
			return ({
				...state,
				navigation: { ...state.navigation, stack: [] }
			})
		case 'setSearchNav':
			return ({
				...state,
				navigation: {
					...state.navigation,
					searchNav: action.payload
				}
			})
		case 'setSearchNavLoading':
			return ({
				...state,
				navigation: {
					...state.navigation,
					searchNavLoading: action.payload
				}
			})

		/* */
		default: 
			throw new Error(`Invalid action: ${action.type}`)
		}
	}, initialState)

	useEffect(() => {
		if (state.auth.authenticated) {
			async function fetchData() {

				const lookups = await children.props.client.query({
					query: GET_ALL_TYPES,
				}).catch((e: Error) => {
					dispatch({ type: 'openSnackBar', payload: { severity: 'error', text: 'Error retrieving constants.' }})
				})
	
				dispatch({ type: 'loadLookups', payload: lookups })
			}
			fetchData()
		}

	}, [children.props.client, state.auth.authenticated]) 
	
	return (
		<AuthStateContext.Provider value={ state.auth }>
			<SnackbarStateContext.Provider value={ state.snackbar }>
				<DispatchContext.Provider value={ dispatch }>
					<LookupContext.Provider value={ state.lookups }>
						<OpenContext.Provider value={ state.openContext }>
							<NavigationContext.Provider value={ state.navigation }>
								{ children }
							</NavigationContext.Provider>
						</OpenContext.Provider>
					</LookupContext.Provider>
				</DispatchContext.Provider>
			</SnackbarStateContext.Provider>
		</AuthStateContext.Provider>	
	)
}
	
	
export { 
	AuthStateContext,
	SnackbarStateContext,
	DispatchContext,
	NavigationContext,
	StateProvider,
	LookupContext,
	OpenContext
}
