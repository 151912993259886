/* eslint-disable eqeqeq */
import {
	UPDATE_ENTITY_TASK,
	UPDATE_ART_TASK,
	UPDATE_ARTIST_TASK,
	UPDATE_CONTACT_TASK,
	UPDATE_DEAL_TASK,
	UPDATE_LISTING_TASK,
} from "../../Tasks/Queries"
import { DispatchContext, LookupContext } from "../../store"
import { severity } from "../../Snackbar/CustomizedSnackbar"
import { Select, Skeleton } from "@mui/material"
import {
	Box,
	TableContainer,
	Table,
	TableRow,
	TableCell,
	TableBody,
	TableHead,
} from "@mui/material"
import { useMutation } from "@apollo/client"
import { useNavigate } from "react-router-dom"
import EditTask from "../../Tasks/EditTask"
import IconButton from "@mui/material/IconButton"
import InfoCard from "../../InfoCard/InfoCard"
import MenuItem from "@mui/material/MenuItem"
import React, { useContext, useCallback } from "react"
import Thumbnail from "../../Thumbnail/Thumbnail"
import TransitionsModal from "../../navigation/TransitionsModal/TransitionsModal"
import {
	formatDate,
	getArtistThumbnailDetail,
	getContactName,
	getDealName,
	getObjectDetails,
	shorten,
} from "../../common/helpers"
import Dropdown from "../../input/Dropdown/Dropdown"
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded"
import DealThumbnail from "../../Deals/DealThumbnail"
import EditIcon from "@mui/icons-material/Edit"
import ReadMore from "@mui/icons-material/ReadMore"
import InfoOutlined from "@mui/icons-material/InfoOutlined"

/**
 * Get the differing ignored attributes for each task's info card display.
 */
export const getIgnoredAttributes = (currentTask) => {
	if (!currentTask) return []

	const common = [
		"type_id",
		"task_status_id",
		"assigned_to_type",
		"assigned_by_user_id",
		"due_at",
		"created_by",
		"modified_by",
		"is_completed",
		"assigned_to_department", //Todo Phase II,
		"approval_at", //Todo Phase II,
		"approved_by_user_id", //Todo Phase II,
		"approval_required",
	]

	const art = ["art_id", "art_title"]
	const artist = ["artist_id", "artist_name"]
	const contact = ["contact_company_name", "contact_id", "contact_name"]
	const listing = ["listing_id", "listing_title", "listing_subtitle"]
	const deal = ["deal_id"]

	if (currentTask.contact_id) {
		return [...common, ...art, ...artist, ...listing, ...deal]
	} else if (currentTask.listing_id) {
		return [...common, ...art, ...artist, ...contact, ...deal]
	} else if (currentTask.art_id) {
		return [...common, ...artist, ...contact, ...listing, ...deal]
	} else if (currentTask.artist_id) {
		return [...common, ...art, ...contact, ...listing, ...deal]
	} else if (currentTask.deal_id) {
		return [...common, ...art, ...artist, ...listing, ...contact]
	}

	return [...common]
}

function HomeTasks(props) {
	const [infoModal, setInfoModal] = React.useState({ open: false })
	const [currentTask, setCurrentTask] = React.useState(null)
	const [type, setType] = React.useState(null)
	const lookup = React.useContext(LookupContext)
	const taskStatusTypes = lookup.data?.getTaskStatusTypes

	const navigate = useNavigate()

	// Snackbar
	const dispatch = useContext(DispatchContext)
	const openSnackbar = useCallback(
		(severity, text) => {
			dispatch({ type: "openSnackBar", payload: { severity, text } })
		},
		[dispatch],
	)

	// Modal
	const [editTaskModal, setEditTaskModal] = React.useState({
		open: false,
		task: null,
	})

	const handleResponse = (response, name) => {
		if (response && response.data[name].success === false) {
			console.error(response.data[name])
			openSnackbar(severity.ERROR, "There was an error updating this task.")
		} else if (response && response.data[name].success === true) {
			openSnackbar(severity.SUCCESS, "Successfully updated task.")

			props.setCurrentTasks(
				props.tasks.map((task) =>
					task.id != response.data[name].task.id
						? task
						: {
								...task,
								task_status_id: response.data[name].task.task_status_id,
						  },
				),
			)
		} else {
			console.error(response.data[name])
			openSnackbar(severity.ERROR, "Could not update task.")
		}
	}

	const handleError = (error) => {
		console.error(error)
		openSnackbar(severity.ERROR, "There was an error updating this task.")
	}

	// Mutations
	const [updateEntityTask] = useMutation(UPDATE_ENTITY_TASK)
	const [updateArtTask] = useMutation(UPDATE_ART_TASK)
	const [updateArtistTask] = useMutation(UPDATE_ARTIST_TASK)
	const [updateContactTask] = useMutation(UPDATE_CONTACT_TASK)
	const [updateDealTask] = useMutation(UPDATE_DEAL_TASK)
	const [updateListingTask] = useMutation(UPDATE_LISTING_TASK)

	React.useEffect(() => {
		if (currentTask?.art_id) {
			setType("Art")
		} else if (currentTask?.artist_id) {
			setType("Artist")
		} else if (currentTask?.contact_id) {
			setType("Contact")
		} else if (currentTask?.deal_id) {
			setType("Deal")
		} else if (currentTask?.listing_id) {
			setType("Listing")
		} else {
			setType("Entity")
		}
	}, [currentTask])

	return (
		<>
			<div>
				<TableContainer style={{ paddingBottom: "2em" }}>
					<Table
						aria-labelledby="tableTitle"
						size="medium"
						aria-label="enhanced table">
						<TableHead>
							<TableRow>
								<TableCell style={{ fontWeight: "400" }}>Status</TableCell>
								<TableCell style={{ fontWeight: "400" }}>
									Linked Entity
								</TableCell>
								<TableCell style={{ fontWeight: "400" }}>Description</TableCell>
								<TableCell style={{ fontWeight: "400" }}>Due At</TableCell>
								<TableCell></TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{!props.loading &&
							(props.tasks?.length === 0 || props.tasks == null) ? (
								<TableRow>
									<TableCell></TableCell>
									<TableCell style={{ padding: "1em" }}>
										No Tasks Found.
									</TableCell>
									<TableCell></TableCell>
									<TableCell></TableCell>
									<TableCell></TableCell>
								</TableRow>
							) : null}

							{(!props.loading
								? props.tasks || []
								: [{ id: 1, disabled: true }]
							).map((row, index) => {
								if (row && row.hidden === true) return null

								let { type, title, subTitle, isPrivate, hidden, imgUrl } =
									getObjectDetails(row)

								const getThumbnail = () => {
									if (title?.startsWith("Deal"))
										return (
											<div style={{ paddingLeft: "1em" }}>
												<DealThumbnail
													is_private={row.deal.is_private}
													is_gallery_private={row.deal.is_gallery_private}
													created_at={row.deal.created_at}
													deal_name={getDealName(row.deal)}
												/>
											</div>
										)

									if (row.art?.id)
										return (
											<Thumbnail
												name={title}
												formatted_name={row.art?.formatted_title}
												artist={getArtistThumbnailDetail(row.art)}
												isCompany={row.contact?.is_company}
												date={row.art?.year}
												detail={
													!isPrivate
														? !props.loading && !row.art_id
															? subTitle || "-"
															: null
														: null
												}
												avatar={imgUrl}
												type={type}
												animation={props.loading ? "wave" : false}
												largeText
												style={{ paddingLeft: "1em" }}
												hasAccess={!hidden}
												isDeceased={row.contact?.is_deceased}></Thumbnail>
										)

									return (
										<Thumbnail
											name={title}
											formatted_name={row.art?.formatted_title}
											artist={getArtistThumbnailDetail(row.art)}
											isCompany={row.contact?.is_company}
											date={row.art?.year}
											detail={
												!isPrivate
													? !props.loading && !row.art_id
														? subTitle || "-"
														: null
													: null
											}
											avatar={imgUrl}
											type={type}
											animation={props.loading ? "wave" : false}
											largeText
											darkText
											style={{ paddingLeft: "1em" }}
											isPrivate={isPrivate}
											hasAccess={!hidden}
											isDeceased={row.contact?.is_deceased}></Thumbnail>
									)
								}

								return (
									<TableRow key={index} selected={row === props.selectedRow}>
										<TableCell
											sx={{
												paddingLeft: "0.5em",
												paddingRight: "0.5em",
											}}>
											{!props.loading && row.created_at ? (
												<Select
													IconComponent={ExpandMoreRoundedIcon}
													name="status"
													className="hideborder"
													input={<Dropdown />}
													value={row?.task_status_id || ""}
													onChange={(e) => {
														const updateVariables = {
															variables: {
																UpdateTaskInput: {
																	id: row.id,
																	task_status_id: e.target.value,
																},
															},
														}

														if (row.art_id)
															updateArtTask(updateVariables)
																.then((response) =>
																	handleResponse(response, "updateArtTask"),
																)
																.catch(handleError)
														else if (row.artist_id)
															updateArtistTask(updateVariables)
																.then((response) =>
																	handleResponse(response, "updateArtistTask"),
																)
																.catch(handleError)
														else if (row.contact_id)
															updateContactTask(updateVariables)
																.then((response) =>
																	handleResponse(response, "updateContactTask"),
																)
																.catch(handleError)
														else if (row.deal_id)
															updateDealTask(updateVariables)
																.then((response) =>
																	handleResponse(response, "updateDealTask"),
																)
																.catch(handleError)
														else if (row.listing_id)
															updateListingTask(updateVariables)
																.then((response) =>
																	handleResponse(response, "updateListingTask"),
																)
																.catch(handleError)
														else
															updateEntityTask(updateVariables)
																.then((response) =>
																	handleResponse(response, "updateEntityTask"),
																)
																.catch(handleError)
													}}>
													{taskStatusTypes?.map((type) => (
														<MenuItem key={type.id} value={type.id}>
															{type.value}
														</MenuItem>
													))}
												</Select>
											) : (
												<Skeleton
													variant="text"
													width={props.totalItems ? "100%" : "auto"}
													animation={props.loading ? "wave" : false}
												/>
											)}
										</TableCell>

										<TableCell
											className="home-page-tasklist"
											component="th"
											scope="row"
											data-testid="task-thumbnail"
											style={{ width: "15em" }}>
											{title || props.loading ? (
												getThumbnail()
											) : (
												<div style={{ paddingLeft: "1em" }}>
													<span style={{ fontSize: 16 }}>
														{row?.created_at
															? "None"
															: "You do not have permission."}
													</span>
												</div>
											)}
										</TableCell>

										<TableCell>
											<div
												style={{
													maxHeight: "10em",
													overflow: "auto",
													width: "10em",
												}}>
												{row?.description ? (
													shorten(row?.description, 55)
												) : (
													<Skeleton
														variant="text"
														width={props.totalItems ? "100%" : "auto"}
														animation={props.loading ? "wave" : false}
													/>
												)}
											</div>
										</TableCell>
										<TableCell>
											<div
												style={{
													maxHeight: "10em",
													overflow: "auto",
												}}>
												{row.due_at ? (
													formatDate(row.due_at, "MMMM do 'at' h':'mma")
												) : (
													<Skeleton
														variant="text"
														width={props.totalItems ? "100%" : "auto"}
														animation={props.loading ? "wave" : false}
													/>
												)}
											</div>
										</TableCell>

										<TableCell>
											{!row?.disabled ? (
												<Box
													sx={{
														display: "flex",
														flexDirection: "row",
													}}>
													<IconButton
														aria-label="Go To"
														title="Go To"
														onClick={(e) => {
															if (row.contact_id) {
																if (
																	row.contact?.is_private &&
																	!row.contact?.created_at
																) {
																	openSnackbar(
																		severity.WARNING,
																		`You do not have access to this Private Contact.`,
																	)
																	return
																}

																if (row.contact?.is_deleted) {
																	openSnackbar(
																		severity.WARNING,
																		`${getContactName(
																			row.contact,
																		)} was deleted.`,
																	)
																	return
																}

																navigate(`/contacts/${row.contact_id}/tasks`)
															} else if (row.listing_id) {
																if (row.listing?.is_deleted) {
																	openSnackbar(
																		severity.WARNING,
																		`Listing ${row.listing.id} was deleted.`,
																	)
																	return
																}

																navigate(`/listings/${row.listing_id}/tasks`)
															} else if (row.deal_id) {
																if (
																	(row.deal?.is_private ||
																		row.deal?.is_gallery_private) &&
																	!row.deal?.created_at
																) {
																	openSnackbar(
																		severity.WARNING,
																		`You do not have access to this Private Deal.`,
																	)
																	return
																}

																if (row.deal?.is_deleted) {
																	openSnackbar(
																		severity.WARNING,
																		`Deal ${row.deal.id} was deleted.`,
																	)
																	return
																}

																navigate(`/deals/${row.deal_id}/tasks`)
															} else if (row.art_id) {
																if (
																	row.art?.is_private &&
																	!row.art?.created_at
																) {
																	openSnackbar(
																		severity.WARNING,
																		`You do not have access to this Private Artwork.`,
																	)
																	return
																}

																if (row.art?.is_deleted) {
																	openSnackbar(
																		severity.WARNING,
																		`Art ${row.art.id} was deleted.`,
																	)
																	return
																}

																navigate(`/art/${row.art_id}/tasks`)
															} else if (row.artist_id) {
																if (row.artist?.is_deleted) {
																	openSnackbar(
																		severity.WARNING,
																		`Artist ${row.artist.id} was deleted.`,
																	)
																	return
																}

																navigate(`/artists/${row.artist_id}/tasks`)
															} else {
																openSnackbar(
																	severity.WARNING,
																	"This task is not linked to an object.",
																)
															}
														}}>
														<ReadMore />
													</IconButton>
													<IconButton
														aria-label="Edit"
														title="Edit"
														onClick={(e) => {
															if (!row.created_at) {
																openSnackbar(
																	severity.ERROR,
																	"You cannot edit this task.",
																)
																return
															}
															setCurrentTask(row)
															setEditTaskModal({
																open: true,
																task: {
																	...row,
																	// TODO: Phase II assigned_to_department: currentTask.assigned_to_department?.id || null,
																},
															})
														}}>
														<EditIcon />
													</IconButton>
													<IconButton
														aria-label="Info"
														title="Info"
														onClick={(e) => {
															setCurrentTask(row)
															setInfoModal({ open: true })
														}}>
														<InfoOutlined />
													</IconButton>
												</Box>
											) : null}
										</TableCell>
									</TableRow>
								)
							})}
						</TableBody>
					</Table>
				</TableContainer>
			</div>

			<TransitionsModal
				className="edit-task-modal"
				open={editTaskModal.open}
				close={() => {
					setEditTaskModal({ ...editTaskModal, open: false })
					setType(null)
				}}>
				<EditTask
					setEditTaskModal={setEditTaskModal}
					editTaskModal={editTaskModal}
					dynamic={true}
					currentTask={currentTask}
					setTasks={props.setCurrentTasks}
					tasks={props?.tasks || []}
					linkField="task_id"
					entityId="7"
					mutationResponse={`update${type}Task`}
					type={type}
				/>
			</TransitionsModal>

			<InfoCard
				open={infoModal.open}
				object={currentTask}
				setInfoModal={setInfoModal}
				ignoredAttributes={getIgnoredAttributes(currentTask)}
				allAttributes
			/>
		</>
	)
}

export default HomeTasks
