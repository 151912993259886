import React, { useContext } from "react"
import "./RightQuickView.css"
import Paper from "@mui/material/Paper"
import { IconButton } from "@mui/material"
import ChevronRightIcon from "@mui/icons-material/ChevronRight"
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft"
import { DispatchContext, OpenContext } from "../store"

export default function RightQuickView(props) {
	const rightQuickView = "right-quick-view " + (props.className || "")

	const openContext = useContext(OpenContext)

	const rightQuickViewOpen = openContext?.rightQuickView || false

	const dispatch = useContext(DispatchContext)
	const closeRightQuickView = () => dispatch({ type: "closeRightQuickView" })
	const openRightQuickView = () => dispatch({ type: "openRightQuickView" })

	const getRightOffset = () => {
		let offset = 0

		if (rightQuickViewOpen) offset += 12.13
		else offset += 0.5

		return `${offset}em`
	}

	const button = (
		<IconButton
			style={{
				position: "absolute",
				right: getRightOffset(),
				top: "calc(100% - 2.5em)",
				backgroundColor: "hsl(0deg 0% 50% / 32%)",
				borderTopRightRadius: 0,
				borderBottomRightRadius: 0,
				padding: 6,
				zIndex: 3,
				transition: '225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms'
			}}
			onClick={() => {
				if (rightQuickViewOpen) {
					closeRightQuickView()
				} else openRightQuickView()
			}}
			size="large"
		>
			{!rightQuickViewOpen ? <ChevronLeftIcon /> : <ChevronRightIcon />}
		</IconButton>
	)

	return (
		<>
			<Paper
				onClickCapture={() => {
					if (!rightQuickViewOpen) {
						openRightQuickView()
					}
				}}
				className={rightQuickView}
				style={{
					...props.style,
					...{
						display: !rightQuickViewOpen ? 'none' : null,
						cursor: !rightQuickViewOpen ? 'pointer' : null,
						transition: '225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms'
					},
				}}
				onContextMenu={props.onContextMenu}
			>
				{props.children}
			</Paper>
			{button}
		</>
	)
}
